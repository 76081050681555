var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center mt-4"
  }, [_c('h1', {
    staticClass: "text-danger"
  }, [_vm._v("Oops! Something has gone wrong...")]), _c('h3', {
    staticClass: "text-muted"
  }, [_vm._v("If the problem persists please contact Etainabl for assistance.")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Error code: " + _vm._s(_vm.$route.query.errorCode))]), _c('small', [_vm._v(_vm._s(JSON.stringify(_vm.$route.query.errorData)))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }