import Vue from 'vue';

export default {
  get: async ({ commit }, { id }) => {
    try {
      commit('GET_INVOICE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}`);
      commit('GET_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoice',
        message: 'There was a problem getting invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  updateInvoice: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_INVOICE_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/invoices/${id}`, data);
      commit('UPDATE_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateInvoice',
        message: 'There was a problem updating invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  upload: async ({ commit }, { id, data, options }) => {
    try {
      commit('UPLOAD_INVOICE_REQUEST');
      commit('UPDATE_FILE', { id, progress: 0 });

      const qs = [];

      Object.keys(options).forEach(option => {
        qs.push(`${option}=${options[option]}`);
      });

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/captur-upload?${qs.join('&')}`, data, {
        progress(e) {
          commit('UPDATE_FILE', { id, progress: (e.loaded / e.total) * 100 });
        }
      });

      commit('UPLOAD_INVOICE_SUCCESS', body);
      commit('UPDATE_FILE', { id, progress: 100 });

      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'startInvoice',
        message: 'There was a problem uploading invoice.',
        data: e.body
      });
      return e;
    }
  },
  refresh: async ({ commit }, { id }) => {
    try {
      commit('GET_INVOICE_REFRESH_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/refresh`);
      commit('GET_INVOICE_REFRESH_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoiceRefresh',
        message: 'There was a problem getting invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  poll: async ({ commit, state, dispatch }, { timer = 10000 }) => {
    const poller = setInterval(async () => {
      const pollingItems = await dispatch('getPollingItems');

      if (pollingItems.length > 0) {
        const body = await dispatch('getPollingInvoices');

        if (body && body.data) {
          state.polling.items.forEach(item => {
            const result = body.data.find(invoice => invoice.batchId === item.batchId);

            if (!result) {
              commit('UPDATE_POLL_ITEM', { batchId: item.batchId, name: item.name, status: 'queued' });
              commit('UPDATE_FILE', { id: item.id, status: 'queued' });
            } else {
              commit('UPDATE_POLL_ITEM', { batchId: item.batchId, name: item.name, status: result.status });
              commit('UPDATE_FILE', { id: item.id, status: result.status });

              if (result.status === 'captured' || result.status === 'error' || result.status === 'manual') {
                commit('UPDATE_FILE', { id: item.id, invoice: result });
                commit('REMOVE_POLL_ITEM', item.batchId);

                if (result.status === 'error' && result.error.type !== 'captur') {
                  commit('invoiceValidation/ERRORED_INVOICES', result, { root: true });
                } else {
                  commit('invoiceValidation/NEW_INVOICES', 1, { root: true });
                }
              }
            }
          });
        }
      }
    }, timer);

    commit('START_POLL', poller);
  },
  getPollingItems: async ({ state }) => {
    return state.polling.items;
  },
  getPollingInvoices: async ({ state }) => {
    const { body } = await Vue.http.get(
      `${process.env.VUE_APP_API_URL}/invoices?batchId=${state.polling.items.map(item => item.batchId).join(',')}&$multi=true`
    );

    return body;
  },
  getProcessingInvoices: async ({ commit }) => {
    try {
      commit('GET_PROCESSING_INVOICES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices?status=processing,pending,uploading,queued,captured&$multi=true`);
      commit('GET_PROCESSING_INVOICES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getProcessingInvoices',
        message: 'There was a problem getting invoices.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  confirmInvoice: async ({ commit }, { id }) => {
    try {
      commit('CONFIRM_INVOICE_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/invoices/${id}`, { status: 'completed', completed: true });
      commit('CONFIRM_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'confirmInvoice',
        message: 'There was a problem confirming invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  cancelInvoice: async ({ commit }, { id }) => {
    try {
      commit('CANCEL_INVOICE_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/invoices/${id}`);
      commit('CANCEL_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'cancelInvoice',
        message: 'There was a problem cancelling invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  getInvoiceKeys: async ({ commit }) => {
    try {
      commit('GET_INVOICE_KEYS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/templates/invoice-keys`);
      commit('GET_INVOICE_KEYS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoiceKeys',
        message: 'There was a problem getting invoice keys.',
        data: e.body
      });
      return e;
    }
  }
};
