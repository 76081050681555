import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'report',
    plural: 'reports'
  }),
  GENERATE_REPORT_REQUEST: state => {
    state.loadingAction.generateReport = true;
    state.errorAction.generateReport = false;

    state.report = {};
  },
  GENERATE_REPORT_SUCCESS: (state, body) => {
    state.loadingAction.generateReport = false;
    state.errorAction.generateReport = false;

    state.report = body;
  },
  GENERATE_REPORT_ERROR: (state, error) => {
    state.loadingAction.generateReport = false;
    state.errorAction.generateReport = error;

    state.report = {};
  },

  GENERATE_REPORT2_REQUEST: state => {
    state.loadingAction.generateReport2 = true;
    state.errorAction.generateReport2 = false;

    state.report2 = {};
  },
  GENERATE_REPORT2_SUCCESS: (state, body) => {
    state.loadingAction.generateReport2 = false;
    state.errorAction.generateReport2 = false;

    state.report2 = body;
  },
  GENERATE_REPORT2_ERROR: (state, error) => {
    state.loadingAction.generateReport2 = false;
    state.errorAction.generateReport2 = error;

    state.report2 = {};
  },

  SEND_REPORT_REQUEST: state => {
    state.loadingAction.sendReport = true;
    state.errorAction.sendReport = false;
  },
  SEND_REPORT_SUCCESS: state => {
    state.loadingAction.sendReport = false;
    state.errorAction.sendReport = false;
  },
  SEND_REPORT_ERROR: (state, error) => {
    state.loadingAction.sendReport = false;
    state.errorAction.sendReport = error;
  },

  GENERATE_ASSET_REPORT_REQUEST: state => {
    state.loadingAction.generateAssetReport = true;
    state.errorAction.generateAssetReport = false;

    state.report = {};
  },
  GENERATE_ASSET_REPORT_SUCCESS: (state, body) => {
    state.loadingAction.generateAssetReport = false;
    state.errorAction.generateAssetReport = false;

    state.report = body;
  },
  GENERATE_ASSET_REPORT_ERROR: (state, error) => {
    state.loadingAction.generateAssetReport = false;
    state.errorAction.generateAssetReport = error;

    state.report = {};
  },

  GENERATE_PORTFOLIO_REPORT_REQUEST: state => {
    state.loadingAction.generatePortfolioReport = true;
    state.errorAction.generatePortfolioReport = false;

    state.report = {};
  },
  GENERATE_PORTFOLIO_REPORT_SUCCESS: (state, body) => {
    state.loadingAction.generatePortfolioReport = false;
    state.errorAction.generatePortfolioReport = false;

    state.report = body;
  },
  GENERATE_PORTFOLIO_REPORT_ERROR: (state, error) => {
    state.loadingAction.generatePortfolioReport = false;
    state.errorAction.generatePortfolioReport = error;

    state.report = {};
  },

  DOWNLOAD_REPORT_REQUEST: state => {
    state.loadingAction.downloadReport = true;
    state.errorAction.downloadReport = false;

    state.report.downloadUrl = null;
  },
  DOWNLOAD_REPORT_SUCCESS: (state, body) => {
    state.loadingAction.downloadReport = false;
    state.errorAction.downloadReport = false;

    Vue.set(state.report, 'downloadUrl', body.downloadUrl);
  },
  DOWNLOAD_REPORT_ERROR: (state, error) => {
    state.loadingAction.downloadReport = false;
    state.errorAction.downloadReport = error;

    state.report.downloadUrl = null;
  }
};
