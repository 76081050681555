export default [
  {
    name: 'reports',
    path: 'reports',
    component: () => import('./Report'),
    meta: { permissions: ['asset_user'] },
    redirect: { name: 'report-dynamic' },
    children: [
      {
        name: 'reports-dynamic',
        path: 'dynamic',
        component: () => import('./tabs/ReportGenerate')
      },
      {
        name: 'reports-generate',
        path: 'generate',
        component: () => import('./tabs/ReportGenerate')
      },
      {
        name: 'reports-builder',
        path: 'builder',
        component: () => import('./tabs/ReportBuilder')
      },
      {
        name: 'reports-download',
        path: ':id/download',
        component: () => import('./ReportDownload')
      },
      {
        name: 'reports-chart',
        path: ':id/chart',
        component: () => import('./ReportChart')
      },
      {
        name: 'reports-templates',
        path: 'templates',
        component: () => import('./tabs/ReportTemplates')
      },
      {
        name: 'reports-list',
        path: '',
        component: () => import('./tabs/ReportList')
      },
      {
        name: 'reports-history',
        path: 'history',
        component: () => import('./tabs/ReportHistory')
      },
      {
        name: 'reports-schedule',
        path: 'schedule',
        component: () => import('./tabs/ReportSchedule')
      }
    ]
  }
];
