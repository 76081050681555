import Vue from 'vue';

export default {
  getInfo: async ({ commit }) => {
    try {
      commit('GET_INFO_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/live`);
      commit('GET_INFO_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_INFO_ERROR', e);
      return e;
    }
  },
  getConsumption: async ({ commit }) => {
    try {
      commit('GET_CONSUMPTION_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/live/consumption`);
      commit('GET_CONSUMPTION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CONSUMPTION_ERROR', e);
      return e;
    }
  }
};
