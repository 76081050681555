import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'marketplace',
    plural: 'marketplace'
  }),
  getCarbonProjectTypes: async ({ commit }) => {
    try {
      commit('GET_CARBON_PROJECT_TYPES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/marketplace/carbon/project-types`);
      commit('GET_CARBON_PROJECT_TYPES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CARBON_PROJECT_TYPES_ERROR', e);
      return e;
    }
  },
  getCarbonProjects: async ({ commit }, { data } = {}) => {
    try {
      commit('GET_CARBON_PROJECTS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/marketplace/carbon`, data);
      commit('GET_CARBON_PROJECTS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CARBON_PROJECTS_ERROR', e);
      return e;
    }
  },
  getCarbonProject: async ({ commit }, { id }) => {
    try {
      commit('GET_CARBON_PROJECT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/marketplace/carbon/${id}`);
      commit('GET_CARBON_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CARBON_PROJECT_ERROR', e);
      return e;
    }
  }
};
