import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getEpbCertificate: {},
      getAssetAnalytics: {
        ghgAssetChart: true,
        energyUsageAssetChart: true,
        energyCostAssetChart: true,
        energyUsageAccountChart: true,
        energyCostAccountChart: true,
        accountHeatmap: true,
        accountEnergy: false
      }
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getAssetAnalytics: {
        ghgAssetChart: false,
        energyUsageAssetChart: false,
        energyCostAssetChart: false,
        energyUsageAccountChart: false,
        energyCostAccountChart: false,
        accountEnergy: false
      }
    },
    schema: [],
    validationErrors: {},
    asset: {},
    assets: [],
    projects: [],
    recommendations: [],
    floodRisks: [],
    accounts: [],
    selectedAccount: {},
    selectedAccountId: null,
    selectedSchemaId: null,
    accountInvoices: [],
    documentUpload: {
      progress: 0,
      uploaded: false,
      uploading: false
    },
    projectUpload: {
      progress: 0,
      uploaded: false,
      uploading: false
    },
    total: 0,
    findAssets: [],
    satelliteImages: [],
    assetGroups: [],
    epbCertificates: {
      lookup: [],
      details: {}
    },
    assetCertificates: [],
    geocoordinates: {}
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    schema: state => state.schema,
    asset: state => state.asset,
    assets: state => state.assets,
    assetGroups: state => state.assetGroups,
    projects: state => state.projects,
    epbCertificates: state => state.epbCertificates,
    assetCertificates: state => state.assetCertificates,
    floodRisks: state => state.floodRisks,
    accounts: state => state.accounts,
    recommendations: state => state.recommendations,
    documentUpload: state => state.documentUpload,
    certificateUpload: state => state.certificateUpload,
    projectUpload: state => state.projectUpload,
    selectedAccount: state => state.accounts.find(account => account._id === state.selectedAccountId),
    accountInvoices: state => state.accountInvoices.filter(inv => !inv.isCreditNote),
    accountCreditNotes: state => state.accountInvoices.filter(inv => inv.isCreditNote),
    total: state => state.total,
    findAssets: state => state.findAssets,
    satelliteImages: state => state.satelliteImages,
    geocoordinates: state => state.geocoordinates,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    RESET_STATE: state => {
      Object.assign(state, initialState());
    },
    RESET_FIND_ASSETS_STATE: state => {
      Object.assign(state.findAssets, []);
    },
    CLEAR_SEARCHED_CERTS: state => {
      state.lookupCertificateResults = [];
    },
    SET_VALIDATIONS_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
