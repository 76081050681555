import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'automation',
    plural: 'automation'
  }),
  getTemplates: async ({ commit }) => {
    try {
      commit('GET_TEMPLATES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/automation/templates`);
      commit('GET_TEMPLATES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_TEMPLATES_ERROR', e);
      console.log(e);
      return e;
    }
  },
  runAutomation: async ({ commit }, { id }) => {
    try {
      commit('RUN_AUTOMATION_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/automation/${id}/run`);
      commit('RUN_AUTOMATION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('RUN_AUTOMATION_ERROR', e);
      console.log(e);
      return e;
    }
  }
};
