import moment from 'moment';

import actions from './actions';
import mutations from './mutations';
import helpers from './helpers';

const defaultStartDate = moment().startOf('month').subtract(1, 'year');
const defaultEndDate = moment().subtract(1, 'month').endOf('month');

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      getAnalytics: {},
      refreshGraphs: false,
      getAccountTypes: false
    },
    errorAction: {
      getAnalytics: {},
      refreshGraphs: false
    },
    requests: {
      getAnalytics: {},
      refreshGraphs: false
    },
    analytics: {
      consumption: {}
    },
    allAssets: [],
    allAssetGroups: [],
    comparePeriods: [
      { label: `Previous period [${helpers.getComparisonDates(defaultStartDate, defaultEndDate, 'period').join(' - ')}]`, value: 'period' },
      { label: `Previous month [${helpers.getComparisonDates(defaultStartDate, defaultEndDate, 'month').join(' - ')}]`, value: 'month' },
      { label: `Previous quarter [${helpers.getComparisonDates(defaultStartDate, defaultEndDate, 'quarter').join(' - ')}]`, value: 'quarter' },
      { label: `Previous year [${helpers.getComparisonDates(defaultStartDate, defaultEndDate, 'year').join(' - ')}]`, value: 'year' },
      { label: 'No comparison', value: null }
    ],
    accountTypes: [],
    options: {
      type: 'asset',
      startDate: null,
      endDate: null,
      dateRange: [defaultStartDate.toDate(), defaultEndDate.toDate()],
      source: 'combined',
      comparePeriod: 'year',
      selectedId: null,
      page: 'emissions',
      granularity: 'monthly',
      accountType: 'electricity'
    }
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: ({ loading }) => loading,
    accountTypes: ({ accountTypes }) => accountTypes,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    analytics: state => state.analytics,
    options: state => state.options,
    comparePeriods: state => state.comparePeriods,
    allAssets: state => state.allAssets,
    allAssetGroups: state => state.allAssetGroups,
    periodComparisonParams: state => helpers.getComparisonParams(state.options.dateRange[0], state.options.dateRange[1], state.options.comparePeriod)
  },
  mutations: {
    CLEAR_STORE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
