var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay-header show",
    attrs: {
      "id": "page-header-search"
    }
  }, [_c('div', {
    staticClass: "content-header"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "data-cy": "close-btn"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-times-circle"
  })])]), _c('input', {
    staticClass: "form-control border-0 form-control-alt",
    attrs: {
      "id": "page-header-search-input",
      "type": "text",
      "placeholder": "Search...",
      "name": "page-header-search-input"
    },
    on: {
      "input": _vm.onSearchInput
    }
  })])]), _vm.hasSearched ? _c('div', {
    staticClass: "Search-results bg-white text-dark p-4",
    attrs: {
      "data-cy": "search-results"
    }
  }, [_vm.loadingAction.search ? _c('SpinnerLogo') : _vm.searchResults && _vm.searchResults.length > 0 ? _c('div', [_c('h3', {
    staticClass: "text-center"
  }, [_vm._v("Search Results")]), _vm.searchResults.filter(function (r) {
    return r.searchType === 'entity';
  }).length > 0 ? _c('div', [_c('h4', {
    staticClass: "mb-3"
  }, [_c('i', {
    staticClass: "fa text-primary fa-sitemap fa-fw mr-1"
  }), _vm._v("Entities")]), _c('table', {
    staticClass: "table table-borderless table-striped mb-5"
  }, [_c('tbody', _vm._l(_vm.searchResults.filter(function (r) {
    return r.searchType === 'entity';
  }), function (entity) {
    return _c('tr', {
      key: entity._id
    }, [_c('td', [_vm._v(" " + _vm._s(entity._id) + " "), _c('router-link', {
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "to": {
          name: 'entity-view',
          params: {
            id: entity._id
          }
        }
      }
    }, [_c('span', [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(entity.legalName))]), entity.parentEntity ? _c('small', [_vm._v("Parent Entity: " + _vm._s(entity.parentEntity.legalName))]) : _vm._e()]), _vm.$auth.isAdmin ? _c('span', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v(_vm._s(entity.company.name))]) : _vm._e()])], 1)]);
  }), 0)])]) : _vm._e(), _vm.searchResults.filter(function (r) {
    return r.searchType === 'asset';
  }).length > 0 ? _c('div', [_c('h4', {
    staticClass: "mb-3"
  }, [_c('i', {
    staticClass: "fa text-primary fa-buildings fa-fw mr-1"
  }), _vm._v("Assets")]), _c('table', {
    staticClass: "table table-borderless table-striped mb-5"
  }, [_c('tbody', _vm._l(_vm.searchResults.filter(function (r) {
    return r.searchType === 'asset';
  }), function (asset) {
    var _asset$entity, _asset$entity$parentE;

    return _c('tr', {
      key: asset._id
    }, [_c('td', [_c('router-link', {
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "to": {
          name: 'asset-overview',
          params: {
            id: asset._id
          }
        }
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('div', [asset.primaryImageUrl ? _c('img', {
      staticClass: "Search-thumb",
      attrs: {
        "src": asset.primaryImageUrl
      }
    }) : _c('div', {
      staticClass: "Search-thumb no-image"
    })]), _c('div', [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(asset.siteName) + " - " + _vm._s(asset.addressString))]), asset.entity ? _c('small', [_vm._v("Entity: " + _vm._s(asset.entity.legalName))]) : _vm._e(), (_asset$entity = asset.entity) !== null && _asset$entity !== void 0 && (_asset$entity$parentE = _asset$entity.parentEntity) !== null && _asset$entity$parentE !== void 0 && _asset$entity$parentE.legalName ? _c('span', [_c('i', {
      staticClass: "fa fa-pipe fa-fw"
    }), _c('small', [_vm._v("Parent Entity: " + _vm._s(asset.entity.parentEntity.legalName))])]) : _vm._e()])]), _vm.$auth.isAdmin ? _c('div', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v(_vm._s(asset.company.name))]) : _vm._e()])], 1)]);
  }), 0)])]) : _vm._e(), _vm.searchResults.filter(function (r) {
    return r.searchType === 'account';
  }).length > 0 ? _c('div', [_c('h4', {
    staticClass: "mb-3"
  }, [_c('i', {
    staticClass: "fa text-primary fa-meter fa-fw mr-1"
  }), _vm._v("Accounts")]), _c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.searchResults.filter(function (r) {
    return r.searchType === 'account';
  }), function (account) {
    return _c('tr', {
      key: account._id
    }, [_c('td', [_c('router-link', {
      key: _vm.$route.params.accountId,
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "to": {
          name: 'asset-accounts-overview',
          params: {
            id: account.assetId,
            accountId: account._id
          }
        }
      }
    }, [_c('div', [_c('span', [_c('div', {
      staticClass: "font-w600"
    }, [_c('UtilityTypeIcon', {
      attrs: {
        "type": account.type,
        "text": false,
        "icon-class": "fa-duotone"
      }
    }), _vm._v(_vm._s(account.name) + " - " + _vm._s(account.meterPointNumber) + " ")], 1), account.asset ? _c('small', [_vm._v("Asset: " + _vm._s(account.asset.siteName))]) : _vm._e(), account.asset && account.asset.entity ? _c('small', [_vm._v(" | Entity: " + _vm._s(account.asset.entity.legalName))]) : _vm._e(), _c('span', {
      staticClass: "badge text-capitalize ml-2",
      class: {
        'badge-success': account.status === 'active',
        'badge-danger': account.status !== 'active'
      }
    }, [_vm._v(" " + _vm._s(account.status) + " ")]), account.parentAccountId ? _c('span', {
      staticClass: "badge badge-info text-capitalize mr-2"
    }, [_vm._v("Sub-meter")]) : _vm._e()])]), _vm.$auth.isAdmin ? _c('div', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v(_vm._s(account.company.name))]) : _vm._e()])], 1)]);
  }), 0)])]) : _vm._e()]) : _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No search results found.")])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }