import { getInstance } from './index';
import httpInterceptor from '../lib/httpInterceptors';
import { usePermissions } from '../plugins/permissions';

export default (to, from, next) => {
  const authService = getInstance();

  const permissions = usePermissions(authService);

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (!permissions.route(to)) return next(new Error('Forbidden'));

      const token = await authService.auth0Client.getTokenSilently();

      httpInterceptor(token, authService);

      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });

    return null;
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }

    return null;
  });

  return null;
};
