import actions from './actions';

const initialState = {
  error: {},
  loading: {
    getInvoice: false,
    upload: false,
    refresh: false,
    getProcessingInvoices: true,
    confirmInvoice: {},
    cancelInvoice: {},
    recaptureInvoice: {},
    unconfirmedInvoices: []
  },
  files: [],
  polling: {
    items: [],
    poller: {},
    isPolling: false
  },
  invoices: {},
  status: 'preupload',
  invoiceKeys: []
};

const store = {
  namespaced: true,
  state: initialState,
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    files: state => state.files,
    invoices: state => state.invoices,
    status: state => state.status,
    isPolling: state => state.polling.isPolling,
    invoiceKeys: state => state.polling.invoiceKeys,
    unconfirmedInvoices: state => state.p
  },
  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error;
      Object.keys(initialState.loading).forEach(key => {
        state[key] = false;
      });
    },
    ADD_FILE: (state, data) => {
      state.files = [data, ...state.files];
    },
    UPDATE_FILE: (state, { id, ...update }) => {
      const file = state.files.find(f => f.id === id);

      if (file) {
        Object.keys(update).forEach(key => {
          file[key] = update[key];
        });
      }
    },
    UPDATE_FILE_ID: (state, { id, newId }) => {
      const file = state.files.find(f => f.id === id);
      if (file) file.id = newId;
    },
    ADD_POLL_ITEM: (state, { id, name, batchId, status }) => {
      state.polling.items.push({ id, name, batchId, status });
    },
    REMOVE_POLL_ITEM: (state, batchId) => {
      state.polling.items = state.polling.items.filter(item => item.batchId !== batchId);
    },
    UPDATE_POLL_ITEM: (state, { batchId, data }) => {
      state.polling.items = state.polling.items.map(item => {
        if (item.batchId === batchId) {
          return { ...item, ...data };
        }

        return item;
      });
    },
    START_POLL: (state, poller) => {
      state.polling.poller = poller;
      state.polling.isPolling = true;
    },
    CLEAR_POLLS: state => {
      state.polling.items = [];
    },
    END_POLL: state => {
      clearInterval(state.polling.poller);
      state.polling.isPolling = false;
      state.polling.items = [];
    },
    GET_INVOICE_REQUEST: state => {
      state.loading.getInvoice = true;
    },
    GET_INVOICE_SUCCESS: (state, body) => {
      state.loading.getInvoice = false;
      state.invoices[body._id] = body;
    },
    UPLOAD_INVOICE_REQUEST: state => {
      state.loading.upload = true;
    },
    UPLOAD_INVOICE_SUCCESS: (state, body) => {
      state.loading.upload = false;
      state.invoices[body._id] = body;
    },
    UPDATE_INVOICE_REQUEST: state => {
      state.loading.updateInvoice = true;
    },
    UPDATE_INVOICE_SUCCESS: (state, body) => {
      state.loading.updateInvoice = false;
      state.invoices[body._id] = body;
    },
    GET_INVOICE_REFRESH_REQUEST: state => {
      state.loading.refresh = true;
    },
    GET_INVOICE_REFRESH_SUCCESS: (state, body) => {
      state.loading.refresh = false;

      const file = state.files.find(f => f.name === body.fileName);

      if (file) {
        file.results = body.results;
        file.status = body.status;
        file.id = body.id;
        file.invoice = body;
      }
    },
    GET_PROCESSING_INVOICES_REQUEST: state => {
      state.loading.getProcessingInvoices = true;
    },
    GET_PROCESSING_INVOICES_SUCCESS: (state, body) => {
      state.loading.getProcessingInvoices = false;

      const pollItems = body.data
        .filter(invoice => invoice.status !== 'captured')
        .map(invoice => ({
          status: invoice.status,
          name: invoice.fileName,
          batchId: invoice.batchId,
          sisterId: invoice.sisterId,
          id: invoice.batchId
        }));

      const fileItems = body.data
        .filter(invoice => invoice.status !== 'captured')
        .map(invoice => ({
          name: invoice.fileName,
          status: invoice.status,
          id: invoice.batchId,
          invoice: invoice
        }));

      state.polling.items = [...state.polling.items.filter(file => !pollItems.some(newFile => newFile.id === file.id)), ...pollItems];
      state.files = [...state.files.filter(file => !fileItems.some(newFile => newFile.id === file.id)), ...fileItems];
    },
    CONFIRM_INVOICE_REQUEST: (state, id) => {
      state.loading.confirmInvoice = {
        ...state.loading.confirmInvoice,
        [id]: true
      };
    },
    CONFIRM_INVOICE_SUCCESS: (state, body) => {
      state.loading.confirmInvoice = {
        ...state.loading.confirmInvoice,
        [body._id]: false
      };
      state.files = state.files.filter(item => item.id !== body.batchId);
    },
    CANCEL_INVOICE_REQUEST: (state, id) => {
      state.loading.cancelInvoice = {
        ...state.loading.cancelInvoice,
        [id]: true
      };
    },
    CANCEL_INVOICE_SUCCESS: (state, body) => {
      state.loading.cancelInvoice = {
        ...state.loading.cancelInvoice,
        [body._id]: false
      };
      state.files = state.files.filter(item => item.id !== body.batchId);
      state.polling.items = state.polling.items.filter(item => item.id !== body.batchId);
    },
    GET_INVOICE_KEYS_REQUEST: state => {
      state.loading.confirmInvoice = true;
    },
    GET_INVOICE_KEYS_SUCCESS: (state, body) => {
      state.loading.confirmInvoice = false;
      state.invoiceKeys = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
