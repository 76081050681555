import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    roles: [],
    role: {},
    total: 0
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    roles: state => state.roles,
    role: state => state.role,
    total: state => state.total
  },
  mutations,
  actions
};

export default store;
