export default [
  // Admin Views
  {
    path: '/benchmarks',
    redirect: { path: '/benchmarks/crrem' },
    name: 'benchmarks',
    component: () => import('./BenchmarkView'),
    meta: { permissions: ['asset_user'] },
    children: [
      {
        name: 'benchmarks-crrem',
        path: 'crrem',
        component: () => import('./tabs/BenchmarkCRREM')
      },
      {
        name: 'benchmarks-leti',
        path: 'leti',
        component: () => import('./tabs/BenchmarkLETI')
      },
      {
        name: 'benchmarks-reeb',
        path: 'reeb',
        component: () => import('./tabs/BenchmarkREEB')
      },
      {
        name: 'benchmarks-etn',
        path: 'etn',
        component: () => import('./tabs/BenchmarkETN')
      }
    ]
  }
];
