import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    total: 0,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      upload: false,
      download: false,
      assignUser: false,
      reply: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      upload: false,
      download: false,
      assignUser: false
    },
    validationErrors: {},
    uploadStatus: {
      progress: 0,
      uploaded: false,
      previewUrl: ''
    },
    formErrors: {},
    messages: []
  },
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    messages: state => state.messages,
    uploadStatus: state => state.uploadStatus,
    total: state => state.total,
    formErrors: state => state.formErrors,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
