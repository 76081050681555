import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'log',
    plural: 'logs'
  }),
  DOWNLOAD_ATTACHMENT_REQUEST: state => {
    state.loadingAction.downloadAttachment = true;
    state.errorAction.downloadAttachment = null;
  },
  DOWNLOAD_ATTACHMENT_SUCCESS: (state, body) => {
    state.loadingAction.downloadAttachment = false;
    state.errorAction.downloadAttachment = null;

    state.downloadAttachmentUrl = body;
  },
  DOWNLOAD_ATTACHMENT_ERROR: state => {
    state.loadingAction.downloadAttachment = false;
    state.errorAction.downloadAttachment = true;
  }
};
