import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      listCountries: false,
      listAssetTypes: false,
      listEnergySuppliers: false,
      search: false
    },
    errorAction: {},
    countries: [],
    assetTypes: [],
    energySuppliers: [],
    searchResults: [],
    integrations: [],
    granularities: [],
    utilityTypes: [],
    units: []
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    error: state => state.error,
    countries: state => state.countries.filter(c => !c.isJurisdiction),
    jurisdictions: state => state.countries.filter(c => c.isJurisdiction),
    assetTypes: state => state.assetTypes,
    energySuppliers: state => state.energySuppliers,
    searchResults: state => state.searchResults,
    integrations: state => state.integrations,
    granularities: state => state.granularities,
    utilityTypes: state => state.utilityTypes,
    units: state => state.units
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
