<template>
  <span v-if="type === 'gas'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-fire-flame-simple ${noColor ? '' : 'text-danger'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Gas</span></span
  >
  <span v-else-if="type === 'water'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-faucet ${noColor ? '' : 'text-info'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Water</span></span
  >
  <span v-else-if="type === 'waste'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-dumpster ${noColor ? '' : 'text-primary'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Waste</span></span
  >
  <span v-else-if="type === 'solar'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-solar-panel ${noColor ? '' : 'text-success'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Solar PV</span></span
  >
  <span v-else-if="type === 'electricity'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-plug ${noColor ? '' : 'text-warning'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Electricity</span></span
  >
  <span v-else-if="type === 'heating'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-heat ${noColor ? '' : 'text-warning'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Heating</span></span
  >
  <span v-else-if="type === 'cooling'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-fan ${noColor ? '' : 'text-info'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Cooling</span></span
  >
  <span v-else-if="type === 'flow'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-pipe-valve ${noColor ? '' : 'text-muted'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Flow</span></span
  >
  <span v-else-if="type === 'other'" v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-meter ${noColor ? '' : 'text-muted'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">Other</span></span
  >
  <span v-else v-bind="$attrs"
    ><i :class="`fa mr-2 ${noFw ? '' : 'fa-fw'} fa-box ${noColor ? '' : 'text-muted'} ${large ? 'fa-lg' : ''} ${iconClass}`"></i>
    <span v-if="text">{{ type | capitalize }}</span></span
  >
</template>
<script>
export default {
  name: 'UtilityTypeIcon',
  props: {
    type: {
      required: true,
      type: String,
      default: 'electricity'
    },
    iconClass: {
      required: false,
      type: String,
      default: ''
    },
    text: {
      required: false,
      type: Boolean,
      default: true
    },
    large: {
      required: false,
      type: Boolean,
      default: false
    },
    noColor: {
      required: false,
      type: Boolean,
      default: false
    },
    noFw: {
      required: false,
      type: Boolean,
      default: false
    }
  }
};
</script>
