import actions from './actions';
import mutations from './mutations';

const initialState = () => ({
  loadingAction: {
    getUploadUrl: false,
    getInfo: false,
    postMapping: false,
    postPreview: false,
    postImport: false,
    getImportedItems: false,
    rollback: false
  },
  errorAction: {
    getUploadUrl: false,
    getInfo: false,
    postMapping: false,
    postPreview: false,
    postImport: false,
    getImportedItems: false,
    rollback: false
  },
  error: {},
  id: null,
  setup: {
    info: null,
    url: null
  },
  mapping: {
    suggested: [],
    user: []
  },
  preview: {},
  results: {
    status: 'pending'
  },
  import: {},
  imports: [],
  importedItems: []
});

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    error: state => state.error,
    setup: state => state.setup,
    import: state => state.import,
    imports: state => state.imports,
    importedItems: state => state.importedItems,
    mapping: state => {
      try {
        const cache = localStorage.getItem(`ETN-IMPORT-${state.id}`);
        if (cache) {
          const cachedUserMapping = JSON.parse(cache);
          return {
            ...state.mapping,
            user: cachedUserMapping
          };
        }
      } catch (e) {
        return state.mapping;
      }

      return state.mapping;
    },
    preview: state => state.preview,
    id: state => state.id,
    results: state => state.results
  },
  mutations: {
    ...mutations,
    SET_ID: (state, id) => {
      state.id = id;
    },
    RESET: state => {
      Object.assign(state, initialState());
    }
  },
  actions: {
    ...actions
  }
};

export default store;
