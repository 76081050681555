import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'entity',
    plural: 'entities'
  }),
  SEARCH_ENTITIES_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.search = true;
    state.errorAction.search = false;

    state.searchEntityResults = [];
  },
  SEARCH_ENTITIES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.search = false;
    state.errorAction.search = false;

    state.searchEntityResults = body;
  },
  SEARCH_ENTITIES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.search = false;
    state.errorAction.search = error;
  },
  MOVE_ENTITY_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.move = true;
    state.errorAction.move = false;
  },
  MOVE_ENTITY_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.move = false;
    state.errorAction.move = false;

    state.entity = body;
  },
  MOVE_ENTITY_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.move = false;
    state.errorAction.move = error;
  },
  GET_STRUCTURE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.structure = true;

    state.structure = {};
  },
  GET_STRUCTURE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.structure = false;

    state.structure = body;
  },
  GET_STRUCTURE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.structure = false;

    state.structure = {};
  },
  LIST_STRUCTURE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.structure = true;

    state.listStructure = [];
  },
  LIST_STRUCTURE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.structure = false;

    state.listStructure = body;
  },
  LIST_STRUCTURE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.structure = false;

    state.listStructure = [];
  },
  SEARCH_ENTITY_ASSETS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.searchAssets = true;

    state.searchedAssets = {};
  },
  SEARCH_ENTITY_ASSETS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.searchAssets = false;

    state.searchedAssets = body.data;
  },
  SEARCH_ENTITY_ASSETS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.searchAssets = false;

    state.searchedAssets = {};
  },
  UPLOAD_CSV_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadCsv = true;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_PROGRESS: (state, progress) => {
    state.loadingAction.uploadCsv = progress;
  },
  UPLOAD_CSV_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = error;
  },

  GET_ULTIMATE_PARENT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.ultimateParent = true;
  },
  GET_ULTIMATE_PARENT_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.ultimateParent = false;
  },
  GET_ULTIMATE_PARENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.ultimateParent = false;
  }
};
