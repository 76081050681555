import Vue from 'vue';
import VueRouter from 'vue-router';

import AppWrapper from '../views/AppWrapper';
import KioskWrapper from '../views/KioskWrapper';
import KioskError from '../views/Kiosk/KioskError';
import MachineWrapper from '../views/MachineWrapper';
import MachineError from '../views/Machine/MachineError';
import Forbidden from '../views/Forbidden';
import NotFound from '../views/NotFound';
import authGuard from '../auth/authGuard';

import accountRoutes from '../views/Account';
import adminRoutes from '../views/Admin';
import analyticsRoutes from '../views/Analytics';
import assetRoutes from '../views/Asset';
import automationRoutes from '../views/Automation';
import benchmarkRoutes from '../views/Benchmark';
import companyRoutes from '../views/Company';
import entityRoutes from '../views/Entity';
import importRoutes from '../views/Import';
import invoiceRoutes from '../views/Invoice';
import kioskRoutes from '../views/Kiosk';
import machineRoutes from '../views/Machine';
import marketplaceRoutes from '../views/Marketplace';
import portfolioRoutes from '../views/Portfolio';
import reportRoutes from '../views/Report';
import scraperRoutes from '../views/Scraper';
import supplierRoutes from '../views/Supplier';
import targetsRoutes from '../views/Targets';

import Login from '../components/Login';
import Logout from '../components/Logout';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

const routes = [
  { path: '/kiosk/error', name: 'kiosk-error', component: KioskError },
  { path: '/kiosk', name: 'Kiosk', component: KioskWrapper, children: [...kioskRoutes] },
  { path: '/m2m/error', name: 'machine-error', component: MachineError },
  { path: '/m2m', name: 'M2M', component: MachineWrapper, children: [...machineRoutes] },
  { path: '/login', name: 'login', component: Login },
  {
    path: '/',
    beforeEnter: authGuard,
    component: AppWrapper,
    children: [
      { path: '/logout', name: 'logout', component: Logout },
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home'),
        meta: {
          title: 'Home',
          permissions: '*'
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile'),
        meta: {
          title: 'Profile',
          permissions: '*'
        }
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/Notifications'),
        meta: {
          title: 'Notifications',
          permissions: '*'
        }
      },
      {
        path: '/new-entity',
        name: 'entity-wizard',
        component: () => import('../views/EntityWizard'),
        meta: {
          title: 'New Entity'
        }
      },
      {
        path: '/new-account',
        name: 'account-wizard',
        component: () => import('../views/AccountWizard'),
        meta: {
          title: 'New Account'
        }
      },
      ...accountRoutes,
      ...adminRoutes,
      ...analyticsRoutes,
      ...assetRoutes,
      ...benchmarkRoutes,
      ...companyRoutes,
      ...entityRoutes,
      ...importRoutes,
      ...invoiceRoutes,
      ...marketplaceRoutes,
      ...automationRoutes,
      ...portfolioRoutes,
      ...reportRoutes,
      ...scraperRoutes,
      ...supplierRoutes,
      ...targetsRoutes
    ]
  },
  { name: 'forbidden', path: '/forbidden', component: Forbidden },
  { path: '*', component: NotFound }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (/loading chunk \S* failed/i.test(error.message)) {
    document.querySelector('.etn-new-version-box').classList.remove('d-none');
  }

  if (error.message === 'Forbidden') {
    router.push({ name: 'forbidden' });
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
