<template>
  <div class="text-center mt-4">
    <h1 class="text-danger">Oops! Something has gone wrong...</h1>
    <h3 class="text-muted">Try unplugging the power cable from the Etainabl kiosk device, and plugging it back in.</h3>
    <h3 class="text-muted">If the problem persists please contact Etainabl for assistance.</h3>
    <p class="mb-2">Error code: {{ $route.query.errorCode }}</p>
    <small>{{ JSON.stringify($route.query.errorData) }}</small>
  </div>
</template>
<script>
export default {
  name: 'KioskError'
};
</script>
