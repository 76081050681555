import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loading: false,
    total: 0,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getGlobalCompanies: false,
      listUsers: false,
      getUser: false,
      createUser: false,
      updateUser: false,
      deleteUser: false,
      getRoles: false,
      uploadLogo: false,
      getDeletedLogs: false,
      rollbackDelete: false,
      updateSupplierPortal: false,
      deleteSupplierPortal: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getGlobalCompanies: false,
      listUsers: false,
      getUser: false,
      createUser: false,
      updateUser: false,
      deleteUser: false,
      getRoles: false,
      uploadLogo: false,
      getDeletedLogs: false,
      rollbackDelete: false,
      updateSupplierPortal: false,
      deleteSupplierPortal: false
    },
    validationErrors: {},
    company: {},
    companies: [],
    users: [],
    usersTotal: 0,
    roles: [],
    logoUpload: {
      progress: 0,
      uploaded: false,
      previewUrl: ''
    },
    formErrors: {},
    logs: {},
    globalCompanies: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    company: state => state.company,
    companies: state => state.companies,
    logoUpload: state => state.logoUpload,
    users: state => state.users,
    usersTotal: state => state.usersTotal,
    roles: state => state.roles,
    total: state => state.total,
    globalCompanies: state => state.globalCompanies,
    formErrors: state => state.formErrors,
    logs: state => state.logs,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    CLEAR_STORE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
