import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      search: false,
      structure: false,
      uploadCsv: false,
      move: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      search: false,
      structure: false,
      uploadCsv: false,
      move: false
    },
    entity: {},
    entities: [],
    total: 0,
    structure: {},
    listStructure: [],
    searchEntityResults: {},
    searchedAssets: {},
    validationErrors: {}
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    entity: state => state.entity,
    entities: state => state.entities,
    total: state => state.total,
    structure: state => state.structure,
    listStructure: state => state.listStructure,
    searchedEntitys: state => state.searchEntityResults,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
