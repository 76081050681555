<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'MachineView',
  data() {
    return {
      debugMode: false
    };
  },
  async mounted() {
    if (this.$route.query.debug === '1') {
      this.debugMode = true;
    }
  }
};
</script>
