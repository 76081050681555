import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';
import * as stateHelpers from '../../lib/stateHelpers';

export default {
  ...mutationFactory({
    singular: 'asset',
    plural: 'assets'
  }),

  DELETE_ASSET_RECOMMENDATION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.recommendation = true;
    state.errorAction.recommendation = false;
    state.validationErrors = {};
  },
  DELETE_ASSET_RECOMMENDATION_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.recommendation = false;
    state.loadingAction.recommendation = false;
    const index = state.asset.recommendations.findIndex(d => d._id === body._id);

    state.asset.recommendations.splice(index, 1);
  },
  DELETE_ASSET_RECOMMENDATION_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.recommendation = error.body;
    state.loadingAction.recommendation = false;
  },
  UPDATE_ASSET_RECOMMENDATION_REQUEST: state => {
    state.loading = true;
    state.errorAction.updateAssetRecommendation = false;
    state.loadingAction.updateAssetRecommendation = true;
  },
  UPDATE_ASSET_RECOMMENDATION_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.updateAssetRecommendation = false;
    state.loadingAction.updateAssetRecommendation = false;
    state.asset.recommendations.push(body);
  },
  UPDATE_ASSET_RECOMMENDATION_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.updateAssetRecommendation = error.body;
    state.loadingAction.updateAssetRecommendation = false;
  },

  GET_ASSET_GROUPS_REQUEST: state => {
    state.loading = true;
    state.errorAction.getAssetGroups = false;
    state.loadingAction.getAssetGroups = true;
  },
  GET_ASSET_GROUPS_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.getAssetGroups = false;
    state.loadingAction.getAssetGroups = false;

    state.assetGroups = body;
  },
  GET_ASSET_GROUPS_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.getAssetGroups = error.body;
    state.loadingAction.getAssetGroups = false;
  },

  SEARCH_ASSETS_REQUEST: state => {
    state.loading = true;
    state.errorAction.search = false;
    state.loadingAction.search = true;
  },
  SEARCH_ASSETS_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.search = false;
    state.loadingAction.search = false;

    state.asset = body;
  },
  SEARCH_ASSETS_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.search = error.body;
    state.loadingAction.search = false;
  },

  GET_ASSET_CRIME_REQUEST: state => {
    state.loading = true;
    state.errorAction.crime = false;
    state.loadingAction.crime = true;
  },
  GET_ASSET_CRIME_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.crime = false;
    state.loadingAction.crime = false;

    state.asset.recentCrimes = body;
  },
  GET_ASSET_CRIME_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.crime = error.body;
    state.loadingAction.crime = false;
  },

  GET_ASSET_AIR_QUALITY_REQUEST: state => {
    state.loading = true;
    state.errorAction.airQuality = false;
    state.loadingAction.airQuality = true;
  },
  GET_ASSET_AIR_QUALITY_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.airQuality = false;
    state.loadingAction.airQuality = false;

    state.asset.recentAirQuality = body;
  },
  GET_ASSET_AIR_QUALITY_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.airQuality = error.body;
    state.loadingAction.airQuality = false;
  },

  GET_FLOOD_RISKS_REQUEST: state => {
    state.loading = true;
    state.errorAction.floodRisks = false;
    state.loadingAction.floodRisks = true;

    state.floodRisks = [];
  },
  GET_FLOOD_RISKS_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.floodRisks = false;
    state.loadingAction.floodRisks = false;

    state.floodRisks = body;
  },
  GET_FLOOD_RISKS_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.floodRisks = error.body;
    state.loadingAction.floodRisks = false;

    state.floodRisks = [];
  },

  GET_ASSET_SATELLITE_IMAGES_REQUEST: state => {
    state.loading = true;
    state.errorAction.getSatelliteImages = false;
    state.loadingAction.getSatelliteImages = true;
  },
  GET_ASSET_SATELLITE_IMAGES_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.getSatelliteImages = false;
    state.loadingAction.getSatelliteImages = false;

    state.satelliteImages = body;
  },
  GET_ASSET_SATELLITE_IMAGES_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.getSatelliteImages = error.body;
    state.loadingAction.getSatelliteImages = false;
  },

  GET_ASSET_ACCOUNTS_REQUEST: state => {
    state.loading = true;
    state.errorAction.accounts = false;
    state.loadingAction.accounts = true;
  },
  GET_ASSET_ACCOUNTS_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.accounts = false;
    state.loadingAction.accounts = false;

    state.accounts = body;
  },
  GET_ASSET_ACCOUNTS_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.accounts = error.body;
    state.loadingAction.accounts = false;
  },

  GET_INVOICES_REQUEST: state => {
    state.loading = true;
    state.errorAction.accountInvoices = false;
    state.loadingAction.accountInvoices = true;
  },
  GET_INVOICES_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.accountInvoices = false;
    state.loadingAction.accountInvoices = false;

    state.accountInvoices = body;
  },
  GET_INVOICES_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.accountInvoices = error.body;
    state.loadingAction.accountInvoices = false;
  },

  UPDATE_ACCOUNT_INVOICE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateAccountInvoice = true;
    state.errorAction.updateAccountInvoice = false;
  },
  UPDATE_ACCOUNT_INVOICE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.updateAccountInvoice = false;
    state.errorAction.updateAccountInvoice = false;

    state.accountInvoices = state.accountInvoices.map(invoice => {
      if (body._id === invoice._id) {
        return body;
      }

      return invoice;
    });
  },
  UPDATE_ACCOUNT_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateAccountInvoice = false;
    state.errorAction.updateAccountInvoice = error;
  },

  DELETE_ACCOUNT_INVOICE_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteAccountInvoice = id;
    state.errorAction.deleteAccountInvoice = false;
  },
  DELETE_ACCOUNT_INVOICE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteAccountInvoice = false;
    state.errorAction.deleteAccountInvoice = false;

    state.accountInvoices = state.accountInvoices.map(invoice => {
      if (body._id === invoice._id) {
        invoice.deleted = true;

        return body;
      }

      return invoice;
    });
  },
  DELETE_ACCOUNT_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteAccountInvoice = false;
    state.errorAction.deleteAccountInvoice = error;
  },

  RESTORE_ACCOUNT_INVOICE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.restoreAccountInvoice = true;
    state.errorAction.restoreAccountInvoice = false;
  },
  RESTORE_ACCOUNT_INVOICE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.restoreAccountInvoice = false;
    state.errorAction.restoreAccountInvoice = false;

    state.accountInvoices = state.accountInvoices.map(invoice => {
      if (body._id === invoice._id) {
        invoice.deleted = false;

        return body;
      }

      return invoice;
    });
  },
  RESTORE_ACCOUNT_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.restoreAccountInvoice = false;
    state.errorAction.restoreAccountInvoice = error;
  },

  GET_ASSET_ANALYTICS_REQUEST: (state, type) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getAssetAnalytics[type] = true;
    state.errorAction.getAssetAnalytics[type] = false;
  },
  GET_ASSET_ANALYTICS_SUCCESS: (state, { type }) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getAssetAnalytics[type] = false;
    state.errorAction.getAssetAnalytics[type] = false;
  },
  GET_ASSET_ANALYTICS_ERROR: (state, { type, error }) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getAssetAnalytics[type] = false;
    state.errorAction.getAssetAnalytics[type] = error;
  },
  UPLOAD_CSV_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadCsv = true;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_PROGRESS: (state, progress) => {
    state.loadingAction.uploadCsv = progress;
  },
  UPLOAD_CSV_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = error;
  },

  SET_SELECTED_ACCOUNT: (state, body) => {
    state.selectedAccountId = body._id;
  },

  LIST_PROJECTS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.listProjects = true;
    state.errorAction.listProjects = false;
  },
  LIST_PROJECTS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.listProjects = false;
    state.errorAction.listProjects = false;

    state.projects = body.data;
  },
  LIST_PROJECTS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.listProjects = false;
    state.errorAction.listProjects = error;
  },

  GET_PROJECT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getProject = true;
    state.errorAction.getProject = false;
  },
  GET_PROJECT_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getProject = false;
    state.errorAction.getProject = false;
  },
  GET_PROJECT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getProject = false;
    state.errorAction.getProject = error;
  },
  GET_TIMELINE_EVENT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getTimelineEvent = true;
    state.errorAction.getTimelineEvent = false;
  },
  GET_TIMELINE_EVENT_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getTimelineEvent = false;
    state.errorAction.getTimelineEvent = false;
  },
  GET_TIMELINE_EVENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getTimelineEvent = false;
    state.errorAction.getTimelineEvent = error;
  },
  UPDATE_PROJECT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateProject = true;
    state.errorAction.projectForm = false;
  },
  UPDATE_PROJECT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.updateProject = false;
    state.errorAction.projectForm = false;
    state.validationErrors = {};

    state.loadingAction.updateProjectTask = false;
    state.loadingAction.createProjectTask = false;
    state.loadingAction.deleteProjectTask = false;

    state.projects = state.projects.map(p => {
      if (p._id === body._id) {
        return body;
      }

      return p;
    });
  },
  UPDATE_PROJECT_ERROR: (state, e) => {
    state.loading = false;
    state.errorAction.projectForm = e.message;
    state.loadingAction.updateProject = false;
  },

  CREATE_PROJECT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.validationErrors = {};
    state.loadingAction.createProject = true;
    state.errorAction.projectForm = false;
  },
  CREATE_PROJECT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.validationErrors = {};
    state.loadingAction.createProject = false;
    state.errorAction.projectForm = false;

    state.projects = [...state.projects, body];
  },
  CREATE_PROJECT_ERROR: (state, e) => {
    state.loading = false;
    state.errorAction.projectForm = e.message;
    state.loadingAction.createProject = false;
  },

  DELETE_PROJECT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteProject = true;
    state.errorAction.deleteProject = false;
  },
  DELETE_PROJECT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteProject = false;
    state.errorAction.deleteProject = false;

    state.projects = state.projects.filter(p => p._id !== body._id);
  },
  DELETE_PROJECT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteProject = false;
    state.errorAction.deleteProject = error;
  },

  CLEAR_PROJECT_FORM: state => {
    state.errorAction.projectForm = false;
  },

  UPDATE_PROJECT_TASK_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateProjectTask = true;
    state.errorAction.updateProjectTask = false;
  },
  UPDATE_PROJECT_TASK_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateProjectTask = false;
    state.errorAction.updateProjectTask = error;
  },

  CREATE_PROJECT_TASK_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.createProjectTask = true;
    state.errorAction.createProjectTask = false;
  },
  CREATE_PROJECT_TASK_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.createProjectTask = false;
    state.errorAction.createProjectTask = error;
  },
  CREATE_PROJECT_TASK_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.createProjectTask = false;
    state.errorAction.createProjectTask = false;

    state.projects = state.projects.map(p => {
      if (p._id === body._id) {
        return body;
      }

      return p;
    });
  },

  DELETE_PROJECT_TASK_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteProjectTask = id;
    state.errorAction.deleteProjectTask = false;
  },
  DELETE_PROJECT_TASK_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteProjectTask = false;
    state.errorAction.deleteProjectTask = error;
  },
  DELETE_PROJECT_TASK_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteProjectTask = false;
    state.errorAction.deleteProjectTask = false;

    state.projects = state.projects.map(p => {
      if (p._id === body._id) {
        return body;
      }

      return p;
    });
  },

  UPLOAD_DOCUMENT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.uploadDocument = true;
    state.errorAction.uploadDocument = false;
    state.documentUpload.uploaded = false;
    state.documentUpload.uploading = true;
  },
  UPLOAD_DOCUMENT_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.uploadDocument = false;
    state.errorAction.uploadDocument = false;

    state.documentUpload.uploaded = true;
    state.documentUpload.uploading = false;
  },
  UPLOAD_DOCUMENT_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.uploadDocument = false;
    state.errorAction.uploadDocument = error;

    state.documentUpload.uploaded = false;
    state.documentUpload.uploading = false;
  },
  UPLOAD_DOCUMENT_PROGRESS: (state, progress) => {
    state.documentUpload.progress = progress;

    if (progress < 100) {
      state.documentUpload.uploading = true;
    }
  },

  CREATE_DOCUMENT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.createDocument = true;
    state.errorAction.createDocument = false;
    state.validationErrors = {};
  },
  CREATE_DOCUMENT_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.createDocument = error;
    state.loadingAction.createDocument = false;
    state.errorAction.createDocument = error.message;
  },
  CREATE_DOCUMENT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.createDocument = false;
    state.errorAction.createDocument = false;
    state.validationErrors = {};

    state.asset.documents = [...state.asset.documents, body];
  },
  DELETE_DOCUMENT_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteDocument = id;
    state.errorAction.deleteDocument = false;
    state.validationErrors = {};
  },
  DELETE_DOCUMENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteDocument = false;
    state.errorAction.deleteDocument = error;
  },
  DELETE_DOCUMENT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.deleteDocument = false;
    state.errorAction.deleteDocument = false;
    state.validationErrors = {};

    state.asset.documents = state.asset.documents.filter(doc => doc._id !== body._id);
  },

  UPDATE_DOCUMENT_REQUEST: (state, { documentId }) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateDocument = documentId;
    state.errorAction.updateDocument = false;
    state.validationErrors = {};
  },
  UPDATE_DOCUMENT_SUCCESS: (state, { body }) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.updateDocument = false;
    state.errorAction.updateDocument = false;
    state.validationErrors = {};

    const index = state.asset.documents.findIndex(d => d._id === body._id);

    Vue.set(state.asset.documents, index, body);
  },
  UPDATE_DOCUMENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateDocument = false;
    state.errorAction.updateDocument = error;
  },
  CREATE_TIMELINE_EVENT_REQUEST: state => {
    state.loading = true;
    state.error = '';

    state.loadingAction.createTimelineEvent = true;
    state.errorAction.createTimelineEvent = false;
  },
  CREATE_TIMELINE_EVENT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.createTimelineEvent = false;
    state.errorAction.createTimelineEvent = false;

    state.asset.timelineEvents = body;
  },
  CREATE_TIMELINE_EVENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.createTimelineEvent = false;
    state.errorAction.createTimelineEvent = error;
  },
  UPDATE_TIMELINE_EVENT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateTimelineEvent = true;
    state.errorAction.updateTimelineEvent = false;
  },
  UPDATE_TIMELINE_EVENT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.updateTimelineEvent = false;
    state.errorAction.updateTimelineEvent = false;

    state.asset.timelineEvents = body;
  },
  UPDATE_TIMELINE_EVENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateTimelineEvent = false;
    state.errorAction.updateTimelineEvent = error;
  },
  DELETE_TIMELINE_EVENT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteTimelineEvent = true;
    state.errorAction.deleteTimelineEvent = false;
  },
  DELETE_TIMELINE_EVENT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.deleteTimelineEvent = false;
    state.errorAction.deleteTimelineEvent = false;

    state.asset.timelineEvents = body;
  },
  DELETE_TIMELINE_EVENT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteTimelineEvent = false;
    state.errorAction.deleteTimelineEvent = error;
  },
  UPDATE_ADDRESS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateAddress = true;
    state.errorAction.updateAddress = false;
    state.validationErrors = {};
  },
  UPDATE_ADDRESS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = false;
    state.loadingAction.updateAddress = false;
    state.errorAction.updateAddress = false;
    state.validationErrors = {};

    state.asset = body;
  },
  UPDATE_ADDRESS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateAddress = false;
    state.errorAction.updateAddress = error;
  },
  UPDATE_SATELLITE_IMAGES_REQUEST: state => {
    state.loading = false;
    state.loadingAction.updateSatelliteImages = true;
  },
  UPDATE_SATELLITE_IMAGES_SUCCESS: (state, body) => {
    state.loadingAction.updateSatelliteImages = false;
    state.asset = body;
  },
  FIND_ASSETS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.find = true;
    state.errorAction.find = false;
  },
  FIND_ASSETS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.find = false;
    state.errorAction.find = false;
    state.findResults = body;
  },
  FIND_ASSETS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.find = false;
    state.errorAction.find = error;
  },
  UPLOAD_PROJECT_ATTACHMENT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.uploadProjectAttachment = true;
    state.errorAction.uploadProjectAttachment = false;
    state.projectUpload.uploaded = false;
    state.projectUpload.uploading = true;
  },
  UPLOAD_PROJECT_ATTACHMENT_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.uploadProjectAttachment = false;
    state.errorAction.uploadProjectAttachment = false;

    state.projectUpload.uploaded = true;
    state.projectUpload.uploading = false;
  },
  UPLOAD_PROJECT_ATTACHMENT_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.uploadProjectAttachment = false;
    state.errorAction.uploadProjectAttachment = error;

    state.projectUpload.uploaded = false;
    state.projectUpload.uploading = false;

    if (error.message === 'Form validation error') {
      state.errorAction.uploadProjectAttachment = error.errors;
    }
  },
  UPLOAD_PROJECT_ATTACHMENT_PROGRESS: (state, progress) => {
    state.projectUpload.progress = progress;

    if (progress < 100) {
      state.projectUpload.uploading = true;
    }
  },
  DELETE_PROJECT_ATTACHMENT_REQUEST: (state, id) => {
    // stateHelpers.mutationRequest(state, 'deleteProjectAttachment');
    state.error = '';
    state.loadingAction.deleteProjectAttachment = id;
    state.errorAction.deleteProjectAttachment = false;
  },
  DELETE_PROJECT_ATTACHMENT_ERROR: (state, error) => {
    // stateHelpers.mutationError(state, 'deleteProjectAttachment', error);
    state.error = error;
    state.loadingAction.deleteProjectAttachment = false;
    state.errorAction.deleteProjectAttachment = error;
  },
  DELETE_PROJECT_ATTACHMENT_SUCCESS: (state, body) => {
    // stateHelpers.mutationSuccess(state, 'deleteProjectAttachment');
    state.error = false;
    state.loadingAction.deleteProjectAttachment = false;
    state.errorAction.deleteProjectAttachment = false;

    state.asset = body;
  },

  LOOKUP_EPB_CERTIFICATES_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'lookupEpbCertificates');
    state.epbCertificates.lookup = [];
  },
  LOOKUP_EPB_CERTIFICATES_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'lookupEpbCertificates');
    state.epbCertificates.lookup = body;
  },
  LOOKUP_EPB_CERTIFICATES_ERROR: (state, error) => {
    stateHelpers.mutationError(state, 'lookupEpbCertificates', error);
    state.epbCertificates.lookup = [];
  },
  LOOKUP_EPB_CERTIFICATES_RESET: state => {
    stateHelpers.mutationSuccess(state, 'lookupEpbCertificates');
    state.epbCertificates.lookup = [];
  },

  GET_EPB_CERTIFICATE_REQUEST: (state, { id }) => stateHelpers.mutationRequest(state, 'getEpbCertificate', id),
  GET_EPB_CERTIFICATE_ERROR: (state, { id, error }) => stateHelpers.mutationError(state, 'getEpbCertificate', error, id),
  GET_EPB_CERTIFICATE_SUCCESS: (state, { id, body }) => {
    stateHelpers.mutationSuccess(state, 'getEpbCertificate', id);

    if (body && body.id) Vue.set(state.epbCertificates.details, body.id, body);
  },

  LOOKUP_ASSET_CERTIFICATES_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'lookupAssetCertificates');
    state.assetCertificates = [];
  },
  LOOKUP_ASSET_CERTIFICATES_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'lookupAssetCertificates');
    state.assetCertificates = body;
  },
  LOOKUP_ASSET_CERTIFICATES_ERROR: (state, error) => {
    stateHelpers.mutationError(state, 'lookupAssetCertificates', error);
    state.assetCertificates = [];
  },

  SET_SELECTED_SCHEMA: (state, schemaId) => {
    state.selectedSchemaId = schemaId;
  },

  GET_IMAGE_UPLOAD_URL_REQUEST: state => {
    state.loadingAction.getImageUploadUrl = true;
    state.errorAction.getImageUploadUrl = false;
  },
  GET_IMAGE_UPLOAD_URL_SUCCESS: (state, body) => {
    state.loadingAction.getImageUploadUrl = false;
    state.errorAction.getImageUploadUrl = false;

    state.imageUploadUrl = body;
  },
  GET_IMAGE_UPLOAD_URL_ERROR: (state, error) => {
    state.loadingAction.getImageUploadUrl = false;
    state.errorAction.getImageUploadUrl = error;

    state.imageUploadUrl = false;
  },

  GET_GEOCOORDINATES_REQUEST: state => {
    state.loadingAction.getGeocoordinates = true;
    state.errorAction.getGeocoordinates = false;
  },
  GET_GEOCOORDINATES_SUCCESS: (state, body) => {
    state.loadingAction.getGeocoordinates = false;
    state.errorAction.getGeocoordinates = false;

    state.geocoordinates = body;
  },
  GET_GEOCOORDINATES_ERROR: (state, error) => {
    state.loadingAction.getGeocoordinates = false;
    state.errorAction.getGeocoordinates = error;

    state.geocoordinates = false;
  }
};
