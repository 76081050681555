import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'kiosk',
    plural: 'kiosks'
  }),
  getPreviewUrl: async ({ commit }, { id }) => {
    try {
      commit('GET_PREVIEW_URL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/preview`);
      commit('GET_PREVIEW_URL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_PREVIEW_URL_ERROR', e);
      return e;
    }
  },
  getOptisignsScreens: async ({ commit }) => {
    try {
      commit('GET_OPTISIGNS_SCREENS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/optisigns/screens`);
      commit('GET_OPTISIGNS_SCREENS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_OPTISIGNS_SCREENS_ERROR', e);
      return e;
    }
  },
  pairOptisignsScreen: async ({ commit }, { kioskId, pairingCode }) => {
    try {
      commit('NEW_OPTISIGNS_SCREEN_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/kiosks/optisigns/screens`, { kioskId, pairingCode });
      commit('NEW_OPTISIGNS_SCREEN_SUCCESS', body);
      return body;
    } catch (e) {
      commit('NEW_OPTISIGNS_SCREEN_ERROR', e);
      return e;
    }
  }
};
