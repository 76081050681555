import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'reportTemplate',
    plural: 'reportTemplates'
  }),

  LIST_SYSTEM_REQUEST: state => {
    state.loadingAction.listSystem = true;
    state.errorAction.listSystem = false;
  },
  LIST_SYSTEM_SUCCESS: (state, body) => {
    state.loadingAction.listSystem = false;
    state.errorAction.listSystem = false;

    state.systemTemplates = body;
  },
  LIST_SYSTEM_ERROR: (state, error) => {
    state.loadingAction.listSystem = false;
    state.errorAction.listSystem = error;

    state.systemTemplates = [];
  },

  GET_SOURCE_ITEMS_REQUEST: state => {
    state.loadingAction.sourceItems = true;
    state.errorAction.sourceItems = false;
  },
  GET_SOURCE_ITEMS_SUCCESS: state => {
    state.loadingAction.sourceItems = false;
    state.errorAction.sourceItems = false;
  },
  GET_SOURCE_ITEMS_ERROR: (state, error) => {
    state.loadingAction.sourceItems = false;
    state.errorAction.sourceItems = error;
  }
};
