import actions from './actions';

const initialState = () => {
  return {
    error: {},
    validationErrors: {},
    account: {},
    loading: {
      submit: false
    },
    createdAccount: {}
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    error: state => state.error,
    validationErrors: state => state.validationErrors,
    account: state => state.account,
    createdAccount: state => state.createdAccount,
    loading: state => state.loading
  },
  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error;
      Object.keys(state.loading).forEach(key => {
        state[key] = false;
      });
    },
    SET_VALIDATION_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    RESET_WIZARD: state => {
      Object.assign(state, initialState());
    },
    SUBMIT_ACCOUNT_WIZARD_REQUEST: state => {
      state.loading.submit = true;
      state.error = {};
      state.validationErrors = {};
    },
    SUBMIT_ACCOUNT_WIZARD_SUCCESS: (state, body) => {
      state.loading.submit = false;
      state.error = {};
      state.validationErrors = {};
      state.createdAccount = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
