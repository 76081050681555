import Vue from 'vue';

export default {
  getTemplates: async ({ commit }, { supplierId, type }) => {
    try {
      commit('GET_TEMPLATES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/templates?supplierId=${supplierId}&type=${type}`);

      commit('GET_TEMPLATES_SUCCESS', body.data);
      return body.data;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getTemplates',
        message: 'There was a problem getting the template.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  updateTemplate: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_TEMPLATE_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/templates/${id}`, data);
      commit('UPDATE_TEMPLATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateTemplate',
        message: 'There was a problem updating template.',
        data: e.body
      });
      return e;
    }
  },
  createTemplate: async ({ commit }, { data }) => {
    try {
      commit('CREATE_TEMPLATE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/templates`, data);
      commit('CREATE_TEMPLATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'createTemplate',
        message: 'There was a problem creating template.',
        data: e.body
      });
      return e;
    }
  },
  deleteTemplate: async ({ commit }, { id }) => {
    try {
      commit('DELETE_TEMPLATE_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/templates/${id}`);
      commit('DELETE_TEMPLATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'deleteTemplate',
        message: 'There was a problem deleting template.',
        data: e.body
      });
      return e;
    }
  },
  getInvoiceKeys: async ({ commit }) => {
    try {
      commit('GET_INVOICE_KEYS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/templates/invoice-keys`);
      commit('GET_INVOICE_KEYS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoiceKeys',
        message: 'There was a problem getting invoice keys.',
        data: e.body
      });
      return e;
    }
  },
  getPreview: async ({ commit }, { id, jobId }) => {
    try {
      commit('GET_INVOICE_PREVIEW_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/suppliers/${id}/invoice-preview?jobId=${jobId}`);
      commit('GET_INVOICE_PREVIEW_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getAnalysis',
        message: 'There was a problem getting invoice analysis.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  saveVersionRules: async ({ commit }, { id, data }) => {
    try {
      commit('SAVE_VERSION_RULES_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/templates/${id}/version-rules`, data);
      commit('SAVE_VERSION_RULES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'saveVersionRules',
        message: 'There was a problem saving version rules.',
        data: e.body
      });
      return e;
    }
  },
  saveSupplierRules: async ({ commit }, { id, data }) => {
    try {
      commit('SAVE_SUPPLIER_RULES_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/templates/${id}/supplier-rules`, data);
      commit('SAVE_SUPPLIER_RULES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'saveSupplierRules',
        message: 'There was a problem saving supplier rules.',
        data: e.body
      });
      return e;
    }
  },
  savePages: async ({ commit }, { id, data }) => {
    try {
      commit('SAVE_PAGES_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/templates/${id}/pages`, data);
      commit('SAVE_PAGES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'savePages',
        message: 'There was a problem saving pages.',
        data: e.body
      });
      return e;
    }
  },
  saveBounds: async ({ commit }, { id, data }) => {
    try {
      commit('SAVE_BOUNDS_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/templates/${id}/bounds`, data);
      commit('SAVE_BOUNDS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'saveBounds',
        message: 'There was a problem saving bounds.',
        data: e.body
      });
      return e;
    }
  },
  addValue: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_VALUE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/templates/${id}/values`, data);
      commit('ADD_VALUE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'addValue',
        message: 'There was a problem adding a value.',
        data: e.body
      });
      return e;
    }
  },
  updateValue: async ({ commit }, { id, valueId, data }) => {
    try {
      delete data._id;

      commit('UPDATE_VALUE_REQUEST', { id, valueId, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/templates/${id}/values/${valueId}`, data);
      commit('UPDATE_VALUE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateValue',
        message: 'There was a problem updating a value.',
        data: e.body
      });
      return e;
    }
  },
  deleteValue: async ({ commit }, { id, valueId }) => {
    try {
      commit('DELETE_VALUE_REQUEST', { id, valueId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/templates/${id}/values/${valueId}`);
      commit('DELETE_VALUE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'deleteValue',
        message: 'There was a problem deleting a value.',
        data: e.body
      });
      return e;
    }
  },
  addRate: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_RATE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/templates/${id}/rates`, data);
      commit('ADD_RATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'addRate',
        message: 'There was a problem adding a rate.',
        data: e.body
      });
      return e;
    }
  },
  updateRate: async ({ commit }, { id, rateId, data }) => {
    try {
      delete data._id;

      commit('UPDATE_RATE_REQUEST', { id, rateId, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/templates/${id}/rates/${rateId}`, data);
      commit('UPDATE_RATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateRate',
        message: 'There was a problem updating a rate.',
        data: e.body
      });
      return e;
    }
  },
  deleteRate: async ({ commit }, { id, rateId }) => {
    try {
      commit('DELETE_RATE_REQUEST', { id, rateId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/templates/${id}/rates/${rateId}`);
      commit('DELETE_RATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'deleteRate',
        message: 'There was a problem deleting a rate.',
        data: e.body
      });
      return e;
    }
  },
  formatValues: async (action, { values, types, formats }) => {
    try {
      const { body } = await Vue.http.get(
        `${process.env.VUE_APP_API_URL}/util/template-value-format?values=${values}&types=${types}&formats=${formats}`
      );
      return body.results;
    } catch (e) {
      return e;
    }
  }
};
