import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    permissions: [],
    permission: {},
    total: 0
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    permissions: state => state.permissions,
    permission: state => state.permission,
    total: state => state.total
  },
  mutations,
  actions
};

export default store;
