import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loading: false,
    total: 0,
    find: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      listAssets: false,
      addAsset: false,
      removeAsset: false,
      getAnalytics: false,
      getReportData: false,
      find: false,
      getFloorArea: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      listAssets: false,
      addAsset: false,
      removeAsset: false,
      getAnalytics: false,
      getReportData: false,
      getFloorArea: false
    },
    schema: [],
    validationErrors: {},
    searchAssetResults: [],
    assetGroup: {},
    assetGroups: [],
    assets: [],
    reportData: {},
    floorArea: null
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    schema: state => state.schema,
    assetGroup: state => state.assetGroup,
    assetGroups: state => state.assetGroups,
    assets: state => state.assets,
    reportData: state => state.reportData,
    floorArea: state => state.floorArea,
    total: state => state.total,
    searchAssetResults: state => state.searchAssetResults,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    ...mutations,
    CLEAR_FORM_ERRORS: state => {
      state.validationErrors = {};
      state.errorAction.create = false;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
