import actions from '../assetWizard/actions';

const initialState = () => {
  return {
    error: {},
    validationErrors: {},
    asset: {},
    loading: {
      check: false,
      submit: false
    },
    createdAsset: {},
    assetCheck: false
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    error: state => state.error,
    validationErrors: state => state.validationErrors,
    asset: state => state.asset,
    createdAsset: state => state.createdAsset,
    loading: state => state.loading,
    assetCheck: state => state.assetCheck
  },
  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error;
      Object.keys(state.loading).forEach(key => {
        state[key] = false;
      });
    },
    SET_VALIDATION_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    RESET_WIZARD: state => {
      Object.assign(state, initialState());
    },
    CHECK_REQUEST: state => {
      state.loading.check = true;
      state.error = {};
      state.validationErrors = {};
      state.assetCheck = false;
    },
    CHECK_SUCCESS: state => {
      state.loading.check = false;
      state.error = {};
      state.validationErrors = {};
      state.assetCheck = true;
    },
    SUBMIT_WIZARD_REQUEST: state => {
      state.loading.submit = true;
      state.error = {};
      state.validationErrors = {};
    },
    SUBMIT_WIZARD_SUCCESS: (state, body) => {
      state.loading.submit = false;
      state.error = {};
      state.validationErrors = {};
      state.createdAsset = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
