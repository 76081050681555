import Vue from 'vue';

export default {
  getImportLogs: async ({ commit }, { data }) => {
    try {
      const qs = [];

      Object.keys(data.params || []).forEach(key => {
        qs.push(`${key}=${data.params[key]}`);
      });

      commit('GET_IMPORT_LOGS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/logs?type=import,import-rollback&$multi=true&${qs.join('&')}`);
      commit('GET_IMPORT_LOGS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_IMPORT_LOGS_ERROR', e);
      return e;
    }
  },
  getAllLogs: async ({ commit }, { data }) => {
    try {
      const qs = [];

      Object.keys(data.params || []).forEach(key => {
        if (!data.params[key]) return;
        qs.push(`${key}=${data.params[key]}`);
      });

      commit('GET_ALL_LOGS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/logs?${qs.join('&')}`);
      commit('GET_ALL_LOGS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ALL_LOGS_ERROR', e);
      return e;
    }
  },
  getEmails: async ({ commit }, { data }) => {
    try {
      commit('GET_EMAILS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/emails`, data);
      commit('GET_EMAILS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_EMAILS_ERROR', e);
      return e;
    }
  },
  downloadEmailAttachment: async ({ commit }, { id, attachmentId }) => {
    try {
      commit('DOWNLOAD_EMAIL_ATTACHMENT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/emails/${id}/attachments/${attachmentId}/download`);
      commit('DOWNLOAD_EMAIL_ATTACHMENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DOWNLOAD_EMAIL_ATTACHMENT_ERROR', e);
      return e;
    }
  },
  rollbackImport: async ({ commit }, { type, batchId }) => {
    let url = 'invoices/import-rollback';

    if (type === 'account') {
      url = 'accounts/import-rollback';
    } else if (type === 'contract') {
      url = 'accounts/contract-import-rollback';
    } else if (type === 'asset') {
      url = 'assets/import-rollback';
    } else if (type === 'entity') {
      url = 'entities/import-rollback';
    } else if (type === 'invoice') {
      url = 'invoices/import-rollback';
    }

    try {
      commit('ROLLBACK_IMPORT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/${url}`, { batchId });
      commit('ROLLBACK_IMPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('ROLLBACK_IMPORT_ERROR', e);
      return e;
    }
  },
  getUsers: async ({ commit }) => {
    try {
      commit('LIST_USERS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users`);
      commit('LIST_USERS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_USERS_ERROR', e);
      return e;
    }
  },
  getActiveBilling: async ({ commit }, { startDate, endDate, companyId }) => {
    try {
      commit('GET_ACTIVE_BILLING_REQUEST');
      const { body } = await Vue.http.get(
        `${process.env.VUE_APP_API_URL}/accounts/active-billing?start=${startDate}&end=${endDate}&companyId=${companyId}`
      );
      commit('GET_ACTIVE_BILLING_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ACTIVE_BILLING_ERROR', e);
      return e;
    }
  },
  getGridfetchUsage: async ({ commit }, { data } = {}) => {
    try {
      commit('GET_GRIDFETCH_USAGE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/gridfetch-usage`, data);
      commit('GET_GRIDFETCH_USAGE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_GRIDFETCH_USAGE_ERROR', e);
      return e;
    }
  },
  uploadGridfetchLoa: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_GRIDFETCH_LOA_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/util/gridfetch-loa`, data, {
        progress(e) {
          commit('UPLOAD_GRIDFETCH_LOA_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_GRIDFETCH_LOA_PROGRESS', 100);
      commit('UPLOAD_GRIDFETCH_LOA_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_GRIDFETCH_LOA_ERROR', e);
      return e;
    }
  }
};
