export const defaults = {
  getters: {
    friendlyName: state => state.friendlyName,
    friendlyNamePlural: state => state.friendlyNamePlural,
    items: state => state.items,
    formComponent: state => state.formComponent,
    endpoint: state => state.endpoint,
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    validationErrors: state => state.validationErrors,
    schema: state => state.schema
  }
};
