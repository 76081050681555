export default {
  GET_IMPORT_LOGS_REQUEST: state => {
    state.loading.getImportLogs = true;
    state.error.getImportLogs = false;
  },
  GET_IMPORT_LOGS_SUCCESS: (state, body) => {
    state.loading.getImportLogs = false;
    state.error.getImportLogs = false;

    state.importLogs = body;
  },
  GET_IMPORT_LOGS_ERROR: (state, error) => {
    state.loading.getImportLogs = false;
    state.error.getImportLogs = error;
  },
  GET_ALL_LOGS_REQUEST: state => {
    state.loading.getAllLogs = true;
    state.error.getAllLogs = false;
  },
  GET_ALL_LOGS_SUCCESS: (state, body) => {
    state.loading.getAllLogs = false;
    state.error.getAllLogs = false;

    state.allLogs = body;
  },
  GET_ALL_LOGS_ERROR: (state, error) => {
    state.loading.getAllLogs = false;
    state.error.getAllLogs = error;
  },
  ROLLBACK_IMPORT_REQUEST: state => {
    state.loading.rollbackImport = true;
    state.error.rollbackImport = false;
  },
  ROLLBACK_IMPORT_SUCCESS: state => {
    state.loading.rollbackImport = false;
    state.error.rollbackImport = false;
  },
  ROLLBACK_IMPORT_ERROR: (state, error) => {
    state.loading.rollbackImport = false;
    state.error.rollbackImport = error;
  },

  LIST_USERS_REQUEST: state => {
    state.loading.listUsers = true;
    state.error.listUsers = false;
  },
  LIST_USERS_SUCCESS: (state, body) => {
    state.loading.listUsers = false;
    state.error.listUsers = false;

    state.users = body;
  },
  LIST_USERS_ERROR: (state, error) => {
    state.loading.listUsers = false;
    state.error.listUsers = error;
  },

  GET_ACTIVE_BILLING_REQUEST: state => {
    state.loading.getActiveBilling = true;
    state.error.getActiveBilling = false;
  },
  GET_ACTIVE_BILLING_SUCCESS: (state, body) => {
    state.loading.getActiveBilling = false;
    state.error.getActiveBilling = false;

    state.activeBilling = body;
  },
  GET_ACTIVE_BILLING_ERROR: (state, error) => {
    state.loading.getActiveBilling = false;
    state.error.getActiveBilling = error;
  },

  GET_EMAILS_REQUEST: state => {
    state.loading.getEmails = true;
    state.error.getEmails = false;
  },
  GET_EMAILS_SUCCESS: (state, body) => {
    state.loading.getEmails = false;
    state.error.getEmails = false;

    state.emails = body;
  },
  GET_EMAILS_ERROR: (state, error) => {
    state.loading.getEmails = false;
    state.error.getEmails = error;
  },

  DOWNLOAD_EMAIL_ATTACHMENT_REQUEST: state => {
    state.loading.downloadEmailAttachment = true;
    state.error.downloadEmailAttachment = false;
  },
  DOWNLOAD_EMAIL_ATTACHMENT_SUCCESS: state => {
    state.loading.downloadEmailAttachment = false;
    state.error.downloadEmailAttachment = false;
  },
  DOWNLOAD_EMAIL_ATTACHMENT_ERROR: (state, error) => {
    state.loading.downloadEmailAttachment = false;
    state.error.downloadEmailAttachment = error;
  },

  GET_GRIDFETCH_USAGE_REQUEST: state => {
    state.loading.getGridfetchUsage = true;
    state.error.getGridfetchUsage = false;
  },
  GET_GRIDFETCH_USAGE_SUCCESS: (state, body) => {
    state.loading.getGridfetchUsage = false;
    state.error.getGridfetchUsage = false;

    state.gridfetch.usage = body;
  },
  GET_GRIDFETCH_USAGE_ERROR: (state, error) => {
    state.loading.getGridfetchUsage = false;
    state.error.getGridfetchUsage = error;
  },

  UPLOAD_GRIDFETCH_LOA_REQUEST: state => {
    state.loading.uploadGridfetchLoa = true;
    state.error.uploadGridfetchLoa = false;
  },
  UPLOAD_GRIDFETCH_LOA_SUCCESS: (state, body) => {
    state.loading.uploadGridfetchLoa = false;
    state.error.uploadGridfetchLoa = false;

    state.gridfetch.uploadResponse = body;
  },
  UPLOAD_GRIDFETCH_LOA_PROGRESS: (state, progress) => {
    state.gridfetch.loaUploadProgress = progress;
  },
  UPLOAD_GRIDFETCH_LOA_ERROR: (state, error) => {
    state.loading.uploadGridfetchLoa = false;
    state.error.uploadGridfetchLoa = error;
  }
};
