export default {
  emit(action, { namespace, emitTo, data }) {
    const socket = action.state[namespace].socket;

    socket.emit(emitTo, data);
  },
  startListeners({ commit, state }, { listeners, namespace }) {
    const socket = state[namespace].socket;

    listeners.forEach(({ listener, mutation }) => {
      socket.on(listener, data => {
        commit(mutation, data, { root: true });
      });
    });
  },
  clearListeners: async ({ commit, state }, { listeners, namespace }) => {
    const socket = state[namespace].socket;

    listeners.forEach(({ listener, mutation }) => {
      socket.off(listener, data => {
        commit(mutation, data, { root: true });
      });
    });
  }
};
