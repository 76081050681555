import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false
  },
  ping: {
    messages: [],
    lastMessage: ''
  }
};

const getters = {
  isConnected: state => state.socket.isConnected,
  ping: state => state.ping
};

const mutations = {
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
  },
  SOCKET_ONCLOSE(state) {
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    state.socket.message = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info(state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  }
};

const actions = {
  emit: function (context, message) {
    Vue.prototype.$socket.sendObj(message);
  },
  connect: () => {
    Vue.prototype.$connect(Vue.prototype.$auth.socketsApiUrl);
  },
  disconnect: () => {
    Vue.prototype.$disconnect();
  },
  ping: ({ state }, data) => {
    state.ping.lastMessage = {
      data: data.response,
      timestamp: new Date()
    };
    state.ping.messages.push(state.ping.lastMessage);
  }
};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
