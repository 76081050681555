import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'user',
    plural: 'users'
  }),
  me: async ({ commit }) => {
    try {
      commit('GET_USER_SELF_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me`);
      commit('GET_USER_SELF_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_USER_SELF_ERROR', e.body.message || e.message);
      return e;
    }
  },
  setCompanyId: async ({ commit }, { userSub, companyId }) => {
    try {
      commit('SET_COMPANY_ID_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/users/${userSub}/company-id`, { companyId });
      commit('SET_COMPANY_ID_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_COMPANY_ID_ERROR', e);
      return e;
    }
  },
  getDashboardData: async ({ commit }, { params }) => {
    try {
      commit('GET_DASHBOARD_DATA_REQUEST', params.dataType);

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/dashboard-data`, {
        params
      });
      commit('GET_DASHBOARD_DATA_SUCCESS', { body, dataType: params.dataType });
      return body;
    } catch (e) {
      commit('GET_DASHBOARD_DATA_ERROR', e);
      return e;
    }
  },
  getNotifications: async ({ commit }, { params } = {}) => {
    try {
      commit('GET_NOTIFICATIONS_REQUEST', { params });

      const queryString = [];

      if (params) {
        Object.keys(params).forEach(key => {
          queryString.push(`${key}=${params[key]}`);
        });
      }

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/notifications?${queryString.join('&')}`);
      commit('GET_NOTIFICATIONS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_NOTIFICATIONS_ERROR', e);
      return e;
    }
  },
  seenNotification: async ({ commit }, { id }) => {
    try {
      commit('SEEN_NOTIFICATION_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/users/me/seen-notification`, { id });
      commit('SEEN_NOTIFICATION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEEN_NOTIFICATION_ERROR', e);
      return e;
    }
  },
  getCompany: async ({ commit }) => {
    try {
      commit('GET_COMPANY_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/company`);
      commit('GET_COMPANY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_COMPANY_ERROR', e);
      return e;
    }
  },
  getCompanies: async ({ commit }) => {
    try {
      commit('GET_COMPANIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/companies`);
      commit('GET_COMPANIES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_COMPANIES_ERROR', e);
      return e;
    }
  },
  getPermissions: async ({ commit }) => {
    try {
      commit('GET_PERMISSIONS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/permissions`);
      commit('GET_PERMISSIONS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_PERMISSIONS_ERROR', e);
      return e;
    }
  },
  getAssetGroups: async ({ commit }) => {
    try {
      commit('GET_ASSET_GROUPS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/asset-groups`);
      commit('GET_ASSET_GROUPS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_GROUPS_ERROR', e);
      return e;
    }
  },
  getApiUsage: async ({ commit }) => {
    try {
      commit('GET_API_USAGE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me/api-usage`);
      commit('GET_API_USAGE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_API_USAGE_ERROR', e);
      return e;
    }
  },
  setupApiAccess: async ({ commit }) => {
    try {
      commit('SETUP_API_ACCESS_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/users/me/api-setup`);
      commit('SETUP_API_ACCESS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SETUP_API_ACCESS_ERROR', e);
      return e;
    }
  },
  getApiKey: async ({ commit }) => {
    try {
      commit('GET_API_KEY_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/users/me/api-key`);
      commit('GET_API_KEY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_API_KEY_ERROR', e);
      return e;
    }
  },
  getEntity: async ({ commit }, { id }) => {
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/entities/${id}`);
      commit('GET_ENTITY_SUCCESS', body);
      return body;
    } catch (e) {
      return e;
    }
  }
};
