import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getDashboardData: {}
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    users: [],
    user: {},
    dashboardData: {},
    notifications: {
      data: [],
      unseenCount: 0
    },
    company: {},
    companies: [],
    assetGroups: [],
    entity: {},
    permissions: [],
    total: 0,
    validationErrors: {}
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    users: state => state.users,
    user: state => state.user,
    dashboardData: state => state.dashboardData,
    notifications: state => state.notifications,
    company: state => state.company,
    companies: state => state.companies,
    assetGroups: state => state.assetGroups,
    error: state => state.error,
    entity: state => state.entity,
    permissions: state => state.permissions,
    usersTotal: state => state.total,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
