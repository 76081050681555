import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';
import { defaults } from '../../lib/getterFactory';

const store = {
  namespaced: true,
  state: {
    friendlyName: 'Kiosk',
    friendlyNamePlural: 'Kiosks',
    formComponent: 'KioskForm',
    endpoint: 'kiosks',
    loading: true,
    schema: {},
    items: [],
    validationErrors: {},
    total: 0,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    }
  },
  getters: {
    ...defaults.getters
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
