import actions from './actions';

const initialState = () => {
  return {
    error: {},
    validationErrors: {},
    entity: {},
    loading: {
      submit: false
    },
    createdEntity: {}
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    error: state => state.error,
    validationErrors: state => state.validationErrors,
    entity: state => state.entity,
    createdEntity: state => state.createdEntity,
    loading: state => state.loading
  },
  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error;
      Object.keys(state.loading).forEach(key => {
        state[key] = false;
      });
    },
    SET_VALIDATION_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    RESET_WIZARD: state => {
      Object.assign(state, initialState());
    },
    SUBMIT_WIZARD_REQUEST: state => {
      state.loading.submit = true;
      state.error = {};
      state.validationErrors = {};
    },
    SUBMIT_WIZARD_SUCCESS: (state, body) => {
      state.loading.submit = false;
      state.error = {};
      state.validationErrors = {};
      state.createdEntity = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
