import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'scheduledReport',
    plural: 'scheduledReports'
  }),
  SEND_SCHEDULED_REPORT_REQUEST: state => {
    state.loadingAction.send = true;
    state.errorAction.send = false;

    state.report = {};
  },
  SEND_SCHEDULED_REPORT_SUCCESS: (state, body) => {
    state.loadingAction.send = false;
    state.errorAction.send = false;

    state.report = body;
  },
  SEND_SCHEDULED_REPORT_ERROR: (state, error) => {
    state.loadingAction.send = false;
    state.errorAction.send = error;

    state.report = {};
  }
};
