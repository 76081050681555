import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    settings: {}
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    error: state => state.error,
    settings: state => state.settings
  },
  mutations: {
    CLEAR_STORE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
