import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'message',
    plural: 'messages'
  }),

  UPLOAD_ATTACHMENT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.upload = true;
    state.errorAction.upload = false;
  },
  UPLOAD_ATTACHMENT_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.upload = false;
    state.errorAction.upload = false;

    state.uploadStatus.uploaded = true;
  },
  UPLOAD_ATTACHMENT_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.upload = false;
    state.errorAction.upload = error;
    state.uploadStatus.uploaded = false;

    if (error.message === 'Form validation error') {
      state.formErrors = error.errors;
    }
  },
  UPLOAD_ATTACHMENT_PROGRESS: (state, progress) => {
    state.uploadStatus.progress = progress;
  },
  GET_LINKED_ACCOUNT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getLinkedAccount = true;
    state.errorAction.getLinkedAccount = false;
  },
  GET_LINKED_ACCOUNT_SUCCESS: (state, { id, body }) => {
    state.loading = false;
    state.loadingAction.getLinkedAccount = false;
    state.errorAction.getLinkedAccount = false;

    const message = state.messages.find(message => message._id === id);

    Vue.set(message, 'accounts', body);
  },

  ASSIGN_USER_REQUEST: state => {
    state.loading = true;
    state.loadingAction.assignUser = true;
    state.errorAction.assignUser = false;
  },
  ASSIGN_USER_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.assignUser = false;
    state.errorAction.assignUser = false;

    const messageIdx = state.messages.findIndex(message => body._id === message._id);

    Vue.set(state.messages, messageIdx, body);
  },
  ASSIGN_USER_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.assignUser = false;
    state.errorAction.assignUser = error;
  },
  ADD_REPLY_REQUEST: state => {
    state.loadingAction.reply = true;
  },
  ADD_REPLY_SUCCESS: state => {
    state.loadingAction.reply = false;
  }
};
