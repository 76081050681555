export default [
  // Admin Views
  {
    path: '/targets',
    name: 'targets',
    component: () => import('./TargetsView'),
    meta: { permissions: ['admin'] },
    redirect: { name: 'targets-dashboard' },
    children: [
      {
        name: 'targets-dashboard',
        path: '',
        component: () => import('./tabs/TargetsDashboard')
      },
      {
        name: 'targets-manage',
        path: 'manage',
        component: () => import('./tabs/TargetsManage')
      }
    ]
  }
];
