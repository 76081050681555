import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'supplier',
    plural: 'suppliers'
  }),
  listDistributors: async ({ commit }, { data = {} }) => {
    try {
      commit('LIST_DISTRIBUTORS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/suppliers?type=distributor`, data);
      commit('LIST_DISTRIBUTORS_SUCCESS', body.data);
      return body.data;
    } catch (e) {
      commit('LIST_DISTRIBUTORS_ERROR', e);
      return e;
    }
  }
};
