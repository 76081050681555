import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'marketplace',
    plural: 'marketplace'
  }),
  GET_CARBON_PROJECT_TYPES_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getCarbonProjectTypes = true;
    state.errorAction.getCarbonProjectTypes = false;
  },
  GET_CARBON_PROJECT_TYPES_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getCarbonProjectTypes = false;
    state.errorAction.getCarbonProjectTypes = false;

    state.carbon.projectTypes = body;
  },
  GET_CARBON_PROJECT_TYPES_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getCarbonProjectTypes = false;
    state.errorAction.getCarbonProjectTypes = error;
  },

  GET_CARBON_PROJECTS_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getCarbonProjects = true;
    state.errorAction.getCarbonProjects = false;
  },
  GET_CARBON_PROJECTS_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getCarbonProjects = false;
    state.errorAction.getCarbonProjects = false;

    state.carbon.projects = body;
  },
  GET_CARBON_PROJECTS_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getCarbonProjects = false;
    state.errorAction.getCarbonProjects = error;
  },

  GET_CARBON_PROJECT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getCarbonProject = true;
    state.errorAction.getCarbonProject = false;
  },
  GET_CARBON_PROJECT_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getCarbonProject = false;
    state.errorAction.getCarbonProject = false;

    state.carbon.project = body;
  },
  GET_CARBON_PROJECT_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getCarbonProject = false;
    state.errorAction.getCarbonProject = error;
  }
};
