import actions from './actions';

const state = {};

const mutations = {
  SET_CONNECTED: (state, { status, namespace, socket }) => {
    if (!status) {
      console.log(`Disconnected from namespace: ${namespace}`);
    } else {
      console.log(`Connected to namespace: ${namespace}`);
    }

    if (!state[namespace]) {
      state[namespace] = Object.assign({}, { connected: status, socket });
    } else {
      state[namespace].connected = status;
    }
  }
};

const store = {
  namespaced: true,
  state,
  mutations,
  actions
};

export default store;
