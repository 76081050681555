import Vue from 'vue';
import actions from './actions';

const getInitialState = () => {
  return {
    error: {
      refresh: false,
      getInvoices: false,
      getInvoiceKeys: false,
      getInvoice: {},
      getRelatedAccounts: {},
      confirmInvoice: {},
      deleteInvoices: {},
      updateInvoice: {},
      validateInvoice: {},
      revalidateInvoice: {},
      recaptureInvoice: {},
      addInvoiceRate: {},
      removeInvoiceRate: {},
      searchAccounts: {},
      getStats: false
    },
    loading: {
      refresh: false,
      getInvoices: true,
      getInvoiceKeys: false,
      getInvoice: {},
      getRelatedAccounts: {},
      confirmInvoice: {},
      deleteInvoices: {},
      updateInvoice: {},
      validateInvoice: {},
      revalidateInvoice: {},
      recaptureInvoice: {},
      addInvoiceRate: {},
      removeInvoiceRate: {},
      searchAccounts: {},
      getStats: false
    },
    invoices: [],
    invoicesTotal: {
      total: 0,
      filteredTotal: 0
    },
    invoiceKeys: [],
    selectedInvoices: [],
    validationResults: {},
    newInvoices: 0,
    erroredInvoices: [],
    stats: {}
  };
};

const getInitialValidationState = invoice => {
  return {
    show: false,
    results: {},
    hasAccount: !!invoice.accountId,
    accountSearch: {
      data: [],
      total: 0,
      limit: 100,
      skip: 0
    }
  };
};

const store = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    invoices: state => state.invoices,
    invoicesTotal: state => state.invoicesTotal,
    selectedInvoices: state => state.selectedInvoices,
    validationResults: state => state.validationResults,
    invoiceKeys: state => state.invoiceKeys,
    newInvoices: state => state.newInvoices,
    erroredInvoices: state => state.erroredInvoices,
    stats: state => state.stats
  },
  mutations: {
    SET_ERROR: (state, error) => {
      if (error.id && state.loading[error.type]) {
        Vue.set(state.error[error.type], error.id, error);
        Vue.set(state.loading[error.type], error.id, false);
      } else {
        state.error[error.type] = error;
        state.loading[error.type] = false;
      }
    },
    NEW_INVOICES: (state, count) => {
      state.newInvoices = state.newInvoices + count;
    },
    ERRORED_INVOICES: (state, invoice) => {
      state.erroredInvoices = [...state.erroredInvoices, { fileName: invoice.fileName, id: invoice._id, errorType: invoice.error.type }];
    },
    REMOVE_ERRORED_INVOICE: (state, id) => {
      state.erroredInvoices = state.erroredInvoices.filter(inv => inv.id !== id);
    },
    SELECT_INVOICE: (state, invoice) => {
      // Toggle
      state.selectedInvoices = state.selectedInvoices.some(id => id === invoice._id)
        ? state.selectedInvoices.filter(id => id !== invoice._id)
        : [...state.selectedInvoices, invoice._id];
    },
    SELECT_ALL_INVOICES: state => {
      state.selectedInvoices = state.invoices.filter(inv => inv.invoice.status !== 'completed').map(inv => inv._id);
    },
    UNSELECT_ALL_INVOICES: state => {
      state.selectedInvoices = [];
    },
    GET_INVOICE_RECAPTURE_REQUEST: (state, id) => {
      Vue.set(state.loading.recaptureInvoice, id, true);
    },
    GET_INVOICE_RECAPTURE_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.recaptureInvoice, id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);

      Vue.set(state.invoices[invoiceIndex], 'invoice', body);
    },
    GET_INVOICES_REQUEST: state => {
      state.loading.getInvoices = true;
      state.newInvoices = 0;
    },
    GET_INVOICES_SUCCESS: (state, { body, skip, fullReset }) => {
      state.loading.getInvoices = false;

      if (fullReset) state.invoices = [];

      body.data.forEach((invoice, index) => {
        Vue.set(state.invoices, index + skip, {
          _id: invoice._id,
          id: invoice._id,
          invoice,
          validation: getInitialValidationState(invoice)
        });
      });

      state.invoicesTotal = {
        total: body.total,
        filteredTotal: body.filteredTotal
      };
    },
    GET_INVOICE_REQUEST: (state, { id }) => {
      Vue.set(state.loading.getInvoice, id, true);
    },
    GET_INVOICE_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.getInvoice, id, false);

      state.invoices = state.invoices.map(inv => {
        if (inv._id === id) {
          return {
            ...inv,
            invoice: body
          };
        }

        return inv;
      });
    },
    GET_RELATED_ACCOUNTS_REQUEST: (state, { id }) => {
      Vue.set(state.loading.getRelatedAccounts, id, true);
    },
    GET_RELATED_ACCOUNTS_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.getRelatedAccounts, id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);

      Vue.set(
        state.invoices[invoiceIndex].validation,
        'relatedAccounts',
        body.data.map(account => ({
          label: `${account.asset ? account.asset.siteName : 'Unknown Asset'} - ${account.name} [${account.meterPointNumber}]`,
          value: account._id
        }))
      );
    },
    CONFIRM_INVOICE_REQUEST: (state, { id }) => {
      Vue.set(state.loading.confirmInvoice, id, true);
      Vue.set(state.error.confirmInvoice, id, false);
    },
    CONFIRM_INVOICE_SUCCESS: (state, { id }) => {
      Vue.set(state.loading.confirmInvoice, id, false);
      Vue.set(state.error.confirmInvoice, id, false);
    },
    DELETE_INVOICES_REQUEST: (state, ids) => {
      ids.forEach(id => {
        Vue.set(state.loading.deleteInvoices, id, true);
        Vue.set(state.error.deleteInvoices, id, false);
      });
    },
    DELETE_INVOICES_SUCCESS: (state, body) => {
      body.forEach(invoice => {
        Vue.set(state.loading.deleteInvoices, invoice._id, false);
      });

      state.invoices = state.invoices.filter(invoice => !body.some(deletedInvoice => invoice._id === deletedInvoice._id));

      state.invoicesTotal = {
        total: state.invoicesTotal.total - body.length,
        filteredTotal: state.invoicesTotal.filteredTotal - body.length
      };
    },
    GET_INVOICE_KEYS_REQUEST: state => {
      state.loading.getInvoiceKeys = true;
    },
    GET_INVOICE_KEYS_SUCCESS: (state, body) => {
      state.loading.getInvoiceKeys = false;
      state.invoiceKeys = body;
    },
    SEARCH_ACCOUNTS_REQUEST: (state, { id }) => {
      Vue.set(state.loading.searchAccounts, id, true);
    },
    SEARCH_ACCOUNTS_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.searchAccounts, id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);

      Vue.set(state.invoices[invoiceIndex].validation, 'accountSearch', body);

      return body;
    },
    UPDATE_INVOICE_REQUEST: (state, id) => {
      Vue.set(state.loading.updateInvoice, id, true);
    },
    UPDATE_INVOICE_SUCCESS: (state, body) => {
      Vue.set(state.loading.updateInvoice, body._id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === body._id);

      Vue.set(state.invoices[invoiceIndex], 'invoice', body);

      if (!body.accountId) {
        Vue.set(state.invoices[invoiceIndex], 'validation', getInitialValidationState(body));
      }
    },
    VALIDATE_INVOICE_REQUEST: (state, id) => {
      Vue.set(state.loading.validateInvoice, id, true);
      Vue.set(state.error.validateInvoice, id, false);
    },
    VALIDATE_INVOICE_SUCCESS: (state, { id, body, isManual = false }) => {
      Vue.set(state.loading.validateInvoice, id, false);
      Vue.set(state.error.validateInvoice, id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);
      const { invoice } = state.invoices.find(inv => inv._id === id);

      Vue.set(state.invoices[invoiceIndex].validation, 'show', isManual);
      Vue.set(state.invoices[invoiceIndex].validation, 'results', body);
      Vue.set(state.invoices[invoiceIndex].validation, 'hasAccount', !!invoice.accountId);
    },
    VALIDATE_INVOICE_RESET: (state, id) => {
      const { invoice } = state.invoices.find(inv => inv._id === id);
      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);

      Vue.set(state.invoices[invoiceIndex], 'validation', getInitialValidationState(invoice));
    },
    REVALIDATE_INVOICE_REQUEST: (state, id) => {
      Vue.set(state.loading.revalidateInvoice, id, true);
    },
    REVALIDATE_INVOICE_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.revalidateInvoice, id, false);

      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);

      Vue.set(state.invoices[invoiceIndex].validation, 'results', body);
    },
    TOGGLE_DISPLAY_VALIDATION_RESULTS: (state, id) => {
      const invoiceIndex = state.invoices.findIndex(inv => inv._id === id);
      Vue.set(state.invoices[invoiceIndex].validation, 'show', !state.invoices[invoiceIndex].validation.show);
    },
    SET_CONTRACT_RATE_TYPE_SUCCESS: (state, { invoiceId, body }) => {
      const invoiceIndex = state.invoices.findIndex(inv => inv._id === invoiceId);

      Vue.set(state.invoices[invoiceIndex].validation.results, 'contract', body);
    },
    ADD_INVOICE_RATE_REQUEST: (state, { id }) => {
      Vue.set(state.loading.addInvoiceRate, id, true);
    },
    ADD_INVOICE_RATE_SUCCESS: (state, { id, body, rateId }) => {
      Vue.set(state.loading.addInvoiceRate, rateId, false);

      const index = state.invoices.findIndex(inv => inv._id === id);

      // the return data from adding a rate does not include the account
      body.account = state.invoices[index].invoice.account;

      Vue.set(state.invoices[index], 'invoice', body);
    },
    REMOVE_INVOICE_RATE_REQUEST: (state, { id, index }) => {
      Vue.set(state.loading.removeInvoiceRate, id, index);
    },
    REMOVE_INVOICE_RATE_SUCCESS: (state, { id, body }) => {
      Vue.set(state.loading.removeInvoiceRate, id, false);

      const index = state.invoices.findIndex(inv => inv._id === id);

      // the return data from removing a rate does not include the account
      body.account = state.invoices[index].invoice.account;

      Vue.set(state.invoices[index], 'invoice', body);
    },
    GET_STATS_REQUEST: state => {
      state.loading.getStats = true;
      state.stats = {};
    },
    GET_STATS_SUCCESS: (state, body) => {
      state.loading.getStats = false;
      state.stats = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
