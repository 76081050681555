// Translate this files values to french

export default {
  // Comments made are purely where/when I found the word
  // Generic
  LOADING_DATA: 'Loading data...',
  CLICK_HERE: 'Click here',
  // Dashboard
  INVOICE: 'Invoice | Invoices',
  ACCOUNT: 'Account | Accounts',
  ASSET: 'Asset | Assets',
  ENTITY: 'Entity | Entities',
  RECENT_DISCUSSION: 'Recent Discussion | Recent Discussions',
  NO_RECENT_MESSAGE: 'No recent message | No recent messages',
  TITLE: 'Title',
  MESSAGE: 'Message | Messages',
  POSTED_BY: 'Posted by',
  CONFIRMED_INVOICES_UPLOADED_BY_USERS: 'Confirmed Invoices Uploaded By Users',
  RECENTLY_LOGGED_IN_USERS: 'Recently Logged In Users',
  AMOUNT_OF_MESSAGES_POSTED_BY_USERS: 'Amount of Messages Posted by Users',
  // Overview Table
  DATE_RANGE_TEXT: 'Past month', // TODO Moment locale - dateRangeText/reportingPeriods
  FILTER: 'Filter | Filters',
  PREVIOUS: 'Prev',
  NEXT: 'Next',
  USER: 'User | Users',
  LATEST_LOGIN: 'Latest Login',
  NUMBER_OF_MESSAGES: 'Number of Messages',
  NUMBER_OF_INVOICES: 'Number of Invoices',
  // Graph
  LOADING_GRAPH: 'Loading graph...',
  // Chart // TODO dateTimeLabelFormats
  // Profile
  ACCOUNT_NOT_VERIFIED: 'Your account is not verified.',
  EMAIL_VERFICATION: 'to receive a new email that contains the verification link.',
  USER_PROFILE: 'User Profile',
  USER_PROFILE_INFO: 'Your account’s vital info. Your username will be publicly visible.',
  NAME: 'Name',
  NAME_PLACEHOLDER: 'Enter your name..',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_PASSWORD_TEXT: 'Changing your sign in password is an easy way to keep your account secure.',
  NEW_PASSWORD: 'New Password'
};
