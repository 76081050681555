export default [
  {
    path: '/suppliers',
    name: 'supplier-list',
    component: () => import('./SupplierList'),
    meta: { permissions: ['admin'] }
  },
  {
    path: '/suppliers/create',
    name: 'supplier-create',
    component: () => import('./SupplierCreate'),
    meta: { permissions: ['admin'] }
  },
  {
    path: '/suppliers/:id',
    name: 'supplier-view',
    component: () => import('./SupplierView'),
    meta: { permissions: ['admin'] },
    children: [
      {
        name: 'supplier-overview',
        path: 'overview',
        component: () => import('./SupplierEdit')
      },
      {
        name: 'supplier-template-electricity',
        path: 'electricity',
        meta: { type: 'electricity' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-solar',
        path: 'solar',
        meta: { type: 'solar' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-gas',
        path: 'gas',
        meta: { type: 'gas' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-water',
        path: 'water',
        meta: { type: 'water' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-waste',
        path: 'waste',
        meta: { type: 'waste' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-heating',
        path: 'heating',
        meta: { type: 'heating' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-template-cooling',
        path: 'cooling',
        meta: { type: 'cooling' },
        component: () => import('./tabs/SupplierTemplate')
      },
      {
        name: 'supplier-edit',
        path: 'edit',
        component: () => import('./SupplierEdit')
      }
    ]
  }
];
