import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'reportTemplate',
    plural: 'reportTemplates',
    url: 'report-templates'
  }),
  listSystem: async ({ commit }) => {
    try {
      commit('LIST_SYSTEM_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/report-templates/system`);
      commit('LIST_SYSTEM_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_SYSTEM_ERROR', e.body.message || e.message);
      return e;
    }
  },
  getSourceItems: async ({ commit }, { id }) => {
    try {
      commit('GET_SOURCE_ITEMS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/report-templates/${id}/source-items`);
      commit('GET_SOURCE_ITEMS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_SOURCE_ITEMS_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
