import actions from './actions';
import mutations from './mutations';

const store = {
  namespaced: true,
  state: {
    loading: false,
    total: 0,
    loadingAction: {
      ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    },
    photoUploadProgress: 0,
    schema: [],
    reading: {},
    readings: [],
    photoUrl: ''
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    schema: state => state.schema,
    errorAction: state => state.errorAction,
    photoUrl: state => state.photoUrl,
    photoUploadProgress: state => state.photoUploadProgress
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
