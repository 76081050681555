import Vue from 'vue';

export const mutationRequest = (state, key, id) => {
  if (id) {
    Vue.set(state.loadingAction[key], id, true);
  } else {
    state.loadingAction[key] = true;
  }

  state.errorAction[key] = false;
  state.loading = true;
  state.error = '';
};

export const mutationSuccess = (state, key, id) => {
  if (id) {
    Vue.set(state.loadingAction[key], id, false);
  } else {
    state.loadingAction[key] = false;
  }
  state.errorAction[key] = false;
  state.loading = false;
  state.error = '';
};

export const mutationError = (state, key, error, id) => {
  if (id) {
    Vue.set(state.loadingAction[key], id, false);
  } else {
    state.loadingAction[key] = false;
  }
  state.errorAction[key] = error || true;
  state.loading = false;
  state.error = error || true;
};
