import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loading: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    error: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    importLogs: {
      total: 0,
      data: []
    },
    allLogs: {
      total: 0,
      data: []
    },
    users: [],
    activeBilling: {},
    emails: {
      total: 0,
      data: []
    },
    gridfetch: {
      usage: [],
      loaUrl: '',
      loaUploadProgress: 0
    }
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    importLogs: state => state.importLogs,
    users: state => state.users,
    activeBilling: state => state.activeBilling,
    emails: state => state.emails,
    allLogs: state => state.allLogs,
    gridfetch: state => state.gridfetch
  },
  mutations: {
    CLEAR_STORE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
