import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: true,
    validationErrors: {},
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    debug: {},
    slideIndex: 0,
    slides: [
      {
        title: 'Leaderboard',
        subTitle: 'See how your floor is doing compared to others!',
        component: 'KioskEnergyLeaderboard'
      },
      {
        title: 'Consumption',
        subTitle: "This building's energy consumption",
        component: 'KioskHHGraph'
      },
      {
        title: 'Benchmark',
        subTitle: 'How this building compares to others',
        component: 'KioskBenchmark'
      },
      {
        title: 'News',
        subTitle: 'News, weather and travel for your area',
        component: 'KioskLocalNews'
      },
      {
        title: 'Energy Usage',
        subTitle: 'Recent electricity consumption of this building',
        component: 'KioskEnergy'
      },
      {
        title: 'Carbon',
        subTitle: "This building's CO2 emission data",
        component: 'KioskCarbon'
      }
    ],
    kiosk: {},
    consumption: {}
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    slideIndex: state => state.slideIndex,
    slides: state => state.slides,
    kiosk: state => state.kiosk,
    consumption: state => state.consumption,
    debug: state => state.debug
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
