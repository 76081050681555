export default {
  LIST_COUNTRIES_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.listCountries = true;

    state.countries = [];
  },
  LIST_COUNTRIES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.listCountries = false;

    state.countries = body.data;
  },
  LIST_COUNTRIES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.listCountries = false;

    state.countries = [];
  },

  LIST_ASSET_TYPES_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.assetTypes = true;

    state.assetTypes = [];
  },
  LIST_ASSET_TYPES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.assetTypes = false;

    state.assetTypes = body;
  },
  LIST_ASSET_TYPES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.assetTypes = false;

    state.assetTypes = [];
  },

  LIST_INTEGRATIONS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.integrations = true;

    state.integrations = [];
  },
  LIST_INTEGRATIONS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.integrations = false;

    state.integrations = body;
  },
  LIST_INTEGRATIONS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.integrations = false;

    state.integrations = [];
  },

  LIST_GRANULARITIES_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.granularities = true;

    state.granularities = [];
  },
  LIST_GRANULARITIES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.granularities = false;

    state.granularities = body;
  },
  LIST_GRANULARITIES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.granularities = false;

    state.granularities = [];
  },

  LIST_UTILITY_TYPES_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.utilityTypes = true;
  },
  LIST_UTILITY_TYPES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.utilityTypes = false;

    state.utilityTypes = body;
  },
  LIST_UTILITY_TYPES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.utilityTypes = false;

    state.utilityTypes = [];
  },

  LIST_UNITS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.units = true;
  },
  LIST_UNITS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.units = false;

    state.units = body;
  },
  LIST_UNITS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.units = false;

    state.units = [];
  },

  LIST_ENERGY_SUPPLIERS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.energySuppliers = true;

    state.energySuppliers = [];
  },
  LIST_ENERGY_SUPPLIERS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.energySuppliers = false;

    state.energySuppliers = body;
  },
  LIST_ENERGY_SUPPLIERS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.energySuppliers = false;

    state.energySuppliers = [];
  },

  SEARCH_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.search = true;

    state.searchResults = [];
  },
  SEARCH_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.search = false;

    state.searchResults = body;
  },
  SEARCH_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.search = false;

    state.searchResults = [];
  }
};
