export default [
  // Admin Views
  {
    path: '/connectors',
    name: 'connectors',
    component: () => import('./ScraperView'),
    redirect: { name: 'connectors-dashboard' },
    meta: { permissions: ['company_admin'] },
    children: [
      {
        name: 'connectors-dashboard',
        path: 'dashboard',
        component: () => import('./ScraperDashboard')
      },
      {
        name: 'connectors-runs',
        path: 'runs',
        component: () => import('./ScraperRuns')
      },
      {
        name: 'connectors-manage',
        path: 'manage',
        component: () => import('./ScraperManage')
      }
    ]
  }
];
