import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'scheduledReport',
    plural: 'scheduledReports',
    url: 'scheduled-reports'
  }),
  send: async ({ commit }, { id, data }) => {
    try {
      commit('SEND_SCHEDULED_REPORT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/scheduled-reports/${id}/send`, data);
      commit('SEND_SCHEDULED_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEND_SCHEDULED_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
