// import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'setting',
    plural: 'settings'
  })
};
