import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    automation: {},
    automations: [],
    total: 0,
    validationErrors: {},
    templates: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    automation: state => state.automation,
    automations: state => state.automations,
    total: state => state.total,
    errorAction: state => state.errorAction,
    validationErrors: state => state.validationErrors,
    templates: state => state.templates
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
