import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'automation',
    plural: 'automation'
  }),
  GET_TEMPLATES_REQUEST: state => {
    state.loadingAction.getTemplates = true;
    state.errorAction.getTemplates = false;
  },
  GET_TEMPLATES_SUCCESS: (state, data) => {
    state.loadingAction.getTemplates = false;
    state.errorAction.getTemplates = false;
    state.templates = data;
  },
  GET_TEMPLATES_ERROR: (state, error) => {
    state.loadingAction.getTemplates = false;
    state.errorAction.getTemplates = error;
  },

  // Override as automation for some reason is singular on the API
  LIST_AUTOMATION_SUCCESS: (state, data) => {
    state.loadingAction.list = false;
    state.errorAction.list = false;
    state.automations = data.data;
    state.total = data.total;
  },

  RUN_AUTOMATION_REQUEST: state => {
    state.loadingAction.runAutomation = true;
    state.errorAction.runAutomation = false;
  },
  RUN_AUTOMATION_SUCCESS: state => {
    state.loadingAction.runAutomation = false;
    state.errorAction.runAutomation = false;
  },
  RUN_AUTOMATION_ERROR: (state, error) => {
    state.loadingAction.runAutomation = false;
    state.errorAction.runAutomation = error;
  }
};
