import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'reading',
    plural: 'readings'
  }),
  submitReading: async ({ commit }, { data }) => {
    try {
      commit('SUBMIT_READING_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/readings/submit`, data);
      commit('SUBMIT_READING_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SUBMIT_READING_ERROR', e.body);
      return e;
    }
  },
  editSubmission: async ({ commit }, { id, data }) => {
    try {
      commit('EDIT_SUBMISSION_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/readings/${id}/edit-submission`, data);
      commit('EDIT_SUBMISSION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('EDIT_SUBMISSION_ERROR', e.body);
      return e;
    }
  },
  uploadPhoto: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_PHOTO_REQUEST');

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/readings/photo`, data, {
        progress(e) {
          commit('UPLOAD_PHOTO_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_PHOTO_SUCCESS', body);

      return body;
    } catch (e) {
      commit('UPLOAD_PHOTO_ERROR', e.body.message || e.message);
      return e;
    }
  },
  getPhoto: async ({ commit }, { id, data }) => {
    try {
      commit('GET_READING_PHOTO_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/readings/${id}/photo`, data);
      commit('GET_READING_PHOTO_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_READING_PHOTO_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
