import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';
import * as Sentry from '@sentry/browser';

export default {
  ...actionFactory({
    singular: 'report',
    plural: 'reports'
  }),
  generateReport: async ({ commit }, { type, params }) => {
    try {
      commit('GENERATE_REPORT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/reports/generate/${type}`, {
        params
      });
      commit('GENERATE_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GENERATE_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  },
  generateAssetReport: async ({ commit }, { type, id, params }) => {
    try {
      commit('GENERATE_ASSET_REPORT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/reports/asset/${id}/${type}`, {
        params
      });
      commit('GENERATE_ASSET_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GENERATE_ASSET_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  },
  generatePortfolioReport: async ({ commit }, { type, id, params }) => {
    try {
      commit('GENERATE_PORTFOLIO_REPORT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/reports/portfolio/${id}/${type}`, {
        params
      });
      commit('GENERATE_PORTFOLIO_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GENERATE_PORTFOLIO_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  },
  generateReport2: async ({ commit }, { data, params }) => {
    try {
      commit('GENERATE_REPORT2_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/reports/generate2`, data, {
        params
      });
      commit('GENERATE_REPORT2_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GENERATE_REPORT2_ERROR', e.body.message || e.message);
      return e;
    }
  },
  regenerate: async ({ commit }, { id }) => {
    try {
      commit('GENERATE_REPORT2_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/reports/${id}/regenerate`);
      commit('GENERATE_REPORT2_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GENERATE_REPORT2_ERROR', e.body.message || e.message);
      return e;
    }
  },
  send: async ({ commit }, { id }) => {
    try {
      commit('SEND_REPORT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/reports/${id}/send`);
      commit('SEND_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEND_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  },
  download: async ({ commit }, { id }) => {
    try {
      commit('DOWNLOAD_REPORT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/reports/${id}/download`);
      commit('DOWNLOAD_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      Sentry.captureException(e);
      commit('DOWNLOAD_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  },
  downloadPortfolio: async ({ commit }, { id }) => {
    try {
      commit('DOWNLOAD_REPORT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/reports/${id}/download-portfolio`);
      commit('DOWNLOAD_REPORT_SUCCESS', body);
      return body;
    } catch (e) {
      Sentry.captureException(e);
      commit('DOWNLOAD_REPORT_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
