import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'scraperRun',
    plural: 'scraperRuns',
    url: 'scraper-runs'
  }),
  getLogDownloadUrl: async ({ commit }, { id, logId }) => {
    try {
      commit('GET_LOG_DOWNLOAD_URL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/scraper-runs/${id}/logs/${logId}/download`);
      commit('GET_LOG_DOWNLOAD_URL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_LOG_DOWNLOAD_URL_ERROR', e.body.message || e.message);
      return e;
    }
  },
  startScraperRun: async (action, params) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/scraper-runs/start-run`, params);
      return body;
    } catch (e) {
      return e;
    }
  }
};
