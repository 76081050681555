import actions from './actions';

const getInitialState = () => {
  return {
    error: {},
    loading: {
      updateValue: false,
      addValue: false,
      deleteValue: false,
      updateRate: false,
      addRate: false,
      deleteRate: false,
      saveVersionRules: false,
      saveSupplierRules: false,
      savePages: false,
      saveBounds: false,
      results: false,
      template: false,
      invoiceKeys: false
    },
    status: 'preupload',
    templates: [],
    template: {
      supplierRules: [],
      pages: [],
      bounds: [],
      values: [],
      rates: [],
      versions: []
    },
    upload: {
      form: {},
      file: null
    },
    polls: {},
    preview: {
      currentPage: 1,
      totalPages: 1,
      url: ''
    },
    priceFormats: [
      { value: '1', label: '£0,000.00' },
      { value: '2', label: '£ 0,000.00' },
      { value: '3', label: '0,000.00 £' },
      { value: '4', label: '0,000.00£' },
      { value: '5', label: '£0.000,00' },
      { value: '6', label: '£ 0.000,00' },
      { value: '7', label: '0.000,00 £' },
      { value: '8', label: '0.000,00£' },
      { value: '9', label: '0,000.00' },
      { value: '10', label: '0.000,00' }
    ],
    results: {
      values: {},
      rates: []
    },
    settings: {
      showLines: true,
      showBounds: true,
      showCells: true,
      allowDrawing: true
    },
    invoiceKeys: []
  };
};

const initialState = getInitialState();

const store = {
  namespaced: true,
  state: initialState,
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    status: state => state.status,
    templates: state => state.templates,
    template: state => state.template,
    upload: state => state.upload,
    preview: state => state.preview,
    priceFormats: state => state.priceFormats,
    results: state => state.results,
    settings: state => state.settings,
    invoiceKeys: state => state.invoiceKeys
  },
  mutations: {
    RESET: state => {
      Object.assign(state, getInitialState());
    },
    SET_ERROR: (state, error) => {
      state.error = error;
      Object.keys(initialState.loading).forEach(key => {
        state[key] = false;
      });
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_TEMPLATE: (state, data) => {
      state.template = data;
    },
    UPDATE_UPLOAD: (state, data) => {
      state.upload = {
        ...state.upload,
        ...data
      };
    },
    UPDATE_PREVIEW: (state, data) => {
      state.preview = {
        ...state.preview,
        ...data
      };
    },
    START_POLL: (state, { jobId, poller }) => {
      state.polls[jobId] = poller;
    },
    CLEAR_POLLS: state => {
      Object.keys(state.polls).forEach(jobId => {
        console.log(`Cancelling poll ${jobId}`);
        clearInterval(state.polls[jobId]);
      });
    },
    CLEAR_POLL: (state, jobId) => {
      console.log(`Cancelling poll ${jobId}`);
      clearInterval(state.polls[jobId]);
    },
    NEXT_PREVIEW_PAGE: state => {
      state.preview.currentPage < state.preview.totalPages && state.preview.currentPage++;
    },
    PREV_PREVIEW_PAGE: state => {
      state.preview.currentPage > 1 && state.preview.currentPage--;
    },

    // Settings
    TOGGLE_LINES: (state, toggle) => {
      state.settings.showLines = toggle;
    },
    TOGGLE_BOUNDS: (state, toggle) => {
      state.settings.showBounds = toggle;
    },
    TOGGLE_CELLS: (state, toggle) => {
      state.settings.showCells = toggle;
    },
    ALLOW_DRAWING: (state, allow) => {
      state.settings.allowDrawing = allow;
    },
    GET_TEMPLATES_REQUEST: state => {
      state.templates = [];
    },
    GET_TEMPLATES_SUCCESS: (state, body) => {
      state.templates = body;
    },

    UPDATE_TEMPLATE_REQUEST: state => {
      state.loading.updateTemplate = true;
    },
    UPDATE_TEMPLATE_SUCCESS: (state, body) => {
      state.loading.updateTemplate = false;
      state.template = body;

      state.templates = state.templates.map(t => {
        if (t._id === body._id) {
          return body;
        }

        return t;
      });
    },

    CREATE_TEMPLATE_REQUEST: state => {
      state.loading.createTemplate = true;
    },
    CREATE_TEMPLATE_SUCCESS: state => {
      state.loading.createTemplate = false;
    },

    DELETE_TEMPLATE_REQUEST: state => {
      state.loading.deleteTemplate = true;
    },
    DELETE_TEMPLATE_SUCCESS: (state, body) => {
      state.loading.deleteTemplate = false;

      state.templates = state.templates.filter(t => t._id !== body._id);

      if (state.templates.length > 0) {
        state.template = state.templates[0];
      }
    },

    GET_INVOICE_PREVIEW_REQUEST: state => {
      state.loading.results = true;
    },
    GET_INVOICE_PREVIEW_SUCCESS: (state, body) => {
      state.loading.results = false;
      state.results = body;
    },

    // Version
    SAVE_VERSION_RULES_REQUEST: state => {
      state.loading.saveVersionRules = true;
    },
    SAVE_VERSION_RULES_SUCCESS: (state, body) => {
      state.loading.saveVersionRules = false;
      state.template.versionRules = body;
    },

    // Supplier
    SAVE_SUPPLIER_RULES_REQUEST: state => {
      state.loading.saveSupplierRules = true;
    },
    SAVE_SUPPLIER_RULES_SUCCESS: (state, body) => {
      state.loading.saveSupplierRules = false;
      state.template.supplierRules = body;
    },

    // Pages
    SAVE_PAGES_REQUEST: state => {
      state.loading.savePages = true;
    },
    SAVE_PAGES_SUCCESS: (state, body) => {
      state.loading.savePages = false;
      state.template.pages = body;
    },

    // Bounds
    SAVE_BOUNDS_REQUEST: state => {
      state.loading.saveBounds = true;
    },
    SAVE_BOUNDS_SUCCESS: (state, body) => {
      state.loading.saveBounds = false;
      state.template.bounds = body;
    },

    // Values

    ADD_VALUE_REQUEST: state => {
      state.loading.addValue = true;
    },
    ADD_VALUE_SUCCESS: (state, body) => {
      state.loading.addValue = false;
      state.template.values = body;
    },

    UPDATE_VALUE_REQUEST: state => {
      state.loading.updateValue = true;
    },
    UPDATE_VALUE_SUCCESS: (state, body) => {
      state.loading.updateValue = false;
      state.template.values = body;
    },

    DELETE_VALUE_REQUEST: state => {
      state.loading.deleteValue = true;
    },
    DELETE_VALUE_SUCCESS: (state, body) => {
      state.loading.deleteValue = false;
      state.template.values = body;
    },

    // Rates
    ADD_RATE_REQUEST: state => {
      state.loading.addRate = true;
    },
    ADD_RATE_SUCCESS: (state, body) => {
      state.loading.addRate = false;
      state.template.rates = body;
    },

    UPDATE_RATE_REQUEST: state => {
      state.loading.updateRate = true;
    },
    UPDATE_RATE_SUCCESS: (state, body) => {
      state.loading.updateRate = false;
      state.template.rates = body;
    },

    DELETE_RATE_REQUEST: state => {
      state.loading.deleteRate = true;
    },
    DELETE_RATE_SUCCESS: (state, body) => {
      state.loading.deleteRate = false;
      state.template.rates = body;
    },

    GET_INVOICE_KEYS_REQUEST: state => {
      state.loading.invoiceKeys = true;
    },
    GET_INVOICE_KEYS_SUCCESS: (state, body) => {
      state.loading.invoiceKeys = false;
      state.invoiceKeys = body;
    }
  },
  actions: {
    ...actions
  }
};

export default store;
