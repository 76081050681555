import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    validationErrors: {},
    targets: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    targets: state => state.targets
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
