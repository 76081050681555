<template>
  <div v-if="$auth.isAuthenticated && $auth.user.email">
    <Main />
  </div>
  <div v-else id="page-loader" class="bg-primary show"></div>
</template>

<script>
import Main from './Main';

export default {
  name: 'AppWrapper',
  components: {
    Main
  }
};
</script>
