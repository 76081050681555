import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'assetGroup',
    plural: 'assetGroups',
    url: 'asset-groups'
  }),
  listAssets: async ({ commit }, { id, data }) => {
    try {
      commit('LIST_ASSETS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/assets`, data);
      commit('LIST_ASSETS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_ASSETS_ERROR', e);
      console.log(e);
      return e;
    }
  },
  addAsset: async ({ commit }, { id, assetId }) => {
    try {
      commit('ADD_ASSET_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/asset/${assetId}`);
      commit('ADD_ASSET_SUCCESS', body.data);
      return body;
    } catch (e) {
      commit('ADD_ASSET_ERROR', e);
      console.log(e);
      return e;
    }
  },
  addAssets: async ({ commit }, { id, assetIds }) => {
    try {
      commit('ADD_ASSET_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/assets/${assetIds}`);
      commit('ADD_ASSET_SUCCESS', body.data);
      return body;
    } catch (e) {
      commit('ADD_ASSET_ERROR', e);
      console.log(e);
      return e;
    }
  },
  removeAsset: async ({ commit }, { id, assetId }) => {
    try {
      commit('REMOVE_ASSET_REQUEST', assetId);
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/asset/${assetId}`);
      commit('REMOVE_ASSET_SUCCESS', body.data);
      return body;
    } catch (e) {
      commit('REMOVE_ASSET_ERROR', e);
      console.log(e);
      return e;
    }
  },
  getAnalytics: async ({ commit }, { id, options }) => {
    const qs = [];

    Object.keys(options).forEach(option => {
      qs.push(`${option}=${options[option]}`);
    });

    try {
      commit('GET_ANALYTICS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/analytics?${qs.join('&')}`);
      commit('GET_ANALYTICS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ANALYTICS_ERROR', e);
      return e;
    }
  },
  getReportData: async ({ commit }, { id }) => {
    try {
      commit('GET_REPORT_DATA_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/report-data`);
      commit('GET_REPORT_DATA_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_REPORT_DATA_ERROR', e);
      return e;
    }
  },
  searchAssets: async ({ commit }, { params, id }) => {
    try {
      commit('SEARCH_ASSETS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/search-assets`, { params });
      commit('SEARCH_ASSETS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEARCH_ASSETS_ERROR', e);
      return e;
    }
  },
  getFloorArea: async ({ commit }, { id }) => {
    try {
      commit('GET_FLOOR_AREA_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups/${id}/floor-area`);
      commit('GET_FLOOR_AREA_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_FLOOR_AREA_ERROR', e);
      return e;
    }
  }
};
