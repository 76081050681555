<template>
  <div id="app">
    <div class="etn-new-version-box d-none">
      <i class="fa fa-triangle-exclamation mr-1"></i>There's a new version of Etainabl available!
      <a href="#" @click.prevent="refresh">Click here to refresh.</a>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    refresh() {
      window.location.reload();
    }
  }
};
</script>
