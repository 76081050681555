import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'supplier',
    plural: 'suppliers'
  }),

  SUPPLIER_INVOICE_ANALYSIS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.invoiceAnalysis = true;
    state.errorAction.invoiceAnalysis = false;
  },
  SUPPLIER_INVOICE_ANALYSIS_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.invoiceAnalysis = false;
    state.errorAction.invoiceAnalysis = false;
  },
  SUPPLIER_INVOICE_ANALYSIS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.invoiceAnalysis = false;
    state.errorAction.invoiceAnalysis = error;
  },

  SUPPLIER_ANALYSIS_CHECK_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.invoiceAnalysis = true;
    state.errorAction.invoiceAnalysis = false;
  },
  SUPPLIER_ANALYSIS_CHECK_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.invoiceAnalysis = false;
    state.errorAction.invoiceAnalysis = false;

    state.supplier.invoiceAnalysis = body;
  },
  SUPPLIER_ANALYSIS_CHECK_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.invoiceAnalysis = false;
    state.errorAction.invoiceAnalysis = error;
  },

  LIST_DISTRIBUTORS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.distributors = true;
    state.errorAction.distributors = false;
  },
  LIST_DISTRIBUTORS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.distributors = false;
    state.errorAction.distributors = false;

    state.distributors = body;
  },
  LIST_DISTRIBUTORS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.distributors = false;
    state.errorAction.distributors = error;
  }
};
