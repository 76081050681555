import moment from 'moment';

export const setDefaultForm = (defaults, item = {}) => {
  const form = {};

  Object.keys(defaults).forEach(key => {
    form[key] = item[key] || defaults[key];
  });

  return form;
};

export const numberWithCommas = x => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};

export const numberFormat = (value = 0, precision = 0, minPrecision = 0) => {
  return Number(value).toLocaleString('en-GB', { minimumFractionDigits: minPrecision, maximumFractionDigits: precision });
};

export const capitalize = x => {
  if (!x) return x;
  return `${x.charAt(0).toUpperCase()}${x.slice(1)}`;
};

export const niceDate = (value, format = 'DD/MM/YYYY', fromFormat, isUtc) => {
  let m = isUtc ? moment.utc : moment;

  if (!value || !m(value).isValid()) return value;
  if (fromFormat) return m(value, fromFormat).format(format);
  return m(value).format(format);
};

export const duration = (startDate, endDate, unit = 'seconds') => {
  return moment.duration(moment(endDate).diff(moment(startDate)))[unit]();
};

export const timeAgo = value => {
  if (!moment(value).isValid()) return value;
  return moment(value).fromNow();
};

export const getColSpan = rows => {
  if (!rows) return 1;

  const totalCells = rows.reduce((sum, val) => (val ? val.length + sum : sum), 0);
  return Math.ceil(totalCells / rows.length);
};

export const round = (n, p = 2) => {
  if (typeof n === 'undefined' || n === null) return n;

  const isNumber = (Number(n) === n && n % 1 === 0) || (Number(n) === n && n % 1 !== 0);

  if (!isNumber) return n;

  return n.toFixed(p);
};

export const niceAddress = address => {
  if (!address) return 'No Address';

  const addressParts = [];

  if (address.streetAddress) addressParts.push(address.streetAddress);
  if (address.locality) addressParts.push(address.locality);
  if (address.region) addressParts.push(address.region);
  if (address.postCode) addressParts.push(address.postCode);

  return addressParts.join(', ');
};

export const friendlyInvoiceName = invoice => {
  if (!invoice.account) return invoice.fileName;

  let name = '';

  // Entity and Asset Info
  if (invoice.account && invoice.account.asset && (invoice.account.entity || invoice.account.asset.entity))
    name += `${(invoice.account.entity || invoice.account.asset.entity).legalName} - ${invoice.account.asset.siteName}`;

  // Account Type
  name += ` - ${capitalize(invoice.account.type)}`;

  // Invoice Dates
  if (invoice.values && (invoice.values.startDate || invoice.values.endDate)) {
    if (invoice.values.startDate) {
      name += ` ${niceDate(invoice.values.startDate)}`;
    } else {
      name += ' [No Start Date]';
    }

    name += ' to ';

    if (invoice.values.endDate) {
      name += niceDate(invoice.values.endDate);
    } else {
      name += '[No End Date]';
    }
  }

  return name;
};

export const yearToDate = () => {
  return [moment().startOf('year'), moment()];
};

export const reportingPeriods = (type = 'ranges') => {
  const lastYear = moment().subtract(1, 'year').format('YYYY');
  const last2Year = moment().subtract(2, 'year').format('YYYY');

  const periods = {
    ranges: {
      'Current month': [moment().startOf('month'), moment()],
      'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Past month to date': [moment().subtract(1, 'month'), moment()],
      'Last 3 calendar months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 12 calendar months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
      'Current year': [moment().startOf('year'), moment()],
      'Past year to date': [moment().subtract(1, 'year'), moment()],
      [`Year ${lastYear}`]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      [`Year ${last2Year}`]: [moment().subtract(2, 'year').startOf('year'), moment().subtract(2, 'year').endOf('year')]
    },
    single: {
      Today: moment(),
      Yesterday: moment().subtract(1, 'day'),
      '1 Week Ago': moment().subtract(1, 'week'),
      '1 Month Ago': moment().subtract(1, 'week'),
      'This Month (Start)': moment().startOf('month'),
      'Last Month (Start)': moment().subtract(1, 'month').startOf('month'),
      'Last Month (End)': moment().subtract(1, 'month').endOf('month'),
      'This Year (Start)': moment().startOf('year'),
      'Last Year (Start)': moment().subtract(1, 'year').startOf('year'),
      'Last Year (End)': moment().subtract(1, 'year').endOf('year')
    }
  };

  return periods[type];
};

export const floorAreaUnit = unit => {
  if (!unit) return '';

  switch (unit) {
    case 'metric':
      return 'm²';
    case 'imperial':
      return 'ft²';
    default:
      return unit;
  }
};

export const floorAreaConversion = (value, unit) => {
  if (!value) return value;

  switch (unit) {
    case 'metric':
      return Number(value);
    case 'imperial':
      return Number(value) * 0.092903;
    default:
      return Number(value);
  }
};

export const utilityUnit = (value, type) => {
  switch (type) {
    case 'electricity':
      return value + ' kWh';
    case 'gas':
      return value + ' kWh';
    case 'water':
      return value + ' m²';
    case 'waste':
      return value + ' kg';
    default:
      return value + ' kWh';
  }
};

export const niceUser = (userSub, users) => {
  if (!userSub || !users || users.length === 0) return 'N/A';

  const user = users.find(u => u.sub === userSub || u.user_id === userSub || u.userSub === userSub);

  if (user) return user.name;

  return userSub;
};

export const fileIcon = extension => {
  const icons = {
    pdf: 'fa-file-pdf',
    doc: 'fa-file-word',
    docx: 'fa-file-word',
    xls: 'fa-file-excel',
    xlsx: 'fa-file-excel',
    ppt: 'fa-file-powerpoint',
    pptx: 'fa-file-powerpoint',
    zip: 'fa-file-archive',
    rar: 'fa-file-archive',
    txt: 'fa-file-alt',
    jpg: 'fa-file-image',
    jpeg: 'fa-file-image',
    png: 'fa-file-image',
    gif: 'fa-file-image',
    bmp: 'fa-file-image',
    epb: 'fa-file-certificate',
    breeam: 'fa-file-certificate',
    wiredScore: 'fa-file-certificate'
  };

  return icons[extension] || 'fa-file';
};
