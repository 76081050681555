import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'asset',
    plural: 'assets'
  }),
  getAssetGroups: async ({ commit }, { id }) => {
    try {
      commit('GET_ASSET_GROUPS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/asset-groups`);
      commit('GET_ASSET_GROUPS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_GROUPS_ERROR', e);
      console.log(e);
      return e;
    }
  },
  updateAddress: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_ADDRESS_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/assets/${id}/address`, {
        data
      });
      commit('UPDATE_ADDRESS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_ADDRESS_ERROR', {
        type: 'lookup',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  updateSatelliteImages: async ({ commit }, { id }) => {
    try {
      commit('UPDATE_SATELLITE_IMAGES_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/assets/${id}/satellite-images`);
      commit('UPDATE_SATELLITE_IMAGES_SUCCESS', body);
      return body;
    } catch (e) {
      return e;
    }
  },
  crime: async ({ commit }, { id }) => {
    try {
      commit('GET_ASSET_CRIME_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/crime`);
      commit('GET_ASSET_CRIME_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_CRIME_ERROR', e);
      console.log(e);
      return e;
    }
  },
  floodRisks: async ({ commit }, { id, params = {} }) => {
    try {
      commit('GET_FLOOD_RISKS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/flood-risks`, { params });
      commit('GET_FLOOD_RISKS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_FLOOD_RISKS_ERROR', e);
      console.log(e);
      return e;
    }
  },
  airQuality: async ({ commit }, { id }) => {
    try {
      commit('GET_ASSET_AIR_QUALITY_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/air-quality`);
      commit('GET_ASSET_AIR_QUALITY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_AIR_QUALITY_ERROR', e);
      console.log(e);
      return e;
    }
  },
  getSatelliteImages: async ({ commit }, { id }) => {
    try {
      commit('GET_ASSET_SATELLITE_IMAGES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/satellite-images`);
      commit('GET_ASSET_SATELLITE_IMAGES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_SATELLITE_IMAGES_ERROR', e);
      console.log(e);
      return e;
    }
  },
  accounts: async ({ commit }, { id, data }) => {
    try {
      commit('GET_ASSET_ACCOUNTS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts?assetId=${id}`, data);
      commit('GET_ASSET_ACCOUNTS_SUCCESS', body.data);
      return body.data;
    } catch (e) {
      commit('GET_ASSET_ACCOUNTS_ERROR', e);
      console.log(e);
      return e;
    }
  },
  getInvoicesByAccount: async ({ commit }, { id, data }) => {
    try {
      commit('GET_INVOICES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices?accountId=${id}&status=completed`, data);
      commit('GET_INVOICES_SUCCESS', body.data);
      return body.data;
    } catch (e) {
      commit('GET_INVOICES_ERROR', e);
      console.log(e);
      return e;
    }
  },
  updateAccountInvoice: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_ACCOUNT_INVOICE_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/invoices/${id}`, data);
      commit('UPDATE_ACCOUNT_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_ACCOUNT_INVOICE_ERROR', e);
      return e;
    }
  },
  deleteAccountInvoice: async ({ commit }, { id }) => {
    try {
      commit('DELETE_ACCOUNT_INVOICE_REQUEST', id);
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/invoices/${id}`);
      commit('DELETE_ACCOUNT_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_ACCOUNT_INVOICE_ERROR', e);
      return e;
    }
  },
  restoreAccountInvoice: async ({ commit }, { id }) => {
    try {
      commit('RESTORE_ACCOUNT_INVOICE_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/invoices/${id}/restore`);
      commit('RESTORE_ACCOUNT_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('RESTORE_ACCOUNT_INVOICE_ERROR', e);
      return e;
    }
  },
  getAssetAnalytics: async ({ commit }, { id, options, type }) => {
    const qs = [];

    Object.keys(options).forEach(option => {
      qs.push(`${option}=${options[option]}`);
    });

    try {
      commit('GET_ASSET_ANALYTICS_REQUEST', type);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/analytics?${qs.join('&')}`);
      commit('GET_ASSET_ANALYTICS_SUCCESS', { type });
      return body;
    } catch (e) {
      commit('GET_ASSET_ANALYTICS_ERROR', { type, error: e });
      return e;
    }
  },
  getAssetGroupAnalytics: async ({ commit }, { id, options }) => {
    const qs = [];

    Object.keys(options).forEach(option => {
      qs.push(`${option}=${options[option]}`);
    });

    try {
      commit('GET_ASSET_GROUP_ANALYTICS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/group-analytics?${qs.join('&')}`);
      commit('GET_ASSET_GROUP_ANALYTICS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_GROUP_ANALYTICS_ERROR', e);
      return e;
    }
  },
  uploadCsv: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_CSV_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/import`, data, {
        progress(e) {
          commit('UPLOAD_CSV_PROGRESS', (e.loaded / e.total) * 100);
        }
      });
      commit('UPLOAD_CSV_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ERROR', e);
      return e;
    }
  },
  getProject: async ({ commit }, { id }) => {
    try {
      commit('GET_PROJECT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/projects/${id}`);
      commit('GET_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_PROJECT_ERROR', e.message);
      return e;
    }
  },
  listProjects: async ({ commit }, { params } = { params: {} }) => {
    try {
      commit('LIST_PROJECTS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/projects`, { params });
      commit('LIST_PROJECTS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_PROJECTS_ERROR', e.message);
      return e;
    }
  },
  updateProject: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_PROJECT_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/projects/${id}`, data);
      commit('UPDATE_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_PROJECT_ERROR', {
        type: 'update',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      return e;
    }
  },
  createProject: async ({ commit }, { data }) => {
    try {
      commit('CREATE_PROJECT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/projects`, data);
      commit('CREATE_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CREATE_PROJECT_ERROR', {
        type: 'create',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  deleteProject: async ({ commit }, { id }) => {
    try {
      commit('DELETE_PROJECT_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/projects/${id}`);
      commit('DELETE_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_PROJECT_ERROR', e.message);
      return e;
    }
  },
  uploadProjectAttachment: async ({ commit }, { id, data, name }) => {
    try {
      commit('UPLOAD_PROJECT_ATTACHMENT_REQUEST');
      commit('UPLOAD_PROJECT_ATTACHMENT_PROGRESS', 0);

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/projects/${id}/documents/upload`, data, name, {
        progress(e) {
          commit('UPLOAD_PROJECT_ATTACHMENT_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_PROJECT_ATTACHMENT_SUCCESS', body);
      commit('UPLOAD_PROJECT_ATTACHMENT_PROGRESS', 100);

      return body;
    } catch (e) {
      commit('UPLOAD_PROJECT_ATTACHMENT_ERROR', e.body || e);
      return e;
    }
  },
  deleteProjectAttachment: async ({ commit }, { id, documentId }) => {
    try {
      commit('DELETE_PROJECT_ATTACHMENT_REQUEST', documentId);
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/projects/${id}/documents/${documentId}`);
      commit('DELETE_PROJECT_ATTACHMENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_PROJECT_ATTACHMENT_ERROR', e.message);
      return e;
    }
  },
  downloadProjectAttachment: async ({ commit }, { id, documentId }) => {
    try {
      commit('DOWNLOAD_PROJECT_ATTACHMENT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/projects/${id}/documents/${documentId}/download`);
      commit('DOWNLOAD_PROJECT_ATTACHMENT_SUCCESS', body.downloadUrl);
      return body.downloadUrl;
    } catch (e) {
      commit('DOWNLOAD_PROJECT_ATTACHMENT_ERROR', e);
      return e;
    }
  },
  updateProjectTask: async ({ commit }, { id, taskId, data }) => {
    try {
      commit('UPDATE_PROJECT_TASK_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/projects/${id}/tasks/${taskId}`, data);
      commit('UPDATE_PROJECT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_PROJECT_TASK_ERROR', e);

      return e;
    }
  },
  createProjectTask: async ({ commit }, { id, data }) => {
    try {
      commit('CREATE_PROJECT_TASK_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/projects/${id}/tasks`, data);
      commit('CREATE_PROJECT_TASK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CREATE_PROJECT_TASK_ERROR', e);

      return e;
    }
  },
  deleteProjectTask: async ({ commit }, { id, taskId }) => {
    try {
      commit('DELETE_PROJECT_TASK_REQUEST', taskId);
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/projects/${id}/tasks/${taskId}`);
      commit('DELETE_PROJECT_TASK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_PROJECT_TASK_ERROR', e.message);
      return e;
    }
  },
  uploadDocuments: async ({ commit }, { id, data }) => {
    try {
      commit('UPLOAD_DOCUMENT_REQUEST');
      commit('UPLOAD_DOCUMENT_PROGRESS', 0);

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/${id}/documents/upload`, data, {
        progress(e) {
          commit('UPLOAD_DOCUMENT_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_DOCUMENT_SUCCESS', body);
      commit('UPLOAD_DOCUMENT_PROGRESS', 100);

      return body;
    } catch (e) {
      console.log('e', e);
      commit('UPLOAD_DOCUMENT_ERROR', e.body || e);
      return e;
    }
  },
  createDocument: async ({ commit }, { id, data }) => {
    try {
      commit('CREATE_DOCUMENT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/${id}/documents`, data);
      commit('CREATE_DOCUMENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CREATE_DOCUMENT_ERROR', {
        type: 'create',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  updateDocument: async ({ commit }, { id, documentId, data }) => {
    try {
      commit('UPDATE_DOCUMENT_REQUEST', { id, documentId });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/assets/${id}/documents/${documentId}`, data);
      commit('UPDATE_DOCUMENT_SUCCESS', { id, documentId, body });
      return body;
    } catch (e) {
      commit('UPDATE_DOCUMENT_ERROR', {
        params: { id, documentId },
        type: 'create',
        message: 'There was a problem with submitting.',
        data: e.message
      });

      return e;
    }
  },
  deleteDocument: async ({ commit }, { id, documentId }) => {
    try {
      commit('DELETE_DOCUMENT_REQUEST', documentId);
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/assets/${id}/documents/${documentId}`);
      commit('DELETE_DOCUMENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_DOCUMENT_ERROR', e.message);
      return e;
    }
  },
  downloadDocument: async ({ commit }, { id, documentId }) => {
    try {
      commit('DOWNLOAD_DOCUMENT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/documents/${documentId}/download`);
      commit('DOWNLOAD_DOCUMENT_SUCCESS', body.downloadUrl);
      return body.downloadUrl;
    } catch (e) {
      commit('DOWNLOAD_DOCUMENT_ERROR', e);
      return e;
    }
  },
  addStatusHistory: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_STATUS_HISTORY_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/${id}/status-history`, data);
      commit('ADD_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('ADD_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  updateStatusHistory: async ({ commit }, { id, statusId, data }) => {
    try {
      delete data._id;

      commit('UPDATE_STATUS_HISTORY_REQUEST', { id, statusId, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/assets/${id}/status-history/${statusId}`, data);
      commit('UPDATE_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  deleteStatusHistory: async ({ commit }, { id, statusId }) => {
    try {
      commit('DELETE_STATUS_HISTORY_REQUEST', { id, statusId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/assets/${id}/status-history/${statusId}`);
      commit('DELETE_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  createTimelineEvent: async ({ commit }, { data, _id }) => {
    try {
      commit('CREATE_TIMELINE_EVENT_REQUEST');

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/${_id}/timeline-events`, data);

      commit('CREATE_TIMELINE_EVENT_SUCCESS', body);

      return body;
    } catch (e) {
      commit('CREATE_TIMELINE_EVENT_ERROR', e);
      return e;
    }
  },
  updateTimelineEvent: async ({ commit }, { data, _id, eventId }) => {
    try {
      commit('UPDATE_TIMELINE_EVENT_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/assets/${_id}/timeline-events/${eventId}`, data);
      commit('UPDATE_TIMELINE_EVENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_TIMELINE_EVENT_ERROR', e);
      return e;
    }
  },
  deleteTimelineEvent: async ({ commit }, { _id, eventId }) => {
    try {
      commit('DELETE_TIMELINE_EVENT_REQUEST');

      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/assets/${_id}/timeline-events/${eventId}`);

      commit('DELETE_TIMELINE_EVENT_SUCCESS', body);

      return body;
    } catch (e) {
      commit('DELETE_TIMELINE_EVENT_ERROR', e);
      return e;
    }
  },

  search: async ({ commit }, { query, params }) => {
    const qs = [];
    qs.push(`query=${query}`);

    Object.keys(params || {}).forEach(option => {
      qs.push(`${option}=${params[option]}`);
    });

    try {
      commit('FIND_ASSETS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/search?${qs.join('&')}`);
      commit('FIND_ASSETS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('FIND_ASSETS_ERROR', e);
      return e;
    }
  },
  getTimelineData: async ({ commit }, { id }) => {
    try {
      commit('GET_TIMELINE_EVENT_REQUEST');

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/timeline-events`);

      commit('GET_TIMELINE_EVENT_SUCCESS', body);

      return body;
    } catch (e) {
      commit('GET_TIMELINE_EVENT_ERROR', e);
      return e;
    }
  },
  lookupEpbCertificates: async ({ commit }, { params }) => {
    try {
      commit('LOOKUP_EPB_CERTIFICATES_REQUEST', { params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_EPB_URL}/certificates`, { params });
      commit('LOOKUP_EPB_CERTIFICATES_SUCCESS', { params, body });
      return body;
    } catch (e) {
      commit('LOOKUP_EPB_CERTIFICATES_ERROR', e);
      return e;
    }
  },
  getEpbCertificate: async ({ commit }, { id, params }) => {
    try {
      commit('GET_EPB_CERTIFICATE_REQUEST', { id, params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_EPB_URL}/certificates/${id}`, { params });
      commit('GET_EPB_CERTIFICATE_SUCCESS', { id, params, body });
      return body;
    } catch (e) {
      commit('GET_EPB_CERTIFICATE_ERROR', { id, error: e });
      return e;
    }
  },
  lookupAssetCertificates: async ({ commit }, { params }) => {
    try {
      commit('LOOKUP_ASSET_CERTIFICATES_REQUEST', { params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/asset-certificates`, { params });
      commit('LOOKUP_ASSET_CERTIFICATES_SUCCESS', { params, body });
      return body;
    } catch (e) {
      commit('LOOKUP_ASSET_CERTIFICATES_ERROR', e);
      return e;
    }
  },
  modifyRecommendation: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_ASSET_RECOMMENDATION_REQUEST', { data });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/${id}/recommendations`, data);
      commit('UPDATE_ASSET_RECOMMENDATION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_ASSET_RECOMMENDATION_ERROR', e);
      return e;
    }
  },
  deleteRecommendation: async ({ commit }, { id, recommendationId }) => {
    try {
      commit('DELETE_ASSET_RECOMMENDATION_REQUEST', { recommendationId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/assets/${id}/recommendations/${recommendationId}`);
      commit('DELETE_ASSET_RECOMMENDATION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_ASSET_RECOMMENDATION_ERROR', e);
      return e;
    }
  },
  getGeocoordinates: async ({ commit }, { id, data }) => {
    try {
      commit('GET_GEOCOORDINATES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/${id}/geocoordinates`, data);
      commit('GET_GEOCOORDINATES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_GEOCOORDINATES_ERROR', {
        message: 'There was a problem getting geocoordinates.',
        data: e.body
      });
      return e;
    }
  },
  getImageUploadUrl: async ({ commit }, { fileType }) => {
    try {
      commit('GET_IMAGE_UPLOAD_URL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets/image-upload-url?fileType=${fileType}`);
      commit('GET_IMAGE_UPLOAD_URL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_IMAGE_UPLOAD_URL_ERROR', {
        message: 'There was a problem getting an upload URL.',
        data: e.body
      });
      return e;
    }
  }
};
