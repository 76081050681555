import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    total: 0,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    supplier: {},
    suppliers: [],
    distributors: [],
    validationErrors: {},
    schema: []
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    supplier: state => state.supplier,
    suppliers: state => state.suppliers,
    distributors: state => state.distributors,
    total: state => state.total,
    validationErrors: state => state.validationErrors,
    schema: state => state.schema
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
