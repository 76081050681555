export default {
  SET_DEBUG: (state, debug) => {
    state.debug = {
      ...state.debug,
      ...debug
    };
  },

  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },

  SET_SLIDE_INDEX: (state, index) => {
    state.slideIndex = index;
  },

  GET_INFO_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getInfo = true;
    state.errorAction.getInfo = false;
  },
  GET_INFO_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getInfo = false;
    state.errorAction.getInfo = false;

    state.kiosk = body;
  },
  GET_INFO_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getInfo = false;
    state.errorAction.getInfo = error;
  },

  GET_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getConsumption = true;
    state.errorAction.getConsumption = false;
  },
  GET_CONSUMPTION_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getConsumption = false;
    state.errorAction.getConsumption = false;

    state.consumption = body;
  },
  GET_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getConsumption = false;
    state.errorAction.getConsumption = error;
  }
};
