import machineGuard from '../../auth/machineGuard';

export default [
  {
    path: 'asset',
    name: 'machine-view',
    beforeEnter: machineGuard(),
    component: () => import('./MachineView')
  },

  {
    path: 'pdf',
    name: 'machine-pdf',
    beforeEnter: machineGuard(),
    component: () => import('./MachinePDF')
  }
];
