import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    validationErrors: {},
    benchmark: {},
    benchmarks: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    benchmark: state => state.benchmark,
    benchmarks: state => state.benchmarks
  },
  mutations: {
    SET_VALIDATIONS_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
