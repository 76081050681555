import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getLogDownloadUrl: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getLogDownloadUrl: false
    },
    scraperRuns: {
      data: [],
      total: 0,
      skip: 0
    },
    suppliers: [],
    params: {},
    automaticRefresh: false
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    scraperRunsTotal: state => state.scraperRuns.total,
    scraperRuns: state => state.scraperRuns.data,
    suppliers: state => state.suppliers,
    autoRefresh: state => state.automaticRefresh
  },
  mutations: {
    RESET_STATE: state => {
      Object.assign(state, initialState());
    },
    SET_PARAMS: (state, params) => {
      state.params = params;
    },
    SET_SUPPLIERS: (state, suppliers) => {
      state.suppliers = suppliers;
    },
    SET_AUTO: state => {
      state.automaticRefresh = !state.automaticRefresh;
    },
    INSERT_LOG: (state, log) => {
      if (!state.automaticRefresh) return;
      if (typeof state.params.supplierId === 'string' && state.params.supplierId !== log.supplierId) {
        return;
      }

      if (state.params.accountId && state.params.accountId !== log.accountId) {
        return;
      }

      // Ignore if not on page 1 or status does not match current filter status
      if (state.params.$skip > 0 || (state.params?.status !== '$exists' && state.params.status !== log.status)) {
        return;
      }

      state.scraperRuns.data.pop();
      state.scraperRuns.data = [log, ...state.scraperRuns.data];
    },
    UPDATE_LOGS: (state, log) => {
      console.log(log);
      if (!state.automaticRefresh) return;
      if (state.scraperRuns.data.length === 0) return;

      if (typeof state.params.supplierId === 'string' && state.params.supplierId !== log.supplierId) {
        return;
      }

      if (state.params.accountId && state.params.accountId !== log.accountId) {
        return;
      }

      const index = state.scraperRuns.data.findIndex(s => s._id === log._id);
      if (index === -1) return;

      if (state.params?.status !== '$exists' && log.status !== state.param?.status) return state.scraperRuns.data.splice(index, 1);
      Object.assign(state.scraperRuns.data[index], log);
    },
    UPDATE_SUPPLIER_PORTAL: (state, { supplierId, portal }) => {
      const supplierIndex = state.suppliers.findIndex(s => s._id === supplierId);
      const supplier = state.suppliers[supplierIndex];

      supplier.isEnabled = portal.scraperEnabled || supplier.isCompanyEnabled || supplier.isAnyAccountEnabled;
      supplier.isGloballyEnabled = portal.scraperEnabled;
      supplier.portal = portal;

      Object.assign(state.suppliers[supplierIndex], supplier);
    },
    UPDATE_COMPANY_PORTAL: (state, { supplierId, portal }) => {
      const supplierIndex = state.suppliers.findIndex(s => s._id === supplierId);
      const supplier = state.suppliers[supplierIndex];

      supplier.isEnabled = portal.scraperEnabled || supplier.isGloballyEnabled || supplier.isAnyAccountEnabled;
      supplier.isCompanyEnabled = portal.scraperEnabled;

      Object.assign(state.suppliers[supplierIndex], supplier);
    },
    UPDATE_SUPPLIER_ACCOUNT: (state, { supplierId, portal, accountId }) => {
      const supplierIndex = state.suppliers.findIndex(s => s._id === supplierId);
      const accountIndex = state.suppliers[supplierIndex].accounts.findIndex(a => a.id === accountId);

      state.suppliers[supplierIndex].accounts[accountIndex].portal = portal;
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
