import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    total: 0,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    validationErrors: {},
    reportTemplates: [],
    reportTemplate: {},
    systemTemplates: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    total: state => state.total,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    validationErrors: state => state.validationErrors,
    reportTemplates: state => state.reportTemplates,
    reportTemplate: state => state.reportTemplate,
    systemTemplates: state => state.systemTemplates
  },
  mutations: {
    RESET_STATE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
