<template>
  <header id="page-header">
    <div class="content-header justify-content-between">
      <div>
        <button type="button" class="btn btn-primary mr-2" @click.prevent="onClickMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <button type="button" class="btn btn-primary" data-cy="global-search" @click="showSearch = !showSearch">
          <i class="fa fa-fw fa-search"></i> <span>{{ $t('SEARCH') }}</span>
        </button>
      </div>
      <div v-if="$auth.isAuthenticated" class="d-flex align-items-center">
        <div v-if="$auth.company.settings.isDemo" class="text-warning font-w600 mr-2">
          <i class="fa-solid fa-triangle-exclamation"></i> TESTING ENVIRONMENT
        </div>
        <div v-if="$auth.isAdmin" class="text-warning font-w600 mr-2">ADMIN</div>
        <div class="dropdown d-inline-block mr-2">
          <button
            id="page-header-notification-dropdown"
            type="button"
            class="btn btn-link text-white d-flex align-items-center"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>
              <i class="fa fa-bell"></i>
              <span
                class="badge badge-pill ml-2"
                :class="{ 'badge-danger': notifications.unseenCount > 0, 'badge-white': notifications.unseenCount === 0 }"
                >{{ notifications.unseenCount }}</span
              ></span
            >
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-notifications-dropdown"
            x-placement="top-end"
          >
            <div class="bg-primary rounded-top font-w600 text-white text-center p-3">{{ $tc('NOTIFICATION', 2) }}</div>
            <ul class="nav-items my-2">
              <li v-for="notification in seenNotifications.slice(0, 5)" :key="notification._id">
                <router-link
                  class="media py-2"
                  :class="{ 'text-dark': !notification.isSeen, 'text-black-25': notification.isSeen }"
                  :to="notification.route"
                  @click.native="onClickNotification(notification._id)"
                >
                  <div class="mx-3">
                    <i class="fa fa-fw mr-1" :class="notification.icon"></i>
                  </div>
                  <div class="media-body font-size-sm pr-2">
                    <div class="mb-1" v-html="notification.content"></div>
                    <em
                      :class="{ 'text-muted': !notification.isSeen, 'text-black-25': notification.isSeen }"
                      :title="notification.createdAt | date('DD/MM/YYYY HH:mm:ss')"
                    >
                      {{ notification.createdAt | timeago }}
                    </em>
                  </div>
                </router-link>
              </li>
              <li v-if="seenNotifications.length === 0">
                <div class="text-muted mx-3">{{ $t('NO_PENDING_NOTIFICATIONS') }}</div>
              </li>
            </ul>
            <div class="p-2 border-top">
              <router-link class="btn btn-alt-success btn-block text-center" :to="{ name: 'notifications' }">
                <i class="fa fa-fw fa-eye mr-1"></i> {{ $t('VIEW_ALL') }}
              </router-link>
              <a href="#" class="btn btn-light btn-block text-center" @click.prevent="onClickDismissAll">
                <i class="fa fa-fw fa-check mr-1"></i> {{ $t('DISMISS_ALL') }}
              </a>
            </div>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button
            id="page-header-user-dropdown"
            type="button"
            class="btn btn-link text-white dropdown-toggle d-flex align-items-center"
            data-toggle="dropdown"
            aria-haspopup="true"
            data-cy="user-dropdown"
            aria-expanded="false"
          >
            <span id="page-header-user-details" class="mr-2 text-right"
              ><div class="mb-1">{{ $auth.user.name }}</div>
              <div :key="$auth.companyId" class="font-w400 small">{{ activeCompany }}</div></span
            >
            <img class="img-avatar img-avatar32" :src="$auth.user.picture" alt="" />
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right overflow-hidden" aria-labelledby="page-header-user-dropdown">
            <router-link class="dropdown-item" to="/profile" data-cy="user-settings">
              <i class="fa fa-fw fa-user-circle mr-1"></i>
              {{ $t('PROFILE') }}
            </router-link>
            <router-link
              v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))"
              data-cy="company-settings"
              class="dropdown-item"
              :to="{ name: 'company-view', params: { id: $auth.companyId } }"
            >
              <i class="fa fa-fw fa-building mr-1"></i>
              {{ $t('COMPANY_SETTINGS') }}
            </router-link>

            <div v-if="companies.length > 1" class="dropdown-divider"></div>

            <div v-if="companies.length > 1" class="pt-2 pb-3 px-3">
              <Spinner v-if="switching" size="sm">Switching...</Spinner>
              <div v-else>
                <label><i class="fa fa-fw fa-arrows-repeat mr-1"></i>Company Switcher</label>
                <select :value="$auth.companyId" class="form-control form-control-alt" @click.stop @change="onChangeCompanyId">
                  <option v-for="company in companies" :key="company._id" :value="company._id">{{ company.name }}</option>
                </select>
              </div>
            </div>

            <div class="dropdown-divider"></div>

            <div class="dropdown-item text-gray font-size-sm disabled">
              <i class="fa fa-fw fa-code-commit mr-1"></i> App {{ $t('VERSION') }}: {{ appVersion.slice(0, 8) }}
            </div>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item text-danger" href="#" @click.prevent="$auth.logout">
              <i class="fa fa-fw fa-sign-out-alt ml-1"></i>
              {{ $t('LOG_OUT') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Search v-if="showSearch" :key="$route.path" @close="showSearch = false" />
  </header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Search from './Search';
import Spinner from './SpinnerLogo';

export default {
  name: 'Header',
  components: {
    Search,
    Spinner
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      showSearch: false,
      entity: {},
      notificationInterval: null,
      switching: false
    };
  },
  computed: {
    ...mapGetters({
      companies: 'company/globalCompanies',
      totalCompanies: 'company/total',
      notifications: 'user/notifications',
      company: 'user/company'
    }),
    activeCompany() {
      if (!this.$auth.isAuthenticated) return null;

      if (!this.company || !this.company.name) return 'N/A';

      if (this.entity.legalName) {
        return `${this.entity.legalName} (${this.company.name})`;
      }

      return this.company.name;
    },
    seenNotifications() {
      return this.notifications.data.filter(n => !n.isSeen);
    }
  },
  mounted() {
    setTimeout(async () => {
      this.getCompany();

      this.getCompanies();

      if (this.$auth.entityId) {
        this.entity = await this.getEntity({ id: this.$auth.entityId });
      }

      await this.getNotifications();
    }, 1000);

    this.notificationInterval = setInterval(async () => {
      await this.getNotifications();
    }, 600000);
  },
  beforeDestroy() {
    clearInterval(this.notificationInterval);
  },
  methods: {
    ...mapActions({
      getCompanies: 'company/getGlobalCompanies',
      getCompany: 'user/getCompany',
      getEntity: 'user/getEntity',
      setCompanyId: 'user/setCompanyId',
      getNotifications: 'user/getNotifications',
      seenNotification: 'user/seenNotification'
    }),
    onClickMenu() {
      this.$emit('onToggleMenu');
    },
    async onChangeCompanyId(e) {
      this.switching = true;
      await this.setCompanyId({ userSub: this.$auth.user.sub, companyId: e.target.value });
      this.$auth.loginWithRedirect({ appState: { targetUrl: this.$route.fullPath } });
    },
    onClickNotification(notificationId) {
      this.seenNotification({ id: notificationId });
    },
    onClickDismissAll() {
      this.seenNotification({ id: this.seenNotifications.map(n => n._id) });
    }
  },
  watch: {
    '$route.path'() {
      this.showSearch = false;
    }
  }
};
</script>
<style lang="scss">
#page-header-user-details {
  line-height: 12px;
}
</style>
