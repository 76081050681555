import Vue from 'vue';

export default {
  CREATE_RECOMMENDATION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.recommendation = true;
    state.errorAction.recommendation = false;
    state.validationErrors = {};
  },
  CREATE_RECOMMENDATION_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.recommendation = false;
    state.loadingAction.recommendation = false;
    state.global.push(body);
  },
  CREATE_RECOMMENDATION_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.recommendation = error.body;
    state.loadingAction.recommendation = false;

    if (error.body.formMessages) state.validationErrors = error.body.formMessages;
  },
  UPDATE_RECOMMENDATION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.recommendation = true;
    state.errorAction.recommendation = false;
    state.validationErrors = {};
  },
  UPDATE_RECOMMENDATION_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.recommendation = false;
    state.loadingAction.recommendation = false;
    const index = state.global.findIndex(d => d._id === body._id);

    Vue.set(state.global, index, body);
  },
  UPDATE_RECOMMENDATION_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.recommendation = error.body;
    state.loadingAction.recommendation = false;

    if (error.body.formMessages) state.validationErrors = error.body.formMessages;
  },
  REMOVE_RECOMMENDATION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.recommendation = true;
    state.errorAction.recommendation = false;
    state.validationErrors = {};
  },
  REMOVE_RECOMMENDATION_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.recommendation = false;
    state.loadingAction.recommendation = false;
    const index = state.global.findIndex(d => d._id === body._id);

    state.global.splice(index, 1);
  },
  REMOVE_RECOMMENDATION_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.recommendation = error.body;
    state.loadingAction.recommendation = false;

    if (error.body.formMessages) state.validationErrors = error.body.formMessages;
  },
  GET_RECOMMENDATIONS_GLOBAL_REQUEST: state => {
    state.loading = true;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = true;
  },
  GET_RECOMMENDATIONS_GLOBAL_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = false;

    state.global = body;
  },
  GET_RECOMMENDATIONS_GLOBAL_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = error.body;
    state.loadingAction.getGlobalRecommendations = false;
  },
  UPDATE_RECOMMENDATIONS_GLOBAL_REQUEST: state => {
    state.loading = true;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = true;
  },
  UPDATE_RECOMMENDATIONS_GLOBAL_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = false;
    if (state.global.find(item => item.id === body._id)) {
      const index = state.global.findIndex(d => d._id === body._id);

      Vue.set(state.global, index, body);
    } else {
      state.global.push(body);
    }
  },
  UPDATE_RECOMMENDATIONS_GLOBAL_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = error.body;
    state.loadingAction.getGlobalRecommendations = false;
  },
  DELETE_RECOMMENDATIONS_GLOBAL_REQUEST: state => {
    state.loading = true;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = true;
  },
  DELETE_RECOMMENDATIONS_GLOBAL_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = false;
    state.loadingAction.getGlobalRecommendations = false;

    const index = state.global.findIndex(d => d._id === body._id);
    state.global.splice(index, 1);
  },
  DELETE_RECOMMENDATIONS_GLOBAL_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.getGlobalRecommendations = error.body;
    state.loadingAction.getGlobalRecommendations = false;
  }
};
