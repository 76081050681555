import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'entity',
    plural: 'entities'
  }),
  listStructure: async ({ commit }) => {
    try {
      commit('LIST_STRUCTURE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/entities/structure`);
      commit('LIST_STRUCTURE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_STRUCTURE_ERROR', e);
      return e;
    }
  },
  structure: async ({ commit }, { id }) => {
    try {
      commit('GET_STRUCTURE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/entities/${id}/structure`);
      commit('GET_STRUCTURE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_STRUCTURE_ERROR', e);
      console.log(e);
      return e;
    }
  },
  ultimateParent: async ({ commit }, { id }) => {
    try {
      commit('GET_ULTIMATE_PARENT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/entities/${id}/ultimate-parent`);
      commit('GET_ULTIMATE_PARENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ULTIMATE_PARENT_ERROR', e);
      console.log(e);
      return e;
    }
  },
  uploadCsv: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_CSV_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/entities/import`, data, {
        progress(e) {
          commit('UPLOAD_CSV_PROGRESS', (e.loaded / e.total) * 100);
        }
      });
      commit('UPLOAD_CSV_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ERROR', e);
      return e;
    }
  },
  search: async ({ commit }, { query, params }) => {
    const qs = [];
    qs.push(`query=${query}`);

    Object.keys(params || {}).forEach(option => {
      qs.push(`${option}=${params[option]}`);
    });
    try {
      commit('SEARCH_ENTITIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/entities/search?${qs.join('&')}`);
      commit('SEARCH_ENTITIES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEARCH_ENTITIES_ERROR', e);
      return e;
    }
  }
};
