import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'scraperRun',
    plural: 'scraperRuns'
  }),
  GET_LOG_DOWNLOAD_URL_REQUEST: state => {
    state.loadingAction.getLogDownloadUrl = true;
    state.errorAction.getLogDownloadUrl = false;
  },
  GET_LOG_DOWNLOAD_URL_SUCCESS: state => {
    state.loadingAction.getLogDownloadUrl = false;
    state.errorAction.getLogDownloadUrl = false;
  },
  GET_LOG_DOWNLOAD_URL_ERROR: (state, error) => {
    state.loadingAction.getLogDownloadUrl = false;
    state.errorAction.getLogDownloadUrl = error;
  },
  POLL_UPDATE: (state, body) => {
    state.scraperRuns = body;
  },
  SET_INTERVAL: (state, body) => {
    state.interval = body;
  },
  LIST_SCRAPERRUN_REQUEST: state => {
    state.loadingAction.list = true;
  },
  LIST_SCRAPERRUN_SUCCESS: (state, body) => {
    state.scraperRuns = body;
    state.loadingAction.list = false;
  }
};
