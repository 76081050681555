export default [
  {
    path: '/analytics',
    component: () => import('./AnalyticsBase'),
    children: [
      {
        path: '',
        redirect: { name: 'analytics-emissions' }
      },
      {
        name: 'analytics-emissions',
        path: 'emissions',
        component: () => import('./AnalyticsEmissions'),
        meta: { permissions: ['asset_user'] }
      },
      {
        name: 'analytics-consumption',
        path: 'consumption',
        component: () => import('./AnalyticsConsumption'),
        meta: { permissions: ['asset_user'] }
      },
      {
        name: 'analytics-costs',
        path: 'costs',
        component: () => import('./AnalyticsCosts'),
        meta: { permissions: ['asset_user'] }
      }
    ]
  }
];
