<template>
  <div>
    <MachineView />
  </div>
</template>

<script>
import MachineView from './Machine/MachineView';

export default {
  name: 'MachineWrapper',
  components: {
    MachineView
  }
};
</script>
