import Vue from 'vue';

const registerInterceptors = (token, authService, isKiosk = false, isMachine = false) => {
  Vue.http.interceptors.push((request, next) => {
    try {
      if (isKiosk) {
        request.headers.set('X-Kiosk-Key', token);

        next(res => {
          if (res.status === 401) {
            console.log('Kiosk 401', res);
            authService.machineUnauthorised();
          }
        });

        return null;
      } else if (isMachine) {
        if (request.url.includes(process.env.VUE_APP_ANALYTICS_URL)) {
          request.headers.set('Authorization', `xkey ${token}`);
        } else {
          request.headers.set('X-Key', token);
        }

        next(res => {
          if (res.status === 401) {
            console.log('Machine 401', res);
            authService.machineUnauthorised();
          }
        });
      } else {
        if (!request.url.match(/s3\.[a-z0-9-]*\.amazonaws\.com/)) request.headers.set('Authorization', `Bearer ${token}`);

        next(res => {
          if (res.status === 401) authService.logout();
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export default registerInterceptors;
