import kioskGuard from '../../auth/kioskGuard';

export default [
  {
    path: ':id',
    name: 'kiosk-view',
    beforeEnter: kioskGuard(),
    component: () => import('./KioskView')
  }
];
