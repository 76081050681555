import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    total: 0,
    validationErrors: {},
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      uploadCsv: false,
      stampPreview: false,
      upload: false,
      downloadInvoice: false,
      validate: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      uploadCsv: false,
      stampPreview: false,
      upload: false
    },
    schema: [],
    invoice: {},
    invoices: []
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    schema: state => state.schema,
    invoice: state => state.invoice,
    invoices: state => state.invoices,
    total: state => state.total,
    validationErrors: state => state.validationErrors
  },
  mutations: {
    SET_VALIDATION_ERRORS: (state, errors) => {
      state.validationErrors = errors;
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
