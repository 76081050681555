export default [
  {
    path: '/imports',
    component: () => import('./ImportView'),
    meta: { permissions: ['company_user'] },
    children: [
      {
        path: '',
        name: 'import-list',
        component: () => import('./ImportList')
      },
      {
        path: ':id/detail',
        name: 'import-detail',
        component: () => import('./ImportDetail')
      },
      {
        path: 'wizard',
        name: 'import-wizard',
        component: () => import('./ImportWizard'),
        children: [
          {
            path: 'upload',
            name: 'import-upload',
            component: () => import('./ImportUpload')
          },
          {
            path: ':id/mapping',
            name: 'import-mapping',
            component: () => import('./ImportMapping')
          },
          {
            path: ':id/preview',
            name: 'import-preview',
            component: () => import('./ImportPreview')
          },
          {
            path: ':id/import',
            name: 'import-import',
            component: () => import('./ImportImport')
          }
        ]
      }
    ]
  }
];
