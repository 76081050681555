import actions from './actions';
import mutations from './mutations';

const initialState = () => ({
  loadingAction: {
    ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  },
  errorAction: {
    ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  },
  error: {},
  schema: [],
  consumption: [],
  consumptions: [],
  total: 0
});

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    schema: state => state.schema,
    error: state => state.error,
    consumption: state => state.consumption,
    consumptions: state => state.consumptions,
    total: state => state.total
  },
  mutations: {
    ...mutations,
    RESET: state => {
      Object.assign(state, initialState());
    }
  },
  actions: {
    ...actions
  }
};

export default store;
