import moment from 'moment';
import Vue from 'vue';

import helpers from './helpers';

export default {
  getAssets: async ({ commit }, { data } = { data: {} }) => {
    try {
      commit('GET_ASSETS_REQUEST', { data });

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets`, data);
      commit('GET_ASSETS_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_ASSETS_ERROR', e);
      return e;
    }
  },
  getAssetGroups: async ({ commit }, { data } = { data: {} }) => {
    try {
      commit('GET_ASSET_GROUPS_REQUEST', { data });

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups`, data);
      commit('GET_ASSET_GROUPS_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_ASSET_GROUPS_ERROR', e);
      return e;
    }
  },
  getAccountTypes: async ({
    commit,
    state: {
      options: { selectedId: id, type }
    }
  }) => {
    try {
      commit('GET_ACCOUNT_TYPES_REQUEST');
      const collection = type === 'asset' ? 'assets' : 'asset-groups';
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/${collection}/${id}/account-types`);
      commit('GET_ACCOUNT_TYPES_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_ACCOUNT_TYPES_ERROR', { e });
      return e;
    }
  },
  getAnalytics: async ({ commit }, { id, params }) => {
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_ANALYTICS_URL}`, {
        params,
        before: request => {
          commit('GET_ANALYTICS_REQUEST', { id, params, request });
        }
      });
      commit('GET_ANALYTICS_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('GET_ANALYTICS_ERROR', { id, e });
      return e;
    }
  },
  getAnalyticsV2: async ({ commit }, { id, params }) => {
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_ANALYTICS_URL}/v2`, {
        params,
        timeout: 600000,
        before: request => {
          commit('GET_ANALYTICS_REQUEST', { id, params, request });
        }
      });
      commit('GET_ANALYTICS_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('GET_ANALYTICS_ERROR', { id, e });
      return e;
    }
  },
  refreshGraphs: async ({ commit, dispatch, state }) => {
    try {
      console.log('Refreshing graphs...');

      commit('REFRESH_GRAPHS_START');

      // Abort any existing requests
      Object.entries(state.requests.getAnalytics).forEach(([, req]) => {
        if (req?.abort && typeof req?.abort === 'function') {
          req.abort();
        }
      });

      if (state.options.page === 'emissions') {
        dispatch('getEmissions');
        dispatch('getAssetEpcRatings');
        dispatch('getRenewablePercentage');
      } else if (state.options.page === 'consumption') {
        dispatch('getAssetDetailedConsumption');
        dispatch('getAssetConsumption');
      } else if (state.options.page === 'costs') {
        dispatch('getAssetCost');
      }

      commit('REFRESH_GRAPHS_END');
      return true;
    } catch (e) {
      commit('REFRESH_GRAPHS_ERROR', e);
      return e;
    }
  },
  getAssetDetailedConsumption: async ({
    dispatch,
    state: {
      options: { selectedId, dateRange, granularity, source, type, comparePeriod },
      accountTypes
    }
  }) => {
    const [startDate, endDate] = dateRange;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      dataType: 'asset',
      granularity,
      source,
      detailedSource: true
    };

    if (type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = selectedId;
      consumptionSummaryParams.dataType = 'assetGroup';
    } else {
      consumptionSummaryParams.assetId = selectedId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    accountTypes.forEach(accountType => {
      dispatch('getAnalyticsV2', {
        id: `${accountType}-consumption`,
        params: {
          accountType,
          ...consumptionSummaryParams
        }
      });
      dispatch('getAnalyticsV2', {
        id: `${accountType}-consumption-prev`,
        params: {
          accountType,
          ...consumptionSummaryParams,
          startDate: prevStartDate,
          endDate: prevEndDate
        }
      });
    });
  },
  getEmissions: async ({
    dispatch,
    commit,
    state: {
      options: { type: dataType, granularity, source, dateRange, selectedId, comparePeriod },
      accountTypes
    }
  }) => {
    const [startDate, endDate] = dateRange;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      source,
      dataType: dataType === 'asset' ? 'asset' : 'assetGroup',
      granularity,
      emissions: true
    };

    if (dataType === 'portfolio') {
      consumptionSummaryParams.assetGroupId = selectedId;
    } else {
      consumptionSummaryParams.assetId = selectedId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    commit('GET_ANALYTICS_REQUEST', { id: 'emissions-summary' });

    const promises = accountTypes
      .map(accountType => {
        return [
          dispatch('getAnalyticsV2', {
            id: `emissions-summary-${accountType}`,
            params: {
              ...consumptionSummaryParams,
              accountType
            }
          }),
          dispatch('getAnalyticsV2', {
            id: `emissions-summary-prev-${accountType}`,
            params: {
              ...consumptionSummaryParams,
              startDate: prevStartDate,
              endDate: prevEndDate,
              accountType
            }
          })
        ];
      })
      .flat();

    await Promise.all(promises);

    commit('GET_ANALYTICS_SUCCESS', { id: 'emissions-summary' });
  },
  getAssetConsumption: async ({
    dispatch,
    state: {
      options: { granularity, source, accountType, dateRange, type, selectedId, comparePeriod }
    }
  }) => {
    const [startDate, endDate] = dateRange;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      dataType: 'asset',
      granularity,
      source
    };

    if (accountType) consumptionSummaryParams.accountType = accountType;

    if (type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = selectedId;
      consumptionSummaryParams.dataType = 'assetGroup';
    } else {
      consumptionSummaryParams.assetId = selectedId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, comparePeriod);

    const prevStartDate = moment(startDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    dispatch('getAnalyticsV2', {
      id: 'consumption',
      params: {
        detailedSource: true,
        ...consumptionSummaryParams
      }
    });

    dispatch('getAnalyticsV2', {
      id: 'consumption-prev',
      params: {
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
  },
  getAssetCost: async ({
    dispatch,
    state: {
      accountTypes,
      options: { dateRange, type, selectedId, comparePeriod }
    }
  }) => {
    const [startDate, endDate] = dateRange;

    const consumptionSummaryParams = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      sourceData: 'invoice',
      dateBreakdown: 'monthly',
      includeCosts: true
    };

    if (type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = selectedId;
    } else {
      consumptionSummaryParams.assetId = selectedId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    ['electricity', 'gas', 'water', 'waste'].forEach(accountType => {
      if (!accountTypes.includes(accountType)) return;

      dispatch('getAnalytics', {
        id: `${accountType}-consumption`,
        params: {
          id: 'asset-consumption',
          accountType,
          estimateBreakdown: true,
          meterUserBreakdown: true,
          ...consumptionSummaryParams
        }
      });

      dispatch('getAnalytics', {
        id: `${accountType}-consumption-prev`,
        params: {
          id: 'asset-consumption',
          accountType,
          ...consumptionSummaryParams,
          startDate: prevStartDate,
          endDate: prevEndDate
        }
      });
    });
  },

  getAssetEpcRatings: async ({ dispatch, state }) => {
    const params = {
      id: 'epc-ratings'
    };

    if (state.options.type === 'portfolio') {
      params.assetGroupId = state.options.selectedId;
    } else {
      params.assetId = state.options.selectedId;
    }

    dispatch('getAnalytics', {
      id: 'epc-ratings',
      params
    });
  },

  getRenewablePercentage: async ({ dispatch, state }) => {
    const params = {
      id: 'asset-contract-renewable'
    };

    if (state.options.type === 'portfolio') {
      params.assetGroupId = state.options.selectedId;
    } else {
      params.assetId = state.options.selectedId;
    }

    dispatch('getAnalytics', {
      id: 'asset-contract-renewable',
      params
    });
  }
};
