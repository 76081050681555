import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'user',
    plural: 'users'
  }),
  GET_DASHBOARD_DATA_REQUEST: (state, dataType) => {
    if (dataType) state.loadingAction.getDashboardData[dataType] = true;
  },
  GET_DASHBOARD_DATA_SUCCESS: (state, { body, dataType }) => {
    if (dataType) {
      state.loadingAction.getDashboardData[dataType] = false;
      if (dataType === 'invoiceChart') {
        state.dashboardData.confirmedInvoicesByDay = body.confirmedInvoicesByDay;
        return (state.dashboardData.unconfirmedInvoicesByDay = body.unconfirmedInvoicesByDay);
      }
      return (state.dashboardData[dataType] = body[dataType]);
    }
    state.dashboardData = body;
  },
  GET_DASHBOARD_DATA_ERROR: state => {
    state.loadingAction.getDashboardData.invoiceChart = false;
    state.loadingAction.getDashboardData.invoicesUploadedByUsers = false;
    state.loadingAction.getDashboardData.numberOfMessagesByUsers = false;
  },

  GET_NOTIFICATIONS_REQUEST: state => {
    state.loadingAction.getNotifications = true;
  },
  GET_NOTIFICATIONS_SUCCESS: (state, body) => {
    state.loadingAction.getNotifications = false;
    state.notifications = body;
  },
  GET_NOTIFICATIONS_ERROR: state => {
    state.loadingAction.getNotifications = false;
  },

  SEEN_NOTIFICATION_REQUEST: state => {
    state.loadingAction.seenNotification = true;
  },
  SEEN_NOTIFICATION_SUCCESS: (state, body) => {
    state.loadingAction.seenNotification = false;

    Vue.set(state, 'notifications', body);
  },
  SEEN_NOTIFICATION_ERROR: state => {
    state.loadingAction.seenNotification = false;
  },

  GET_COMPANY_REQUEST: state => {
    state.loadingAction.getCompany = true;
  },
  GET_COMPANY_SUCCESS: (state, body) => {
    state.loadingAction.getCompany = false;
    state.company = body;
  },
  GET_COMPANY_ERROR: state => {
    state.loadingAction.getCompany = false;
  },

  GET_COMPANIES_REQUEST: state => {
    state.loadingAction.getCompanies = true;
  },
  GET_COMPANIES_SUCCESS: (state, body) => {
    state.loadingAction.getCompanies = false;
    state.companies = body;
  },
  GET_COMPANIES_ERROR: state => {
    state.loadingAction.getCompanies = false;
  },

  GET_PERMISSIONS_REQUEST: state => {
    state.loadingAction.getPermissions = true;
  },
  GET_PERMISSIONS_SUCCESS: (state, body) => {
    state.loadingAction.getPermissions = false;
    state.permissions = body;
  },
  GET_PERMISSIONS_ERROR: state => {
    state.loadingAction.getPermissions = false;
  },

  GET_ASSET_GROUPS_REQUEST: state => {
    state.loadingAction.getAssetGroups = true;
  },
  GET_ASSET_GROUPS_SUCCESS: (state, body) => {
    state.loadingAction.getAssetGroups = false;
    state.assetGroups = body;
  },
  GET_ASSET_GROUPS_ERROR: state => {
    state.loadingAction.getAssetGroups = false;
  },
  GET_ENTITY_SUCCESS: (state, body) => {
    state.entity = body;
  }
};
