export default [
  {
    path: '/entities',
    name: 'entity-list',
    component: () => import('./EntityList'),
    meta: { permissions: ['entity_user'] }
  },
  {
    path: '/entities/import',
    name: 'entity-import',
    component: () => import('./admin/EntityCsvImport'),
    meta: { permissions: ['company_user'] }
  },
  {
    path: '/entities/:id',
    component: () => import('./EntityView'),
    meta: { permissions: ['entity_user'] },
    children: [
      {
        name: 'entity-view',
        path: '',
        redirect: { name: 'entity-structure' }
      },
      {
        name: 'entity-structure',
        path: 'structure',
        component: () => import('./tabs/EntityStructure')
      },
      {
        name: 'entity-edit',
        path: 'edit',
        component: () => import('./tabs/EntityEdit')
      }
    ]
  }
];
