import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    total: 0,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    reports: [],
    report: {
      downloadUrl: ''
    },
    report2: {
      downloadUrl: ''
    },
    validationErrors: {}
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    validationErrors: state => state.validationErrors,
    total: state => state.total,
    reports: state => state.reports,
    report: state => state.report,
    report2: state => state.report2
  },
  mutations: {
    RESET_STATE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
