import Vue from 'vue';

export default {
  listCountries: async ({ commit }) => {
    try {
      commit('LIST_COUNTRIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/countries?$limit=1000&$sort=fullName:1`);
      commit('LIST_COUNTRIES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_COUNTRIES_ERROR', e);
      return e;
    }
  },
  listAssetTypes: async ({ commit }) => {
    try {
      commit('LIST_ASSET_TYPES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/asset-types`);
      commit('LIST_ASSET_TYPES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_ASSET_TYPES_ERROR', e);
      return e;
    }
  },
  listIntegrations: async ({ commit }) => {
    try {
      commit('LIST_INTEGRATIONS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/integrations`);
      commit('LIST_INTEGRATIONS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_INTEGRATIONS_ERROR', e);
      return e;
    }
  },
  listGranularities: async ({ commit }) => {
    try {
      commit('LIST_GRANULARITIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/granularities`);
      commit('LIST_GRANULARITIES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_GRANULARITIES_ERROR', e);
      return e;
    }
  },
  listUtilityTypes: async ({ commit }) => {
    try {
      commit('LIST_UTILITY_TYPES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/utility-types`);
      commit('LIST_UTILITY_TYPES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_UTILITY_TYPES_ERROR', e);
      return e;
    }
  },
  listUnits: async ({ commit }) => {
    try {
      commit('LIST_UNITS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/units`);
      commit('LIST_UNITS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_UNITS_ERROR', e);
      return e;
    }
  },
  listEnergySuppliers: async ({ commit }) => {
    try {
      commit('LIST_ENERGY_SUPPLIERS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/energy-suppliers`);
      commit('LIST_ENERGY_SUPPLIERS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('LIST_ENERGY_SUPPLIERS_ERROR', e);
      return e;
    }
  },
  search: async ({ commit }, { query }) => {
    try {
      commit('SEARCH_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/util/search?query=${encodeURIComponent(query)}`);
      commit('SEARCH_SUCCESS', body.results);
      return body.results;
    } catch (e) {
      commit('SEARCH_ERROR', e);
      return e;
    }
  }
};
