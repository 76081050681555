import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'recommendation',
    plural: 'recommendations'
  }),
  getRecommendationList: async ({ commit }, { id }) => {
    try {
      commit('GET_RECOMMENDATIONS_GLOBAL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/recommendations/global/${id}`);
      commit('GET_RECOMMENDATIONS_GLOBAL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_RECOMMENDATIONS_GLOBAL_ERROR', {
        type: 'get',
        message: 'There was a problem with getting recommendations.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      console.log(e);
      return e;
    }
  },
  updateGlobalRecommendation: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_RECOMMENDATIONS_GLOBAL_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/recommendations/global/${id}`, data);
      commit('UPDATE_RECOMMENDATIONS_GLOBAL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_RECOMMENDATIONS_GLOBAL_ERROR', {
        type: 'update',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      console.log(e);
      return e;
    }
  },
  removeGlobalRecommendation: async ({ commit }, { id, data }) => {
    try {
      commit('DELETE_RECOMMENDATIONS_GLOBAL_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/recommendations/global/${id}`, data);
      commit('DELETE_RECOMMENDATIONS_GLOBAL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_RECOMMENDATIONS_GLOBAL_ERROR', {
        type: 'delete',
        message: 'There was a problem with deleting recommendations.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      console.log(e);
      return e;
    }
  }
};
