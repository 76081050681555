import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'invoice',
    plural: 'invoices'
  }),
  UPLOAD_CSV_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadCsv = true;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_PROGRESS: (state, progress) => {
    state.loadingAction.uploadCsv = progress;
  },
  UPLOAD_CSV_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = error;
  },
  UPLOAD_CSV_ROLLBACK_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadCsvRollback = true;
    state.errorAction.uploadCsvRollback = false;
  },
  UPLOAD_CSV_ROLLBACK_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadCsvRollback = false;
    state.errorAction.uploadCsvRollback = false;
  },
  UPLOAD_CSV_ROLLBACK_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadCsvRollback = false;
    state.errorAction.uploadCsvRollback = error;
  },
  ADD_INVOICE_RATE_REQUEST: state => {
    state.loading = true;
  },
  CONFIRM_INVOICE_SUCCESS: state => {
    state.loading = false;
  },
  STAMP_PREVIEW_REQUEST: state => {
    state.loadingAction.stampPreview = true;
  },
  STAMP_PREVIEW_SUCCESS: state => {
    state.loadingAction.stampPreview = false;
  },
  STAMP_PREVIEW_ERROR: state => {
    state.loadingAction.stampPreview = false;
  },
  DOWNLOAD_INVOICE_REQUEST: (state, id) => {
    state.loadingAction.downloadInvoice = id;
  },
  DOWNLOAD_INVOICE_SUCCESS: state => {
    state.loadingAction.downloadInvoice = false;
  },
  DOWNLOAD_INVOICE_ERROR: state => {
    state.loadingAction.downloadInvoice = false;
  },
  UPDATE_VALIDATION_REQUEST: state => {
    state.loadingAction.validate = true;
  },
  UPDATE_VALIDATION_SUCCESS: state => {
    state.loadingAction.validate = false;
  }
};
