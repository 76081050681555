import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'company',
    plural: 'companies'
  }),

  GET_GLOBAL_COMPANIES_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getGlobalCompanies = true;
    state.errorAction.getGlobalCompanies = false;
  },
  GET_GLOBAL_COMPANIES_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getGlobalCompanies = false;
    state.errorAction.getGlobalCompanies = false;

    state.globalCompanies = body;
  },
  GET_GLOBAL_COMPANIES_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getGlobalCompanies = false;
    state.errorAction.getGlobalCompanies = error;
  },

  LIST_USERS_REQUEST: state => {
    state.loading = true;
    state.loadingAction.listUsers = true;
    state.errorAction.listUsers = false;
  },
  LIST_USERS_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.listUsers = false;
    state.errorAction.listUsers = false;

    state.users = body.data;
    state.usersTotal = body.total;
  },
  LIST_USERS_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.listUsers = false;
    state.errorAction.listUsers = error;
  },

  GET_USER_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getUser = true;
    state.errorAction.getUser = false;
  },
  GET_USER_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.getUser = false;
    state.errorAction.getUser = false;
  },
  GET_USER_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getUser = false;
    state.errorAction.getUser = error;
  },

  CREATE_USER_REQUEST: state => {
    state.loading = true;
    state.loadingAction.createUser = true;
    state.errorAction.createUser = false;
  },
  CREATE_USER_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.createUser = false;
    state.errorAction.createUser = false;
  },
  CREATE_USER_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.createUser = false;
    state.errorAction.createUser = error;
  },

  DELETE_USER_REQUEST: state => {
    state.loading = true;
    state.loadingAction.deleteUser = true;
    state.errorAction.deleteUser = false;
  },
  DELETE_USER_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.deleteUser = false;
    state.errorAction.deleteUser = false;
  },
  DELETE_USER_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.deleteUser = false;
    state.errorAction.deleteUser = error;
  },

  UPDATE_USER_REQUEST: state => {
    state.loading = true;
    state.loadingAction.updateUser = true;
    state.errorAction.updateUser = false;
  },
  UPDATE_USER_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.updateUser = false;
    state.errorAction.updateUser = false;
  },
  UPDATE_USER_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.updateUser = false;
    state.errorAction.updateUser = error;
  },

  GET_ROLES_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getRoles = true;
    state.errorAction.getRoles = false;
  },
  GET_ROLES_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadingAction.getRoles = false;
    state.errorAction.getRoles = false;

    state.roles = data;
  },
  GET_ROLES_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getRoles = false;
    state.errorAction.getRoles = error;
  },

  UPLOAD_LOGO_REQUEST: state => {
    state.loading = true;
    state.loadingAction.uploadLogo = true;
    state.errorAction.uploadLogo = false;
  },
  UPLOAD_LOGO_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadingAction.uploadLogo = false;
    state.errorAction.uploadLogo = false;

    state.logoUpload.uploaded = true;
    state.logoUpload.previewUrl = data.previewUrl;
  },
  UPLOAD_LOGO_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.uploadLogo = false;
    state.errorAction.uploadLogo = error;
    state.logoUpload.uploaded = false;

    if (error.message === 'Form validation error') {
      state.formErrors = error.errors;
    }
  },
  UPLOAD_LOGO_PROGRESS: (state, progress) => {
    state.logoUpload.progress = progress;
  },
  GET_DELETED_LOGS_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getDeletedLogs = true;
    state.errorAction.getDeletedLogs = false;
  },
  GET_DELETED_LOGS_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getDeletedLogs = false;
    state.errorAction.getDeletedLogs = false;

    state.logs = body;
  },
  GET_DELETED_LOGS_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getDeletedLogs = false;
    state.errorAction.getDeletedLogs = error;
  },
  ROLLBACK_DELETE_REQUEST: state => {
    state.loading = true;
    state.loadingAction.rollbackDelete = true;
    state.errorAction.rollbackDelete = false;
  },
  ROLLBACK_DELETE_SUCCESS: state => {
    state.loading = false;
    state.loadingAction.rollbackDelete = false;
    state.errorAction.rollbackDelete = false;
  },
  ROLLBACK_DELETE_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.rollbackDelete = false;
    state.errorAction.rollbackDelete = error;
  },
  SUBMIT_CREATE_COMPANY_REQUEST: state => {
    state.loadingAction.create = true;
    state.errorAction.create = {};
    state.validationErrors = {};
  },
  SUBMIT_CREATE_COMPANY_SUCCESS: (state, body) => {
    state.loadingAction.create = false;
    state.errorAction.create = {};
    state.validationErrors = {};
    state.company = body;
  },
  SET_VALIDATION_ERRORS: (state, errors) => {
    state.loadingAction.create = false;
    state.validationErrors = errors;
  },
  SET_ERROR: (state, error) => {
    state.errorAction[error.type] = error;
    Object.keys(state.loadingAction).forEach(key => {
      state[key] = false;
    });
  },

  DELETE_SUPPLIER_PORTAL_REQUEST: state => {
    state.loading = true;
    state.loadingAction.deleteSupplierPortal = true;
    state.errorAction.deleteSupplierPortal = false;
  },
  DELETE_SUPPLIER_PORTAL_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.deleteSupplierPortal = false;
    state.errorAction.deleteSupplierPortal = false;

    state.company.settings.supplierPortals = body;
  },
  DELETE_SUPPLIER_PORTAL_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.deleteSupplierPortal = false;
    state.errorAction.deleteSupplierPortal = error;
  },

  UPDATE_SUPPLIER_PORTAL_REQUEST: state => {
    state.loading = true;
    state.loadingAction.updateSupplierPortal = true;
    state.errorAction.updateSupplierPortal = false;
  },
  UPDATE_SUPPLIER_PORTAL_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.updateSupplierPortal = false;
    state.errorAction.updateSupplierPortal = false;

    state.company.settings.supplierPortals = body;
  },
  UPDATE_SUPPLIER_PORTAL_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.updateSupplierPortal = false;
    state.errorAction.updateSupplierPortal = error;
  }
};
