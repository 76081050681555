var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type === 'gas' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-fire-flame-simple ").concat(_vm.noColor ? '' : 'text-danger', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Gas")]) : _vm._e()]) : _vm.type === 'water' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-faucet ").concat(_vm.noColor ? '' : 'text-info', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Water")]) : _vm._e()]) : _vm.type === 'waste' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-dumpster ").concat(_vm.noColor ? '' : 'text-primary', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Waste")]) : _vm._e()]) : _vm.type === 'solar' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-solar-panel ").concat(_vm.noColor ? '' : 'text-success', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Solar PV")]) : _vm._e()]) : _vm.type === 'electricity' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-plug ").concat(_vm.noColor ? '' : 'text-warning', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Electricity")]) : _vm._e()]) : _vm.type === 'heating' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-heat ").concat(_vm.noColor ? '' : 'text-warning', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Heating")]) : _vm._e()]) : _vm.type === 'cooling' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-fan ").concat(_vm.noColor ? '' : 'text-info', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Cooling")]) : _vm._e()]) : _vm.type === 'flow' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-pipe-valve ").concat(_vm.noColor ? '' : 'text-muted', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Flow")]) : _vm._e()]) : _vm.type === 'other' ? _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-meter ").concat(_vm.noColor ? '' : 'text-muted', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v("Other")]) : _vm._e()]) : _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('i', {
    class: "fa mr-2 ".concat(_vm.noFw ? '' : 'fa-fw', " fa-box ").concat(_vm.noColor ? '' : 'text-muted', " ").concat(_vm.large ? 'fa-lg' : '', " ").concat(_vm.iconClass)
  }), _vm.text ? _c('span', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.type)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }