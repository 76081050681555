import Vue from 'vue';

export default {
  check: async ({ commit }, { data }) => {
    try {
      commit('CHECK_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/wizard-check`, data);
      commit('CHECK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'submit',
        message: 'There was a problem with some details entered below.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body?.formMessages);
      return e;
    }
  },
  submit: async ({ commit }, data) => {
    try {
      commit('SUBMIT_WIZARD_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/assets/wizard-submit`, data);
      commit('SUBMIT_WIZARD_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'submit',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body?.formMessages);

      return e;
    }
  }
};
