import Vue from 'vue';

import { crudActions, fileActions } from './commonActions';
import * as Sentry from '@sentry/browser';

const hyphenCaseToCamelCase = str => {
  return str.replace(/-([a-z])/g, g => {
    return g[1].toUpperCase();
  });
};

const hyphenCaseToTitleCase = str => {
  const body = hyphenCaseToCamelCase(str);

  const head = str.charAt(0).toUpperCase();

  return `${head}${body.slice(1)}`;
};

// resource: { singular, plural, url }
// subResource: { singular, plural, url }

export default (resource, subResource = {}, options = { fileActions: false }) => {
  const storeActions = {};

  const actions = { ...crudActions, ...(options.fileActions ? fileActions : {}) };

  Object.entries(actions).forEach(([action, props]) => {
    const { method, url: actionUrl, httpOptions = {} } = props;

    const actionPlurality = action.startsWith('list') ? 'plural' : 'singular';
    const mutationPrefix = `${action.toUpperCase()}_${(subResource.singular || resource.singular).replace(/-/g, '_').toUpperCase()}_`;

    const func = async ({ commit }, params, skipState = false) => {
      const { id, data } = params || {};
      const subId = subResource.singular ? params[`${hyphenCaseToCamelCase(subResource.singular)}Id`] : null;

      let url = actionUrl.replace(':resource', resource.url || resource.plural);

      if (subResource.singular) {
        url = url.replace(':subResource', subResource.url || subResource.plural).replace(':subId', subId);
      } else {
        url = url.replace('/:subResource', '').replace('/:subId', '');
        if (!actionUrl.includes(':subId')) url = url.replace('/:id', '');
      }

      if (url.includes(':id')) {
        url = url.replace(':id', `${id}`);
      }

      commit(`${mutationPrefix}REQUEST`, params);

      if (action === 'upload') {
        httpOptions.progress = e => {
          commit(`${mutationPrefix}PROGRESS`, (e.loaded / e.total) * 100);
        };
      }

      try {
        const { body } = await Vue.http[method](`${process.env.VUE_APP_API_URL}${url}`, data, httpOptions);

        commit(`${mutationPrefix}SUCCESS`, body, skipState);
        return body;
      } catch (e) {
        commit(`${mutationPrefix}ERROR`, e);
        if (e.body?.formMessages) commit(`SET_VALIDATION_ERRORS`, e.body.formMessages);
        Sentry.captureException(e);
        return null;
      }
    };

    const fullAction = subResource.singular ? `${action}${hyphenCaseToTitleCase(subResource[actionPlurality])}` : action;

    storeActions[fullAction] = func;
  });

  storeActions[subResource.singular ? `clear${hyphenCaseToTitleCase(subResource.singular)}` : 'clear'] = ({ commit } = {}) => {
    commit(`CLEAR_${(subResource.singular || resource.singular).replace(/-/g, '_').toUpperCase()}`);
  };

  return storeActions;
};
