import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'assetGroup',
    plural: 'assetGroups'
  }),
  LIST_ASSETS_REQUEST: state => {
    state.loading = true;
    state.errorAction.listAssets = false;
    state.loadingAction.listAssets = true;
  },
  LIST_ASSETS_SUCCESS: (state, body) => {
    state.loading = false;
    state.errorAction.listAssets = false;
    state.loadingAction.listAssets = false;

    state.assets = body;
  },
  LIST_ASSETS_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.listAssets = error.body;
    state.loadingAction.listAssets = false;
  },

  ADD_ASSET_REQUEST: state => {
    state.loading = true;
    state.errorAction.addAsset = false;
    state.loadingAction.addAsset = true;
  },
  ADD_ASSET_SUCCESS: state => {
    state.loading = false;
    state.errorAction.addAsset = false;
    state.loadingAction.addAsset = false;
  },
  ADD_ASSET_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.addAsset = error.body;
    state.loadingAction.addAsset = false;
  },

  REMOVE_ASSET_REQUEST: (state, id) => {
    state.loading = true;
    state.errorAction.removeAsset = false;
    state.loadingAction.removeAsset = id;
  },
  REMOVE_ASSET_SUCCESS: state => {
    state.loading = false;
    state.errorAction.removeAsset = false;
    state.loadingAction.removeAsset = false;
  },
  REMOVE_ASSET_ERROR: (state, error) => {
    state.loading = false;
    state.errorAction.removeAsset = error.body;
    state.loadingAction.removeAsset = false;
  },

  GET_ANALYTICS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getAnalytics = true;
    state.errorAction.getAnalytics = false;
  },
  GET_ANALYTICS_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getAnalytics = false;
    state.errorAction.getAnalytics = false;
  },
  GET_ANALYTICS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getAnalytics = false;
    state.errorAction.getAnalytics = error;
  },

  GET_REPORT_DATA_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getReportData = true;
    state.errorAction.getReportData = false;
  },
  GET_REPORT_DATA_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getReportData = false;
    state.errorAction.getReportData = false;

    state.reportData = body;
  },
  GET_REPORT_DATA_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getReportData = false;
    state.errorAction.getReportData = error;
  },
  SEARCH_ASSETS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.searchAssets = true;
    state.errorAction.searchAssets = false;
  },
  SEARCH_ASSETS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.searchAssets = false;
    state.errorAction.searchAssets = false;
    state.searchAssetResults = body;
  },
  SEARCH_ASSETS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.searchAssets = false;
    state.errorAction.searchAssets = error;
  },
  RESET_SEARCH_ASSETS_STATE: state => {
    Object.assign(state.searchAssetResults, []);
  },
  GET_FLOOR_AREA_REQUEST: state => {
    state.loadingAction.getFloorArea = true;
    state.errorAction.getFloorArea = false;
  },
  GET_FLOOR_AREA_SUCCESS: (state, body) => {
    state.loadingAction.getFloorArea = false;
    state.errorAction.getFloorArea = false;
    state.floorArea = body;
  },
  GET_FLOOR_AREA_ERROR: (state, error) => {
    state.loadingAction.getFloorArea = false;
    state.errorAction.getFloorArea = error;
  }
};
