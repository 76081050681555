import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'company',
    plural: 'companies'
  }),
  getGlobalCompanies: async ({ commit }) => {
    try {
      commit('GET_GLOBAL_COMPANIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/companies/global`);
      commit('GET_GLOBAL_COMPANIES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_GLOBAL_COMPANIES_ERROR', e);
      return e;
    }
  },
  listUsers: async ({ commit }, { id, data }) => {
    try {
      commit('LIST_USERS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/companies/${id}/users`, data);
      commit('LIST_USERS_SUCCESS', body);
      return body.data;
    } catch (e) {
      commit('LIST_USERS_ERROR', e);
      return e;
    }
  },
  createUser: async ({ commit }, { id, data }) => {
    try {
      commit('CREATE_USER_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/companies/${id}/users`, data);
      commit('CREATE_USER_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CREATE_USER_ERROR', e.body.message || e.body.error.message || e.message);
      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  getUser: async ({ commit }, { id, userSub }) => {
    try {
      commit('GET_USER_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/companies/${id}/users/${userSub}`);
      commit('GET_USER_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_USER_ERROR', e.body.message || e.message);
      return e;
    }
  },
  deleteUser: async ({ commit }, { id, userSub }) => {
    try {
      commit('DELETE_USER_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/companies/${id}/users/${userSub}`);
      commit('DELETE_USER_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_USER_ERROR', e.body.message || e.message);
      return e;
    }
  },
  updateUser: async ({ commit }, { id, userSub, data }) => {
    try {
      commit('UPDATE_USER_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/companies/${id}/users/${userSub}`, data);
      commit('UPDATE_USER_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_USER_ERROR', e.body.message || e.message);
      return e;
    }
  },
  getRoles: async ({ commit }) => {
    try {
      commit('GET_ROLES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/companies/roles`);
      commit('GET_ROLES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ROLES_ERROR', e);
      return e;
    }
  },
  uploadLogo: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_LOGO_REQUEST');
      commit('UPLOAD_LOGO_PROGRESS', 0);

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/companies/upload-logo`, data, {
        progress(e) {
          commit('UPLOAD_LOGO_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_LOGO_SUCCESS', body);
      commit('UPLOAD_LOGO_PROGRESS', 100);

      return body;
    } catch (e) {
      console.log('e', e);
      commit('UPLOAD_LOGO_ERROR', e.body || e);
      return e;
    }
  },
  sendWelcomeEmail: async ({ commit }, { id, userSub }) => {
    try {
      commit('SEND_USER_WELCOME_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/companies/${id}/users/${userSub}/welcome-email`);
      commit('SEND_USER_WELCOME_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEND_USER_WELCOME_ERROR', e.body.message || e.message);
      return e;
    }
  },
  createCompany: async ({ commit }, { data }) => {
    try {
      commit('SUBMIT_CREATE_COMPANY_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/companies`, data);
      commit('SUBMIT_CREATE_COMPANY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'create',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  getDeletedLogs: async ({ commit }, { data }) => {
    try {
      const qs = [];
      Object.keys(data.params || []).forEach(key => {
        if (data.params[key] !== undefined) {
          qs.push(`${key}=${data.params[key]}`);
        }
      });

      commit('GET_DELETED_LOGS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/logs?type=delete,restore&$multi=true&${qs.join('&')}`);
      commit('GET_DELETED_LOGS_SUCCESS', body);
      return body.data;
    } catch (e) {
      commit('GET_DELETED_LOGS_ERROR', e);
      return e;
    }
  },
  rollbackDelete: async ({ commit }, { type, id, modelId }) => {
    try {
      commit('ROLLBACK_DELETE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/logs/${id}/delete-rollback`, { modelId, type });
      commit('ROLLBACK_DELETE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('ROLLBACK_DELETE_ERROR', e);
      return e;
    }
  },
  updateSupplierPortal: async ({ commit }, { id, supplierId, data }) => {
    try {
      commit('UPDATE_SUPPLIER_PORTAL_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/companies/${id}/supplier-portals/${supplierId}`, data);
      commit('UPDATE_SUPPLIER_PORTAL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_SUPPLIER_PORTAL_ERROR', e.body.message || e.message);
      return e;
    }
  },
  deleteSupplierPortal: async ({ commit }, { id, supplierId }) => {
    try {
      commit('DELETE_SUPPLIER_PORTAL_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/companies/${id}/supplier-portals/${supplierId}`);
      commit('DELETE_SUPPLIER_PORTAL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_SUPPLIER_PORTAL_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
