var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    attrs: {
      "id": "page-header"
    }
  }, [_c('div', {
    staticClass: "content-header justify-content-between"
  }, [_c('div', [_c('button', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickMenu($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-bars"
  })]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "data-cy": "global-search"
    },
    on: {
      "click": function click($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$t('SEARCH')))])])]), _vm.$auth.isAuthenticated ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.$auth.company.settings.isDemo ? _c('div', {
    staticClass: "text-warning font-w600 mr-2"
  }, [_c('i', {
    staticClass: "fa-solid fa-triangle-exclamation"
  }), _vm._v(" TESTING ENVIRONMENT ")]) : _vm._e(), _vm.$auth.isAdmin ? _c('div', {
    staticClass: "text-warning font-w600 mr-2"
  }, [_vm._v("ADMIN")]) : _vm._e(), _c('div', {
    staticClass: "dropdown d-inline-block mr-2"
  }, [_c('button', {
    staticClass: "btn btn-link text-white d-flex align-items-center",
    attrs: {
      "id": "page-header-notification-dropdown",
      "type": "button",
      "data-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }
  }, [_c('span', [_c('i', {
    staticClass: "fa fa-bell"
  }), _c('span', {
    staticClass: "badge badge-pill ml-2",
    class: {
      'badge-danger': _vm.notifications.unseenCount > 0,
      'badge-white': _vm.notifications.unseenCount === 0
    }
  }, [_vm._v(_vm._s(_vm.notifications.unseenCount))])])]), _c('div', {
    staticClass: "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0",
    attrs: {
      "aria-labelledby": "page-header-notifications-dropdown",
      "x-placement": "top-end"
    }
  }, [_c('div', {
    staticClass: "bg-primary rounded-top font-w600 text-white text-center p-3"
  }, [_vm._v(_vm._s(_vm.$tc('NOTIFICATION', 2)))]), _c('ul', {
    staticClass: "nav-items my-2"
  }, [_vm._l(_vm.seenNotifications.slice(0, 5), function (notification) {
    return _c('li', {
      key: notification._id
    }, [_c('router-link', {
      staticClass: "media py-2",
      class: {
        'text-dark': !notification.isSeen,
        'text-black-25': notification.isSeen
      },
      attrs: {
        "to": notification.route
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.onClickNotification(notification._id);
        }
      }
    }, [_c('div', {
      staticClass: "mx-3"
    }, [_c('i', {
      staticClass: "fa fa-fw mr-1",
      class: notification.icon
    })]), _c('div', {
      staticClass: "media-body font-size-sm pr-2"
    }, [_c('div', {
      staticClass: "mb-1",
      domProps: {
        "innerHTML": _vm._s(notification.content)
      }
    }), _c('em', {
      class: {
        'text-muted': !notification.isSeen,
        'text-black-25': notification.isSeen
      },
      attrs: {
        "title": _vm._f("date")(notification.createdAt, 'DD/MM/YYYY HH:mm:ss')
      }
    }, [_vm._v(" " + _vm._s(_vm._f("timeago")(notification.createdAt)) + " ")])])])], 1);
  }), _vm.seenNotifications.length === 0 ? _c('li', [_c('div', {
    staticClass: "text-muted mx-3"
  }, [_vm._v(_vm._s(_vm.$t('NO_PENDING_NOTIFICATIONS')))])]) : _vm._e()], 2), _c('div', {
    staticClass: "p-2 border-top"
  }, [_c('router-link', {
    staticClass: "btn btn-alt-success btn-block text-center",
    attrs: {
      "to": {
        name: 'notifications'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-eye mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('VIEW_ALL')) + " ")]), _c('a', {
    staticClass: "btn btn-light btn-block text-center",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickDismissAll($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-check mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('DISMISS_ALL')) + " ")])], 1)])]), _c('div', {
    staticClass: "dropdown d-inline-block"
  }, [_c('button', {
    staticClass: "btn btn-link text-white dropdown-toggle d-flex align-items-center",
    attrs: {
      "id": "page-header-user-dropdown",
      "type": "button",
      "data-toggle": "dropdown",
      "aria-haspopup": "true",
      "data-cy": "user-dropdown",
      "aria-expanded": "false"
    }
  }, [_c('span', {
    staticClass: "mr-2 text-right",
    attrs: {
      "id": "page-header-user-details"
    }
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.$auth.user.name))]), _c('div', {
    key: _vm.$auth.companyId,
    staticClass: "font-w400 small"
  }, [_vm._v(_vm._s(_vm.activeCompany))])]), _c('img', {
    staticClass: "img-avatar img-avatar32",
    attrs: {
      "src": _vm.$auth.user.picture,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dropdown-menu dropdown-menu-lg dropdown-menu-right overflow-hidden",
    attrs: {
      "aria-labelledby": "page-header-user-dropdown"
    }
  }, [_c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": "/profile",
      "data-cy": "user-settings"
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-user-circle mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('PROFILE')) + " ")]), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "data-cy": "company-settings",
      "to": {
        name: 'company-view',
        params: {
          id: _vm.$auth.companyId
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-building mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('COMPANY_SETTINGS')) + " ")]) : _vm._e(), _vm.companies.length > 1 ? _c('div', {
    staticClass: "dropdown-divider"
  }) : _vm._e(), _vm.companies.length > 1 ? _c('div', {
    staticClass: "pt-2 pb-3 px-3"
  }, [_vm.switching ? _c('Spinner', {
    attrs: {
      "size": "sm"
    }
  }, [_vm._v("Switching...")]) : _c('div', [_c('label', [_c('i', {
    staticClass: "fa fa-fw fa-arrows-repeat mr-1"
  }), _vm._v("Company Switcher")]), _c('select', {
    staticClass: "form-control form-control-alt",
    domProps: {
      "value": _vm.$auth.companyId
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      },
      "change": _vm.onChangeCompanyId
    }
  }, _vm._l(_vm.companies, function (company) {
    return _c('option', {
      key: company._id,
      domProps: {
        "value": company._id
      }
    }, [_vm._v(_vm._s(company.name))]);
  }), 0)])], 1) : _vm._e(), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "dropdown-item text-gray font-size-sm disabled"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-code-commit mr-1"
  }), _vm._v(" App " + _vm._s(_vm.$t('VERSION')) + ": " + _vm._s(_vm.appVersion.slice(0, 8)) + " ")]), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('a', {
    staticClass: "dropdown-item text-danger",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$auth.logout($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-sign-out-alt ml-1"
  }), _vm._v(" " + _vm._s(_vm.$t('LOG_OUT')) + " ")])], 1)])]) : _vm._e()]), _vm.showSearch ? _c('Search', {
    key: _vm.$route.path,
    on: {
      "close": function close($event) {
        _vm.showSearch = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }