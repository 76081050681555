export default [
  {
    path: '/marketplace',
    component: () => import('./MarketplaceView'),
    meta: { permissions: ['company_user'] },
    children: [
      {
        path: 'carbon',
        name: 'marketplace-carbon-list',
        component: () => import('./carbon/MarketplaceCarbonList')
      },
      {
        path: 'carbon/:id',
        name: 'marketplace-carbon-view',
        component: () => import('./carbon/MarketplaceCarbonView')
      }
    ]
  }
];
