import Vue from 'vue';

export default {
  confirmInvoice: async ({ commit }, { id, data }) => {
    try {
      commit('CONFIRM_INVOICE_REQUEST', { id, data });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/confirm`, data);
      commit('CONFIRM_INVOICE_SUCCESS', { body, id });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'confirmInvoice',
        message: 'There was a problem confirming invoice values.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  recaptureInvoice: async ({ commit }, { id }) => {
    try {
      commit('GET_INVOICE_RECAPTURE_REQUEST', id);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/refresh`);
      commit('GET_INVOICE_RECAPTURE_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoiceRefresh',
        message: 'There was a problem getting invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  getInvoices: async ({ commit }, { fullReset = true, limit = 100, skip = 0, filters = {} }) => {
    try {
      commit('GET_INVOICES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/validation?limit=${limit}&skip=${skip}`, { params: filters });
      commit('GET_INVOICES_SUCCESS', { body, fullReset, limit, skip });

      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoices',
        message: 'There was a problem getting invoices.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  getInvoice: async ({ commit }, { id, params }) => {
    try {
      commit('GET_INVOICE_REQUEST', { id });

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}`, { params });
      commit('GET_INVOICE_SUCCESS', { id, body });

      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoices',
        message: 'There was a problem getting invoices.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  updateInvoice: async ({ commit }, { id, data }) => {
    try {
      commit('UPDATE_INVOICE_REQUEST', id);
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/invoices/${id}`, data);
      commit('UPDATE_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateInvoice',
        message: 'There was a problem updating invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  deleteInvoices: async ({ commit }, { ids, noScrapeIds }) => {
    try {
      commit('DELETE_INVOICES_REQUEST', ids);
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/bulk-delete`, { ids, noScrapeIds });
      commit('DELETE_INVOICES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'deleteInvoices',
        message: 'There was a problem deleting invoices.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  validateInvoice: async ({ commit }, { id, isManual = false }) => {
    try {
      commit('VALIDATE_INVOICE_REQUEST', id);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/validate`);
      commit('VALIDATE_INVOICE_SUCCESS', { id, body, isManual });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        id,
        type: 'validateInvoice',
        message: 'There was a problem validating invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  revalidateInvoice: async ({ commit }, { id }) => {
    try {
      commit('REVALIDATE_INVOICE_REQUEST', id);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/validate`);
      commit('REVALIDATE_INVOICE_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        id,
        type: 'revalidateInvoice',
        message: 'There was a problem re-validating invoice.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  addInvoiceRate: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_INVOICE_RATE_REQUEST', { id });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/rates`, data);
      commit('ADD_INVOICE_RATE_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        id,
        type: 'addInvoiceRate',
        message: 'There was a problem updating invoice rates.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  removeInvoiceRate: async ({ commit }, { id, rateId, index }) => {
    try {
      commit('REMOVE_INVOICE_RATE_REQUEST', { id, index });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/invoices/${id}/rates/${rateId}`);
      commit('REMOVE_INVOICE_RATE_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        id,
        type: 'removeInvoiceRate',
        message: 'There was a problem updating invoice rates.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  getInvoiceKeys: async ({ commit }) => {
    try {
      commit('GET_INVOICE_KEYS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/templates/invoice-keys`);
      commit('GET_INVOICE_KEYS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getInvoiceKeys',
        message: 'There was a problem getting invoice keys.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  setContractRateType: async ({ commit }, { invoiceId, accountId, contractId, data }) => {
    try {
      commit('SET_CONTRACT_RATE_TYPE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${accountId}/contracts/${contractId}/rate-types`, data);
      commit('SET_CONTRACT_RATE_TYPE_SUCCESS', { invoiceId, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'setContractRateType',
        message: 'There was a problem setting contract rate type.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  getRelatedAccounts: async ({ commit }, { invoiceId, supplierId, type, companyId, status }) => {
    try {
      commit('GET_RELATED_ACCOUNTS_REQUEST', { id: invoiceId });
      const { body } = await Vue.http.get(
        `${process.env.VUE_APP_API_URL}/accounts?supplierId=${supplierId}&type=${type}&status=${status}&companyId=${companyId}&$populate=asset`
      );
      commit('GET_RELATED_ACCOUNTS_SUCCESS', { id: invoiceId, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getRelatedAccounts',
        message: 'There was a problem getting related accounts.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  },
  searchAccounts: async ({ commit }, { invoiceId, query, params }) => {
    const qs = [];

    qs.push(`query=${query}`);

    Object.keys(params || {}).forEach(option => {
      qs.push(`${option}=${params[option]}`);
    });

    try {
      commit('SEARCH_ACCOUNTS_REQUEST', { id: invoiceId });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/search?${qs.join('&')}`);
      commit('SEARCH_ACCOUNTS_SUCCESS', { id: invoiceId, body });
      return body;
    } catch (e) {
      commit('SEARCH_ACCOUNTS_ERROR', e);
      return e;
    }
  },
  getStats: async ({ commit }) => {
    try {
      commit('GET_STATS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/validation-stats`);
      commit('GET_STATS_SUCCESS', body);

      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getStats',
        message: 'There was a problem getting stats.',
        data: e.body
      });
      console.log(e);
      return e;
    }
  }
};
