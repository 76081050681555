import authGuard from '../../auth/authGuard';

export default [
  {
    path: '/accounts',
    name: 'account-list',
    component: () => import('./AccountList'),
    beforeEnter: authGuard,
    meta: { permissions: ['account_user'] }
  },
  {
    path: '/accounts/create',
    name: 'account-create',
    component: () => import('./AccountCreate'),
    beforeEnter: authGuard,
    meta: { permissions: ['asset_user'] }
  },
  {
    path: '/accounts/import',
    name: 'account-import',
    component: () => import('./admin/AccountCsvImport'),
    beforeEnter: authGuard,
    meta: { permissions: ['asset_user'] }
  },
  {
    path: '/accounts/:id',
    name: 'account-view',
    component: () => import('./AccountView'),
    beforeEnter: authGuard,
    meta: { permissions: ['account_user'] }
  },
  {
    path: '/accounts/:id/edit',
    name: 'account-edit',
    component: () => import('./AccountEdit'),
    beforeEnter: authGuard,
    meta: { permissions: ['account_user'] }
  }
];
