import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'message',
    plural: 'messages'
  }),
  addReply: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_REPLY_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/messages/${id}/replies`, data);
      commit('ADD_REPLY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'addReply',
        message: 'There was a problem adding a reply.',
        data: e.body
      });
      return e;
    }
  },
  updateReply: async ({ commit }, { id, replyId, data }) => {
    try {
      delete data._id;

      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/messages/${id}/replies/${replyId}`, data);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'updateReply',
        message: 'There was a problem updating a reply.',
        data: e.body
      });
      return e;
    }
  },
  deleteReply: async ({ commit }, { id, replyId }) => {
    try {
      commit('DELETE_REPLY_REQUEST', { id, replyId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/messages/${id}/replies/${replyId}`);
      commit('DELETE_REPLY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'deleteReply',
        message: 'There was a problem deleting a reply.',
        data: e.body
      });
      return e;
    }
  },
  uploadAttachment: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_ATTACHMENT_REQUEST');
      commit('UPLOAD_ATTACHMENT_PROGRESS', 0);

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/messages/upload-attachment`, data, {
        progress(e) {
          commit('UPLOAD_ATTACHMENT_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_ATTACHMENT_SUCCESS', body);
      commit('UPLOAD_ATTACHMENT_PROGRESS', 100);

      return body;
    } catch (e) {
      console.log('e', e);
      commit('UPLOAD_ATTACHMENT_ERROR', e.body || e);
      return e;
    }
  },
  downloadAttachment: async ({ commit }, { id, replyId, attachmentId }) => {
    try {
      commit('DOWNLOAD_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/messages/${id}/replies/${replyId}/attachments/${attachmentId}/download`);
      commit('DOWNLOAD_SUCCESS', body.downloadUrl);
      return body.downloadUrl;
    } catch (e) {
      commit('DOWNLOAD_ERROR', e);

      return e;
    }
  },
  getLinkedAccounts: async ({ commit }, { id, accountIds }) => {
    let accounts = '';

    accountIds.forEach(a => {
      accounts = accounts + a + ',';
    });

    try {
      commit('GET_LINKED_ACCOUNT_REQUEST', { accountIds });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts?_id=${accounts.slice(0, -1)}&$multi=true`);
      commit('GET_LINKED_ACCOUNT_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'getLinkedAccount',
        message: 'There was a problem getting linked account.',
        data: e.body
      });
      return e;
    }
  },
  assignUser: async ({ commit }, { id, userSub }) => {
    try {
      commit('ASSIGN_USER_REQUEST', { id, userSub });
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/messages/${id}/assign-user`, { userSub });
      commit('ASSIGN_USER_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'assignUser',
        message: 'There was a problem assigning a user.',
        data: e.body
      });
      return e;
    }
  }
};
