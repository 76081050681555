import Vue from 'vue';
import * as Sentry from '@sentry/browser';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'invoice',
    plural: 'invoices'
  }),
  download: async ({ commit }, { id, name }) => {
    try {
      commit('DOWNLOAD_INVOICE_REQUEST', id);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/download?name=${name}`);
      commit('DOWNLOAD_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      Sentry.captureException(e);
      commit('DOWNLOAD_INVOICE_ERROR', e);
      return e;
    }
  },
  uploadCsv: async ({ commit }, { data, params }) => {
    const qs = [];

    Object.keys(params).forEach(option => {
      qs.push(`${option}=${params[option]}`);
    });

    try {
      commit('UPLOAD_CSV_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/import?${qs.join('&')}`, data, {
        progress(e) {
          commit('UPLOAD_CSV_PROGRESS', (e.loaded / e.total) * 100);
        }
      });
      commit('UPLOAD_CSV_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ERROR', e);
      return e;
    }
  },
  uploadCsvRollback: async ({ commit }, { batchId }) => {
    try {
      commit('UPLOAD_CSV_ROLLBACK_REQUEST');
      const { body } = await Vue.http.post(
        `${process.env.VUE_APP_API_URL}/invoices/import-rollback`,
        { batchId },
        {
          progress(e) {
            commit('UPLOAD_CSV_ROLLBACK_PROGRESS', (e.loaded / e.total) * 100);
          }
        }
      );
      commit('UPLOAD_CSV_ROLLBACK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ROLLBACK_ERROR', e);
      return e;
    }
  },
  manualUpdate: async (action, { id, data }) => {
    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/invoices/${id}/manual-update`, data);
      return body;
    } catch (e) {
      return e;
    }
  },
  addInvoiceRate: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_INVOICE_RATE_REQUEST', { id });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/rates`, data);
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'addInvoiceRate',
        message: 'There was a problem updating invoice rates.',
        data: e.body
      });
      return e;
    }
  },
  confirmInvoice: async ({ commit }, { id, data }) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/confirm`, data);
      commit('CONFIRM_INVOICE_SUCCESS');
      return body;
    } catch (e) {
      commit('SET_ERROR', {
        type: 'confirmInvoice',
        message: 'There was a problem confirming invoice values.',
        data: e.body
      });
      return e;
    }
  },
  getStampPreview: async ({ commit }, { id, params }) => {
    try {
      commit('STAMP_PREVIEW_REQUEST', { id, params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/invoices/${id}/stamp-preview`, { params });
      commit('STAMP_PREVIEW_SUCCESS', body);
      return body;
    } catch (e) {
      commit('STAMP_PREVIEW_ERROR', e);
      return e;
    }
  },
  stampInvoice: async (action, { id, params }) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/stamp`, { params });
      return body;
    } catch (e) {
      return e;
    }
  },
  manualUpload: async ({ commit }, { data }) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/manual-upload`, data);
      return body;
    } catch (e) {
      commit('SET_VALIDATION_ERRORS', {
        upload: 'There was a problem uploading the invoice.'
      });
      return e;
    }
  },
  convertManualValues: async ({ commit }, { data }) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/convert-values`, data);
      return body;
    } catch (e) {
      commit('SET_VALIDATION_ERRORS', {
        upload: 'There was with converting the invoice values.'
      });
      return e;
    }
  },
  updateValidation: async ({ commit }, { id }) => {
    try {
      commit('UPDATE_VALIDATION_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/invoices/${id}/update-validation`);
      commit('UPDATE_VALIDATION_SUCCESS', { id, body });
      return body;
    } catch (e) {
      return e;
    }
  }
};
