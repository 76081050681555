import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'log',
    plural: 'logs'
  }),
  downloadAttachment: async ({ commit }, { id, data }) => {
    try {
      commit('DOWNLOAD_ATTACHMENT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/logs/${id}/attachment`, data);
      commit('DOWNLOAD_ATTACHMENT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DOWNLOAD_ATTACHMENT_ERROR', e.body.message || e.message);
      return e;
    }
  }
};
