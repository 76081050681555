import Vue from 'vue';

import { getInstance } from './index';
import httpInterceptor from '../lib/httpInterceptors';

export default () => (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    const id = localStorage.getItem('etainablMachineId');
    const key = localStorage.getItem('etainablMKK');

    if (!id || !key) {
      return next({ name: 'machine-error', query: { errorCode: 'INVALID_STORAGE', errordate: { id: !!id, key: !!key } } });
    }

    try {
      await Vue.http.get(`${process.env.VUE_APP_API_URL}/users/me`, {
        headers: {
          'X-Key': key
        }
      });
    } catch (e) {
      return next({ name: 'machine-error', query: { errorCode: 'AUTH_ERROR', errorData: { statusCode: e.status } } });
    }

    authService.loginWithMachine({ key, id });

    httpInterceptor(key, authService, false, true);

    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }

    return null;
  });

  return null;
};
