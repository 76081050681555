export const crudActions = {
  list: {
    method: 'get',
    url: '/:resource/:id/:subResource'
  },
  get: {
    method: 'get',
    url: '/:resource/:id/:subResource/:subId'
  },
  create: {
    method: 'post',
    url: '/:resource/:id/:subResource'
  },
  createBulk: {
    method: 'post',
    url: '/:resource/:id/:subResource/bulk'
  },
  update: {
    method: 'patch',
    url: '/:resource/:id/:subResource/:subId'
  },
  remove: {
    method: 'delete',
    url: '/:resource/:id/:subResource/:subId'
  },
  restore: {
    method: 'put',
    url: '/:resource/:id/:subResource/:subId/restore'
  },
  schema: {
    method: 'get',
    url: '/:resource/schema'
  },
  search: {
    method: 'get',
    url: '/:resource/search'
  }
};

export const fileActions = {
  upload: {
    method: 'post',
    url: '/:resource/:id/:subResource/upload'
  },
  download: {
    method: 'get',
    url: '/:resource/:id/:subResource/:subId/download'
  }
};
