import authGuard from '../../auth/authGuard';

export default [
  {
    path: '/invoices/upload',
    name: 'invoice-upload',
    component: () => import('./InvoiceUploader'),
    beforeEnter: authGuard,
    meta: { permissions: ['company_admin', 'bill_validation'] }
  }
];
