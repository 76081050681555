import moment from 'moment';

const getComparisonParams = (startDate, endDate, period) => {
  if (period === 'period') {
    const difference = moment(endDate).diff(startDate, 'days');
    return [difference, 'days'];
  } else {
    return [1, period];
  }
};

export default {
  getComparisonParams,
  getComparisonDates: (startDate, endDate, periodType) => {
    if (!periodType) {
      return [null, null];
    } else if (periodType === 'period') {
      const params = getComparisonParams(startDate, endDate, periodType);
      return [
        moment(startDate)
          .clone()
          .subtract(...params)
          .format('DD MMM YYYY'),
        moment(endDate)
          .clone()
          .subtract(...params)
          .format('DD MMM YYYY')
      ];
    } else {
      const params = getComparisonParams(startDate, endDate, periodType);
      return [
        moment(startDate)
          .clone()
          .subtract(...params)
          .format('DD MMM YYYY'),
        moment(endDate)
          .clone()
          .subtract(...params)
          .format('DD MMM YYYY')
      ];
    }
  }
};
