<template>
  <p>Redirecting in 3 seconds...</p>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      console.log(this.$auth.loginWithRedirect());
    }, 3000);
  }
};
</script>
