import * as helpers from '../lib/helpers';

export default Vue => {
  Vue.filter('capitalize', helpers.capitalize);
  Vue.filter('numberFormat', helpers.numberFormat);
  Vue.filter('date', helpers.niceDate);
  Vue.filter('timeago', helpers.timeAgo);
  Vue.filter('duration', helpers.duration);
  Vue.filter('round', helpers.round);
  Vue.filter('address', helpers.niceAddress);
  Vue.filter('user', helpers.niceUser);
  Vue.filter('floorAreaUnit', helpers.floorAreaUnit);
  Vue.filter('utilityUnit', helpers.utilityUnit);
  Vue.filter('fileIcon', helpers.fileIcon);
};
