export default [
  {
    path: '/automation',
    name: 'automation',
    redirect: { name: 'automation-list' },
    component: () => import('./AutomationView'),
    meta: { permissions: ['company_admin'] },
    children: [
      {
        name: 'automation-list',
        path: '',
        component: () => import('./tabs/AutomationList')
      },
      {
        name: 'automation-meters-list',
        path: 'meters',
        component: () => import('./tabs/AutomationMetersList')
      },
      {
        name: 'automation-create',
        path: 'create',
        component: () => import('./tabs/AutomationManage')
      },
      {
        name: 'automation-manage',
        path: ':id/edit',
        component: () => import('./tabs/AutomationManage')
      }
    ]
  }
];
