export default {
  // Comments made are purely where/when I found the word
  // Generic
  LOADING_DATA: 'Loading data...',
  LOADING_COMPANY: 'Loading Company...',
  LOADING: 'Loading...',
  CLICK_HERE: 'Click here',
  VIEW_ALL: 'View All',
  // Global
  ENTITY: 'Entity | Entities',
  USER: 'User | Users',
  ASSET: 'Asset | Assets',
  INVOICE: 'Invoice | Invoices',
  LOG_OUT: 'Log Out',
  UPDATING: 'Updating...',
  NAME: 'Name',
  YES: 'Yes',
  NO: 'No',
  // Header
  SEARCH: 'Search',
  DISMISS_ALL: 'Dismiss All',
  NO_PENDING_NOTIFICATIONS: 'No pending notifications',
  PROFILE: 'Profile',
  COMPANY_SETTINGS: 'Company Settings',
  // Navbar
  POWERED_BY: 'Powered By',
  DASHBOARD: 'Dashboard',
  INBOX: 'Inbox',
  NOTIFICATION: 'Notifications | Notifications',
  MANAGEMENT: 'Management',
  SITE: 'Site | Sites',
  PORTFOLIO: 'Portfolio | Portfolios',
  TOOLS: 'Tools',
  ANALYTIC: 'Analytic | Analytics',
  EMISSION: 'Emission | Emissions',
  CONSUMPTION: 'Consumption | Consumptions',
  COST: 'Cost | Costs',
  SUPPLY_CHAIN: 'Supply Chain',
  AUTOMATION: 'Automation',
  IMPORT: 'Import | Imports',
  CONNECTOR: 'Connector | Connectors',
  HH_DATA: 'HH Data',
  CUSTOM: 'Custom',
  COMPANY_ADMIN: 'Company Admin',
  SETTING: 'Setting | Settings',
  ACTIVITY_LOG: 'Activity Log',
  MARKETPLACE: 'Marketplace',
  CARBON_PROJECT: 'Carbon Project | Carbon Projects',
  OFFSET: 'Offset',
  ADMIN: 'Admin',
  OVERVIEW: 'Overview',
  COMPANY: 'Company | Companies',
  CREATE_NEW: 'Create New',
  UPLOADER: 'Uploader',
  SUPPLIER: 'Supplier | Suppliers',
  VERSION: 'Version',
  // Dashboard
  ACCOUNT: 'Account | Accounts',
  RECENT_DISCUSSION: 'Recent Discussion | Recent Discussions',
  NO_RECENT_MESSAGE: 'No recent message | No recent messages',
  TITLE: 'Title',
  MESSAGE: 'Message | Messages',
  POSTED_BY: 'Posted by',
  CONFIRMED_INVOICES_UPLOADED_BY_USERS: 'Confirmed Invoices Uploaded By Users',
  RECENTLY_LOGGED_IN_USERS: 'Recently Logged In Users',
  AMOUNT_OF_MESSAGES_POSTED_BY_USERS: 'Amount of Messages Posted by Users',
  // Company Settings
  UPDATE_COMPANY: 'Update Company',
  COMPANY_UPDATE_SUCCESS: 'Company updated successfully!',
  UNSAVED_CHANGES: 'Unsaved Changes!',
  // Company Form
  COMPANY_INVOICE_CAPTUR_EMAIL: 'Invoice Captur Email',
  COMPANY_INFORMATION: 'Company Information',
  LOGO: 'Logo',
  LOGO_FOMRAT_RULE: 'Must be at least 200px wide and .png format',
  LOGO_SUBMISSION_ERROR: 'Please submit a logo.',
  SUBMIT: 'Submit',
  REPORTING_SETTINGS: 'Reporting Settings',
  CALENDAR_PERIOD: 'Calendar Period',
  HIDE_COST_DATA: 'Hide Cost Data',
  APPLICATION_SETTINGS: 'Application Settings',
  INVOICE_CATEGORY: 'Invoice Category | Invoice Categories',
  INVOICE_CATEGORY_DESCRIPTION: 'Invoice categories are used when tagging invoices in the uploader',
  ADD_CATEGORY_PLACEHOLDER: 'Add a category...',
  MISC_INVOICE_CAT: 'Misc Invoice Categories',
  MISC_INVOICE_CAT_DESCRIPTION: 'Invoice categories that can be used for type on the misc tab in accounts',
  MESSAGE_CATEGORY: 'Message Category | Message Categories',
  MESSAGE_CATEGORY_DESCRIPTION: 'Message categories are used when creating messages within Asset discussion boards',
  DOCUMENT_CATEGORY: 'Document Category | Document Categories',
  DOCUMENT_CATEGORY_DESCRIPTION: 'Document categories are used when creating documents within an Asset',
  // Calender Period Options
  STANDARD: 'Standard',
  UK_FINANCIAL_YEAR: '13 Week Periods (UK Financial Year)',
  RAIL_CALENDAR: 'Rail Calendar',
  // Overview Table
  DATE_RANGE_TEXT: 'Past month', // TODO Moment locale - dateRangeText/reportingPeriods
  FILTER: 'Filter | Filters',
  PREVIOUS: 'Prev',
  NEXT: 'Next',
  LATEST_LOGIN: 'Latest Login',
  NUMBER_OF_MESSAGES: 'Number of Messages',
  NUMBER_OF_INVOICES: 'Number of Invoices',
  // Form Error
  PATH_REQUIRED: 'This field is required',
  // Graph
  LOADING_GRAPH: 'Loading graph...',
  // Chart // TODO dateTimeLabelFormats
  // Profile
  ACCOUNT_NOT_VERIFIED: 'Your account is not verified.',
  EMAIL_VERFICATION: 'to receive a new email that contains the verification link.',
  USER_PROFILE: 'User Profile',
  USER_PROFILE_INFO: 'Your account’s vital info. Your username will be publicly visible.',
  NAME_PLACEHOLDER: 'Enter your name..',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_PASSWORD_TEXT: 'Changing your sign in password is an easy way to keep your account secure.',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  UPDATE_PROFILE_BUTTON: 'Update Profile',
  API_ACCESS: 'API Access',
  API_ACCESS_TEXT: 'Your API key to gain access to our public API. Generating a new key will invalidate the old one.',
  API_ACCESS_WARNING: 'Do not share this API key with anyone outside of your organisation.',
  API_LABEL: 'API Key',
  VIEW_USAGE_BUTTON: 'View Usage',
  GENERATE_NEW_KEY_BUTTON: 'Generate New Key',
  PLAN_INFORMATION: 'Plan Information',
  CONTACT_SUPPORT_TEXT: 'Contact support if you would like to increase any of the below.',
  PLAN_NAME: 'Plan Name',
  CALLS_LIMIT: 'Calls Limit',
  CALLS_LIMIT_TEXT: 'calls per',
  RATE_LIMIT: 'Rate Limit',
  RATE_LIMIT_TEXT: 'per second',
  CURRENT_USAGE: 'Current Usage',
  CURRENT_USAGE_TEXT: 'Usage for last month and the current month (updated every 1-2 hours).',
  PROFILE_UPDATED_SUCCESSFULLY: 'Details updated successfully!',
  PROFILE_UPDATED_ERROR: 'There was a problem updating your details.'
  //
};
